import appGlobalState from '../appconfig';

const settingsReducer = (state = { ...appGlobalState.settings }, action) => {
  if (action.type === 'HAS_TOKEN') {
    if (action.payload) {
      return {
        ...state,
        userData: action.payload,
        VerifiedEmail: action.EmailVerify,
        api: {
          ...state.api,
          apiRequestHeaders: {
            ...state.api.apiRequestHeaders,
            headers: {
              ...state.api.apiRequestHeaders.headers,
              Authorization: `${action.payload.token_type} ${action.payload.token}`,
              Accept: 'application/json',
            },
          },
        },
      };
    }
  }
  if (action.type === 'DISABLE_APPLOADING') {
    return {
      ...state,
      apploading: false,
    };
  }
  if (action.type === 'ENABLE_APPLOADING') {
    return {
      ...state,
      apploading: true,
    };
  }

  if (action.type === 'EMAILVERIFIEDSTATUS') {
    return {
      ...state,
      VerifiedEmail: action.payload,
    };
  }

  if (action.type === 'CLOSE_THE_PREMIUM_BANNER') {
    return {
      ...state,
      showPremiumBanner: action.payload,
    };
  }
  if (action.type === 'SETFREETRIAL') {
    return {
      ...state,
      freeTrial: action.payload.freeTrial,
      freeTrialDetails: {
        ...state.freeTrialDetails,
        trialEndDate: action.payload.freeTrialDetails.trialEndDate,
        daystogo: action.payload.freeTrialDetails.daystogo
      }
    }
  }
  if (action.type === 'LOGIN_USER') {
    // console.log(action)
    return {
      ...state,
      userData: action.payload,
      VerifiedEmail: action.EmailVerify,
      freeTrial: action.freeTrial,
      freeTrialDetails: {
        ...state.freeTrialDetails,
        trialEndDate: action.freeTrialDetails.trialEndDate,
        daystogo: action.freeTrialDetails.daystogo
      },
      api: {
        ...state.api,
        apiRequestHeaders: {
          ...state.api.apiRequestHeaders,
          headers: {
            ...state.api.apiRequestHeaders.headers,
            Authorization: `${action.payload.token_type} ${action.payload.token}`,
            Accept: 'application/json',
          },
        },
      },
    };
  }

  if (action.type === 'TOGGLE_PRO') {
    return {
      ...state,
      userData: {
        ...state.userData,
        isPro: action.payload,
      },
    };
  }

  if (action.type === 'TNC_ACCEPTED') {
    return {
      ...state,
      userData: action.payload,
      checkListStatus: {
        SecuritiesCheckList: false,
        EventTypesCheckList: false,
      },
    };
  }
  if (action.type === 'WELCOME_MODE_ON') {
    return {
      ...state,
      welcomemode: action.payload
    };
  }
  if (action.type === 'MAINTENANCE_MODE') {
    return {
      ...state,
      maintenancemodeon: action.payload
    };
  }

  if (action.type === 'SET_TZ_OFFSET') {
    return {
      ...state,
      TimeZoneOffset: action.payload,
    };
  }
  if (action.type === 'FROM_MOBILEAPP') {
    return {
      ...state,
      isMobileApp: action.payload,
    };
  }
  if (action.type === 'INSTALLED_APP_DATA') {
    return {
      ...state,
      installedAppData: action.payload,
    };
  }
  if (action.type === 'SET_TZ_LIST') {
    return {
      ...state,
      TimeZoneList: action.payload,
    };
  }

  if (action.type === 'SET_INIT_REQUEST_STATUS') {
    return {
      ...state,
      initDataRequest: action.payload,
    };
  }

  if (action.type === 'SET_INCMESSAGE') {
    if (action.payload) {
      return {
        ...state,
        messageNotifications: action.payload,
      };
    }
  }

  if (action.type === 'SET_NOTIFICATIONS') {
    return {
      ...state,
      notifications: action.payload,
    };
  }

  if (action.type === 'SYSTEM_TIME') {
    return {
      ...state,
      systemTime: action.payload,
    };
  }

  if (action.type === 'FEEDBACK_GET') {
    return {
      ...state,
      userFeedbacks: action.payload,
    };
  }

  if (action.type === 'SET_ORG_USERS') {
    if (action.payload) {
      return {
        ...state,
        OrgUsers: action.payload,
      };
    }
  }

  if (action.type === 'TIP_CLICKED') {
    let tooltipglobalHandler = state.toolTipGlobals;
    Object.keys(tooltipglobalHandler).forEach((key) => {
      if (action.payload.tiptype !== key) {
        tooltipglobalHandler[`${key}`] = false;
      } else {
        tooltipglobalHandler[`${key}`] = action.payload.tipstate ? action.payload.tipOnId : action.payload.tipstate;
      }
    });

    return {
      ...state,
      toolTipGlobals: tooltipglobalHandler,
    };
  }
  if (action.type === 'TIP_RESET_GLOBAL') {
    let tooltipglobalHandler = state.toolTipGlobals;
    Object.keys(tooltipglobalHandler).forEach((key) => {
      tooltipglobalHandler[`${key}`] = false;
    });

    return {
      ...state,
      toolTipGlobals: tooltipglobalHandler,
    };
  }

  if (action.type === 'FEEDBACK_SET') {
    if (action.payload) {
      let fb = state.userFeedbacks;
      let newFb = [];
      let isAnUpdate = false;
      for (let row of fb) {
        if (row.EventID === action.payload.EventID) {
          row.Like = action.payload.Like;
          row.FeedbackCreationTime = action.payload.FeedbackCreationTime;
          isAnUpdate = true;
          newFb.push(row);
        } else {
          newFb.push(row);
        }
      }
      if (!isAnUpdate) {
        let FeedbackID = fb.length;
        action.payload.FeedbackID = FeedbackID;
        newFb.push(action.payload);
      }

      return {
        ...state,
        userFeedbacks: newFb,
      };
    }
  }

  if (action.type === 'SET_SENDMESSAGE') {
    return state;
  }

  if (action.type === 'FEEDBACK_SEND') {
    return {
      ...state,
      feedbackStatus: action.payload,
    };
  }

  if (action.type === 'PASSWORD_CHANGED') {
    return {
      ...state,
      changePassword: action.payload,
    };
  }
  if (action.type === 'INITIAL_PASSWORD_CHANGED') {
    return {
      ...state,
      userData: {
        ...state.userData,
        InitialPasswordReset: action.payload,
      },
    };
  }
  if (action.type === 'TTR_GLOBAL_ACTIVATE') {
    return {
      ...state,
      toolTipGlobalRemover: true,
    };
  }
  if (action.type === 'TTR_GLOBAL_DEACTIVATE') {
    return {
      ...state,
      toolTipGlobalRemover: false,
    };
  }
  if (action.type === 'SET_TICKER_ICON_LIST') {
    return {
      ...state,
      TickerIconsList: action.payload,
    };
  }
  if (action.type === 'ACTIVE_COLUMN') {
    return {
      ...state,
      ActiveColumn: action.payload,
      BeforeActiveColumn: action.before,
      AfterActiveColumn: action.after
    };
  }
  if (action.type === 'SET_COLUMN_LIST') {
    return {
      ...state,
      columnlist: action.payload,
    };
  }
  if (action.type === 'SET_COLUMN_ORDER_LIST_INIT') {
    return {
      ...state,
      columnorderlist: action.payload,
    };
  }
  if (action.type === 'SET_VERIFIED_SETTING') {
    return {
      ...state,
      verifiednewcolumnordersettings: action.payload,
    };
  }
  if (action.type === 'SET_COLUMN_ORDER_LIST') {
    // let prefArr = [];
    //   if(typeof action.payload !== 'undefined' && action.payload !== '') {
    //     let prefObjStr = action.payload.split('--')[1]
    //     let eachordersetting = prefObjStr.split('??').filter(itm => (itm))
    //     eachordersetting.forEach((order,i) => {
    //       let prefData = {};
    //       let settings = order.split('||');
    //       settings.forEach(elem => {
    //         prefData[elem.split('__')[0]] = elem.split('__')[1]
    //       })
    //       prefArr.push(prefData);
    //     })
    //   }
    // console.log(prefArr)
    return {
      ...state,
      columnorderlist: action.payload,
      columnorderlist_initload: true
    };
  }
  if (action.type === 'SWITCH_COLUMN_FOCUS') {
    return {
      ...state,
      existingColumnName: action.payload,
    };
  }
  if (action.type === 'PINNED_MAINFEEDS') {
    return {
      ...state,
      pinnedmainfeeds: state.pinnedmainfeeds + 1,
    };
  }
  if (action.type === 'CLOSE_PINNED_COLUMNS') {
    return {
      ...state,
      closedpinnedcolumn: state.closedpinnedcolumn + 1,
    };
  }
  if (action.type === 'SET_POSTPONEUPDATE') {
    return {
      ...state,
      postponeupdate: action.payload,
    };
  }
  if (action.type === 'UPDATE_LOADCOUNTER') {
    // console.log('columns loaded', state.initialcolumnloadcounter + 1);
    return {
      ...state,
      initialcolumnloadcounter: state.initialcolumnloadcounter + 1
    };
  }
  if (action.type === 'UPDATE_RENDERTYPE') {

    return {
      ...state,
      render: action.payload
    };
  }
  if (action.type === 'UPDATE_FORCETHEME') {

    return {
      ...state,
      forcetheme: action.payload
    };
  }
  if (action.type === 'UPDATE_LANGUAGE_SETTINGS') {

    return {
      ...state,
      lang: action.payload
    };
  }
  if (action.type === 'SET_IFRAME_LOGO') {

    return {
      ...state,
      iframelogo: action.payload
    };
  }
  if (action.type === 'UPDATE_IFRAME_ASSET') {

    return {
      ...state,
      iframeasset: action.payload
    };
  }
  if (action.type === 'UPDATE_IFRAME_ASSET_SECURITY') {

    return {
      ...state,
      iframeassetdata: action.payload
    };
  }
  if (action.type === 'UPDATE_IFRAME_NAV') {
    // console.log(action.payload)
    return {
      ...state,
      iframenav: action.payload
    };
  }

  return state;
};

export default settingsReducer;
