import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as feedActionCreators from '../actions/feeds.action';
import * as preferencesActionCreator from '../actions/apppreferences.action';
import * as searchActionCreators from '../actions/search.action';
import * as tmActionCreators from '../actions/trendingmentions.action';
import { addLogAction } from '../actions/logAction.action';
import {
  updateTMAutoRefresh,
  setTMFrame,
  ChangeTMActiveTab,
  CloseTMDetails,
  resetTickerEvents,
  TMViewDetails,
  resetTM,
  TabColumnChangeRequest
} from '../actions/trendingmentions.action';
import { setEventFeedBackWithReason, switchcolumnfocus, initiallysetcolumnorderlist, verifiednewsettings, updateclosedpinnedcolumn, SetPostponeUpdate } from '../actions/settings.action';
import TweetDeck from '../components/stories/tweetdeck';
// import * as moment from 'moment';
import { PreferenceHandler, TrendingMentionsHelper } from '../modules/helper.module';
import { isMobile } from 'react-device-detect';
import * as moment from 'moment';
import { extrasecurities } from '../static_data/extrasecurities';

class Stories extends Component {
  constructor() {
    super();
    this.state = {
      rootFeeds: [],
      searchList: {},
      storiesList: {},
      checkInSearch: '',
      searchSaveData: {},
      lastNBEventDate: '',
      UserPreference_RequestState: true,
      tdeck: {
        colWidth: '',
      },
      TabMode: false,
      showEarlyEvents: true,
      showAnalyzedEvents: true,
      showEarlyInAnalyzedEvents: true,
      NACE: true,
      Search_with_gray: true,
      Search_with_black: true,
      Search_with_yellow: true,
      Search_with_orange: true,
      Search_with_red: true,
      searchCount: 0,
      pinnedSearchCount: 0,
      TM_View: false,
      coin_column_preferences: [],
      columnorderlist: []
    };
    this.SINGLESTORYHEIGHT = 66;
    this.SINGLESTORYHEIGHTWITHSECURITIES = 95.5;
  }
  componentWillMount() {
    if (this.props.prevPath === '/Settings' || this.props.prevPath === '/User-Preferences') {
      if (this.props.userpreference_NACE) {
        // call for analysed stories
        this.props.getStories(false, true, false, true);
        //call for with curated stories
        this.props.getStories(false, true, this.props.userpreference_NACE);
      } else {
        //call for both
        this.props.getStories(false, true, this.props.userpreference_NACE, 'mix');
      }
    }
    this.setStoryState(this.props);
  }

  componentDidMount() {
    // console.log('befor call')
    // this.props.showModal('Loader')
    // console.log('after call')
    window.addEventListener('scroll', this.handleScroll);
    // this.props.addLogAction({Message: 'Stories Component Loaded Successfully'})
    // window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    //   window.Mixpanel.actions.identify(this.props.userData.username);
    //   window.Mixpanel.actions.track('Stories loaded Successfully', window.Mixpanel.MixpanelProps).then((data) => {
    //     window.Mixpanel.actions.people.set({
    //       UserName: this.props.userData.username,
    //     });
    // });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (e) => {
    if (Object.keys(this.state.storiesList).length) {
      if (document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight - 100) {
        this.setState({
          ...this.state,
          ListLimit: this.state.ListLimit + 10,
        });
      }
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.actions.identify(this.props.userData.username);
      window.Mixpanel.actions.track('Scrolling stories page', window.Mixpanel.MixpanelProps).then((data) => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username,
        });
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setStoryState(nextProps);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const ChangedProps = Object.keys(this.props)
      .map((prop, ind) => {
        if (!(nextProps[prop] instanceof Function)) {
          if (!(nextProps[prop] === this.props[prop])) {
            // console.log(`${prop} not equal`); //not shallow equal
            if (prop === 'searchSaveData') {
              return prop;
            }
            if (JSON.stringify(nextProps[prop]) !== JSON.stringify(this.props[prop])) {
              // console.log(`and ${prop} when stringified is not equal`) //not Deep Equal
              return prop;
            }
            // else {
            //   console.log(`but ${prop} when stringified is equal`)
            // }
          }
        }
        return null;
      })
      .filter((itm) => itm);
    const ChangedState = Object.keys(this.state)
      .map((prop, ind) => {
        if (!(nextState[prop] instanceof Function)) {
          if (!(nextState[prop] === this.state[prop])) {
            // console.log(`${prop} not equal`);
            if (prop === 'searchSaveData') {
              return prop;
            }
            if (JSON.stringify(nextState[prop]) !== JSON.stringify(this.state[prop])) {
              // console.log(`and ${prop} when stringified is not equal`)
              return prop;
            }
            // else {
            //   console.log(`but state ${prop} when stringified is equal`)
            // }
          }
        }
        return null;
      })
      .filter((itm) => itm);
    // console.log('stories component trying to update')
    if (!ChangedProps.length && !ChangedState.length) {
      return false;
    }
    // console.log('stories component is updating')
    return true;
  }

  setStoryState = (StoryProps) => {
    let checkInSearch = false;
    let TabMode = false;

    checkInSearch = Object.keys(StoryProps.searchData).length !== 0 && StoryProps.searchData.activeStatus ? true : false;

    if (isMobile) {
      TabMode = true;
    } else {
      if (StoryProps.homeviewport.width <= 1350 && StoryProps.homeviewport.width >= 991) {
        if (checkInSearch) {
          // TabMode = true;
        }
      } else {
        if (StoryProps.homeviewport.width < 991) {
          TabMode = true;
        } 
        // else if (StoryProps.homeviewport.width > 1350) {
        //   TabMode = false;
        // }
      }
    } 
    let { coin_column_preferences } = StoryProps;
    if(coin_column_preferences.length) {
      coin_column_preferences.forEach((pref, i) => {
        if(pref.expanded === 'true') {
          // if(!checkInSearch) {
          //   TabMode = true;
          // } else {
          //   this.expandTopCoins(i)
          // }
          TabMode = true;
        }
      })
    }
    let ActiveTFrame = '';
    Object.keys(StoryProps.tmActiveFrame).map((frame) => {
      if (StoryProps.tmActiveFrame[frame]) {
        ActiveTFrame = frame;
      }
      return null;
    });

    // console.log(StoryProps.searchCount)
    this.setState({
      ...this.state,
      checkInSearch: checkInSearch,
      searchString: StoryProps.searchData.searchString,
      searchSaveData: StoryProps.searchSaveData,
      UserPreference_RequestState: StoryProps.userpreference_requestState,
      TabMode: TabMode,
      showEarlyEvents: StoryProps.preferencesEarlyEvents,
      showAnalyzedEvents: StoryProps.preferencesAnalyzedEvents,
      showEarlyInAnalyzedEvents: StoryProps.preferencesEarlyAnalyzedEvents,
      NACE: StoryProps.userpreference_NACE,
      Show_HIGHER: StoryProps.userpreference_HIGHER,
      Show_HIGH: StoryProps.userpreference_HIGH,
      Show_MEDIUM: StoryProps.userpreference_MEDIUM,
      Show_LOW: StoryProps.userpreference_LOW,
      Search_with_gray: StoryProps.Search_with_gray,
      Search_with_black: StoryProps.Search_with_black,
      Search_with_white: StoryProps.Search_with_white,
      Search_with_yellow: StoryProps.Search_with_yellow,
      Search_with_orange: StoryProps.Search_with_orange,
      Search_with_red: StoryProps.Search_with_red,
      CDSearch_with_gray: StoryProps.CDSearch_with_gray,
      CDSearch_with_black: StoryProps.CDSearch_with_black,
      CDSearch_with_yellow: StoryProps.CDSearch_with_yellow,
      CDSearch_with_orange: StoryProps.CDSearch_with_orange,
      CDSearch_with_red: StoryProps.CDSearch_with_red,
      Event_Collapsed_View: StoryProps.Event_Collapsed_View,
      CD_Collapsed_View: StoryProps.CD_Collapsed_View,
      Search_Collapsed_View: StoryProps.Search_Collapsed_View,
      // SavedSearch_Collapsed_View: StoryProps.SavedSearch_Collapsed_View,
      searchCount: StoryProps.searchCount,
      pinnedSearchCount: StoryProps.pinnedSearchCount,
      pinnedcoincolumn: StoryProps.pinnedcoincolumn,
      pinnedcoindetailcolumn: StoryProps.pinnedcoindetailcolumn,
      pinnedmainfeeds: StoryProps.pinnedmainfeeds,
      closedpinnedcolumn: StoryProps.closedpinnedcolumn,
      singleCoinSearch: StoryProps.singleCoinSearch,
      TM_View: StoryProps.userpreference_TM,
      tmActiveFrame: StoryProps.tmActiveFrame,
      ActiveTFrame,
      activeTab: StoryProps.activeTab,
      TickerViewDetails: StoryProps.TickerViewDetails,
      FromTopCoins: StoryProps.FromTopCoins,
      coin_column_preferences: StoryProps.coin_column_preferences,
      tmDetailPreferences: StoryProps.tmDetailPreferences,
      RecentUpdateView: StoryProps.RecentUpdateView,
      HasColumnOrderSettings: StoryProps.HasColumnOrderSettings,
      MenuFilter: StoryProps.MenuFilter,
      columnorderlist:StoryProps.columnorderlist,
      verifiednewcolumnordersettings: StoryProps.verifiednewcolumnordersettings,
      columnorderlist_initload: StoryProps.columnorderlist_initload,
      searchsavedata_initload: StoryProps.searchsavedata_initload
      // collapseFlag: false,
    });
  };

  initSaveSearch = (status) => {
    this.props.captureTitle();
  };

  deleteSaveSearch = (e, saveid) => {
    
    e.stopPropagation();
    try {
      // this.props.SetPostponeUpdate(true);
      this.props.showModal('Loader');
      let listkey = `SavedSearch${saveid}`
      let { coin_column_preferences, tmDetailPreferences, columnlist } = this.props;
      let preferences = JSON.parse(JSON.stringify(this.props.preferences));
      if(preferences.user_interface.HasColumnOrderSettings === false) {
        preferences.user_interface.HasColumnOrderSettings = true;
      }
      let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))
      let removeAtIndex = columnlist.indexOf(listkey)
      // if(columnorderlist[removeAtIndex] && columnorderlist[removeAtIndex].columntype === 'SavedSearch' && columnorderlist[removeAtIndex].UserSavedDataID === saveid) {
        
      // }
      columnorderlist.splice(removeAtIndex, 1)

      let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
      let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
      let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tmDetailPreferences, 'coindetails');
      let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)
      // console.log(PreferenceStrings.userPrefData.UserPreferencesString)
      // console.log(CoinCoulmnStrings)
      let newPrefStr = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`
      // console.log(newPrefStr);
      PreferenceStrings.userPrefData.UserPreferencesString = newPrefStr;
      PreferenceStrings.userPrefData.UserName = this.props.userData.username;
      


      let deletedData = this.state.searchSaveData[saveid];
      this.props.updateclosedpinnedcolumn()
      if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
        this.props.deleteSavedSearch_LLU(deletedData);
        this.props.setUserPreferences_LLU(PreferenceStrings.userPrefData);
      } else {
        this.props.deleteSavedSearch(deletedData);
        this.props.setUserPreferences(PreferenceStrings.userPrefData);
      }
      
      
      
      
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.actions.identify(this.props.userData.username);
      window.Mixpanel.actions.track(`Deleted Saved Search`, window.Mixpanel.MixpanelProps).then((data) => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username,
        });
      });
    } catch(err) {
      this.props.showModal('ErrorMsg', 'There seems to be a temporary error. Please refresh or try later. If the problem persists, ');
    }
    
  };

  updateCoinColumnPreferences = (PreferenceObj, index = 0, listkey) => {
    // console.log(PreferenceObj, index, this.props.coin_column_preferences)
    let { coin_column_preferences, tmDetailPreferences, columnlist } = this.props;
    let preferences = JSON.parse(JSON.stringify(this.props.preferences));
    if(preferences.user_interface.HasColumnOrderSettings === false) {
      preferences.user_interface.HasColumnOrderSettings = true;
    }
    let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))
    let changeAtIndex = columnlist.indexOf(listkey)

    coin_column_preferences[index] = PreferenceObj;

    columnorderlist[changeAtIndex] = coin_column_preferences[index];

    let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
    let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
    let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tmDetailPreferences, 'coindetails');
    let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)
    // console.log(PreferenceStrings.userPrefData.UserPreferencesString)
    // console.log(CoinCoulmnStrings)
    let newPrefStr = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`
    // console.log(newPrefStr);
    PreferenceStrings.userPrefData.UserPreferencesString = newPrefStr;
    PreferenceStrings.userPrefData.UserName = this.props.userData.username;
    if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
      this.props.setUserPreferences_LLU(PreferenceStrings.userPrefData);
    } else {
      this.props.setUserPreferences(PreferenceStrings.userPrefData);
    }
    
  }

  closeTopcoins = (index, listkey) => {
    try {
      // this.props.SetPostponeUpdate(true);
      this.props.showModal('Loader');
      let { coin_column_preferences, tmDetailPreferences, columnlist } = this.props;
      let preferences = JSON.parse(JSON.stringify(this.props.preferences));
      if(preferences.user_interface.HasColumnOrderSettings === false) {
        preferences.user_interface.HasColumnOrderSettings = true;
      }
      let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))
      let removeAtIndex = columnlist.indexOf(listkey)

      let cc_prefs = JSON.parse(JSON.stringify(coin_column_preferences));
      cc_prefs.splice(index, 1);

      columnorderlist.splice(removeAtIndex, 1)
      // console.log(cc_prefs, coin_column_preferences)
      
      let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
      let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(cc_prefs);
      let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tmDetailPreferences, 'coindetails');
      let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)

      let newPrefStr = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`;
      
      PreferenceStrings.userPrefData.UserPreferencesString = newPrefStr;
      PreferenceStrings.userPrefData.UserName = this.props.userData.username;
      this.props.updateclosedpinnedcolumn()
      if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
        this.props.setUserPreferences_LLU(PreferenceStrings.userPrefData);
      } else {
        this.props.setUserPreferences(PreferenceStrings.userPrefData);
      }
    } catch(err) {
      this.props.showModal('ErrorMsg', 'There seems to be a temporary error. Please refresh or try later. If the problem persists, ');
    }
    
    
  }

  expandTopCoins = (index, listkey) => {
    let { coin_column_preferences, tmDetailPreferences, columnlist } = this.props;
    let preferences = JSON.parse(JSON.stringify(this.props.preferences));
    if(preferences.user_interface.HasColumnOrderSettings === false) {
      preferences.user_interface.HasColumnOrderSettings = true;
    }
    let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))
    let changeAtIndex = columnlist.indexOf(listkey)

    let cc_prefs = JSON.parse(JSON.stringify(coin_column_preferences));
    cc_prefs[index].expanded = typeof cc_prefs[index].expanded === 'undefined' || cc_prefs[index].expanded === 'false' ? 'true' : 'false'
    if(cc_prefs[index].expanded === 'false') {
      cc_prefs[index].isPro = 'true';
    }
    columnorderlist[changeAtIndex] = cc_prefs[index];
    // console.log(columnorderlist)
    // return;
    let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
    let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(cc_prefs);
    let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tmDetailPreferences, 'coindetails');
    let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)
    let newPrefStr = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`;
    
    PreferenceStrings.userPrefData.UserPreferencesString = newPrefStr;
    PreferenceStrings.userPrefData.UserName = this.props.userData.username;
    if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
      this.props.setUserPreferences_LLU(PreferenceStrings.userPrefData);
    } else {
      this.props.setUserPreferences(PreferenceStrings.userPrefData);
    }
    
  }

  updateSavedSearchPreference = (newOption, saveId) => {
    let saveData = this.state.searchSaveData[saveId] && this.state.searchSaveData[saveId];
    if (saveData) {
      let updateKey = Object.keys(newOption)[0];
      let savedDetails = JSON.parse(saveData.SavedDataDetails);
      if (
        updateKey === 'withBlack' ||
        updateKey === 'withCurated' ||
        updateKey === 'withWhite' ||
        updateKey === 'withYellow' ||
        updateKey === 'withOrange' ||
        updateKey === 'withRed'
      ) {
        // savedDetails = PreferenceHandler.UpdateSavedSearchColorPreference(savedDetails, updateKey, newOption[`${updateKey}`])
        savedDetails = PreferenceHandler.UpdateSavedSearchColorPreferenceForSlider(
          savedDetails,
          updateKey,
          newOption[`${updateKey}`]
        );
        // console.log(savedDetails)
      } else {
        savedDetails[`${updateKey}`] = newOption[`${updateKey}`];
      }
      
      saveData.SavedDataDetails = JSON.stringify(savedDetails);
      // console.log(saveData, updateKey)
      // return;
      if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
        this.props.updateSavedSearch_LLU(saveData, updateKey);
      } else {
        this.props.updateSavedSearch(saveData, updateKey);
      }
    }
  };

  updateNACEPreference = (e, NACE = false) => {
    let preferences = this.props.preferences;
    let { coin_column_preferences, tmDetailPreferences } = this.props;
    let category = 'Non_Active_Curated_Events';
    let title = 'Show_NACE';
    let value = !NACE;
    let Preferences = PreferenceHandler.UpdateUserPreferences(preferences, title, value, category);
    let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(Preferences);
    let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
    let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tmDetailPreferences, 'coindetails');
    Object.keys(PreferenceStrings).map((StrType) => {
      PreferenceStrings[`${StrType}`].UserName = this.props.userData.username;
      return null;
    });
    PreferenceStrings.userPrefData.UserPreferencesString = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}`
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.MixpanelProps.Category = category;
    window.Mixpanel.MixpanelProps.Preference = title;
    window.Mixpanel.MixpanelProps.Status = value === true ? 'ON' : value === false ? 'OFF' : value;
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track(`Changed Preferences`, window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username,
      });
      window.Mixpanel.actions.resetProp('Category');
      window.Mixpanel.actions.resetProp('Preference');
      window.Mixpanel.actions.resetProp('Status');
    });
    this.props.setUserPreferences(PreferenceStrings.userPrefData, true);
  };

  updateUserPreferences_EventImportance = (Color, Preference = false, listkey) => {
    let preferences = { ...this.props.preferences };
    if(preferences.user_interface.HasColumnOrderSettings === false) {
      preferences.user_interface.HasColumnOrderSettings = true;
    }
    let { coin_column_preferences, tmDetailPreferences, columnlist  } = this.props;
    let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))
    let value = Preference;

    let { categories, titles, selectedCategory } = PreferenceHandler.GetCategoriesAndTitlesForSlider(Color, value);

    if (titles.length > 0) {
      titles.forEach((title, indx) => {
        preferences = PreferenceHandler.UpdateUserPreferences(preferences, Object.keys(title)[0], title[Object.keys(title)[0]], categories[indx]);
      });
    }
    let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
    // console.log(preferences, PreferenceStrings)
    // return;
    let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
    let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tmDetailPreferences, 'coindetails');
    let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)
    Object.keys(PreferenceStrings).map((StrType) => {
      PreferenceStrings[`${StrType}`].UserName = this.props.userData.username;
      return null;
    });
    // console.log(PreferenceStrings.userPrefData.UserPreferencesString);
    PreferenceStrings.userPrefData.UserPreferencesString = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.MixpanelProps.Category = JSON.stringify(categories);
    window.Mixpanel.MixpanelProps.Preference = JSON.stringify(titles);
    window.Mixpanel.MixpanelProps.Status = value === true ? 'ON' : value === false ? 'OFF' : value;
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track(`Changed Preferences`, window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username,
      });
      window.Mixpanel.actions.resetProp('Category');
      window.Mixpanel.actions.resetProp('Preference');
      window.Mixpanel.actions.resetProp('Status');
    });
    if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
      this.props.setUserPreferences_LLU(PreferenceStrings.userPrefData, true, false, false, selectedCategory);
    } else {
      this.props.setUserPreferences(PreferenceStrings.userPrefData, true, false, false, selectedCategory);
    }
    
  };

  updateSearchPreference = (key, value, columnType = false) => {
    console.log(key, value, columnType)
    let preferences = { ...this.props.preferences };
    let { coin_column_preferences, tmDetailPreferences } = this.props;
    let ExtractedCat = [];
    let ExtractedTitle = [];
    let SelectedCat = [];
    value = value;
    let { categories, titles, selectedCategory } = PreferenceHandler.GetCategoriesAndTitlesForSlider(
      key,
      value,
      columnType
    );
    console.log(categories, titles, selectedCategory);
    ExtractedCat = categories;
    ExtractedTitle = titles;
    SelectedCat = selectedCategory;
    if (titles.length > 0) {
      titles.forEach((title, indx) => {
        preferences = PreferenceHandler.UpdateUserPreferences(
          preferences,
          Object.keys(title)[0],
          title[Object.keys(title)[0]],
          selectedCategory
        );
      });
    }
    console.log(preferences)
    let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
    let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
    let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tmDetailPreferences, 'coindetails');
    console.log(PreferenceStrings.userPrefData.UserPreferencesString)
    Object.keys(PreferenceStrings).map((StrType) => {
      PreferenceStrings[`${StrType}`].UserName = this.props.userData.username;
      return null;
    });
    PreferenceStrings.userPrefData.UserPreferencesString = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}`
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.MixpanelProps.Category = JSON.stringify(ExtractedCat);
    window.Mixpanel.MixpanelProps.Preference = JSON.stringify(ExtractedTitle);
    window.Mixpanel.MixpanelProps.Status = value === true ? 'ON' : value === false ? 'OFF' : value;
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track(`Changed Preferences`, window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username,
      });
      window.Mixpanel.actions.resetProp('Category');
      window.Mixpanel.actions.resetProp('Preference');
      window.Mixpanel.actions.resetProp('Status');
    });
    this.props.setUserPreferences(PreferenceStrings.userPrefData, false, true, columnType);
  };
  updateCoinDetailColumnSliderPreference = (key, value, columnindex, SecurityName, listkey) => {
    // let preferences = { ...this.props.preferences };
    // console.log(key, value, columnindex, SecurityName, listkey)
    
    let { coin_column_preferences, tmDetailPreferences, columnlist } = this.props;
    let preferences = JSON.parse(JSON.stringify(this.props.preferences));
    if(preferences.user_interface.HasColumnOrderSettings === false) {
      preferences.user_interface.HasColumnOrderSettings = true;
    }
    let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))
    let changeAtIndex = columnlist.indexOf(listkey)

    let tm_prefs = JSON.parse(JSON.stringify(tmDetailPreferences));
    // let pref_data = tm_prefs[columnindex];
    let ExtractedCat = [];
    let ExtractedTitle = [];
    let SelectedCat = [];
    value = value;
    let { categories, titles, selectedCategory } = PreferenceHandler.GetCategoriesAndTitlesForSlider(
      key,
      value,
      'tmcd'
    );
    // console.log(categories, titles, selectedCategory, tm_prefs[columnindex])
    
    ExtractedCat = categories;
    ExtractedTitle = titles;
    SelectedCat = selectedCategory;
    if (titles.length > 0) {
      titles.forEach((title, indx) => {
        if(Object.keys(tm_prefs[columnindex]).indexOf(Object.keys(title)[0]) > -1) {
          tm_prefs[columnindex][Object.keys(title)[0]] = title[Object.keys(title)[0]]
        }
      });
    }
    columnorderlist[changeAtIndex] = tm_prefs[columnindex]

    let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
    let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
    let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tm_prefs, 'coindetails');
    let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)
    // console.log(PreferenceStrings.userPrefData.UserPreferencesString)
    Object.keys(PreferenceStrings).map((StrType) => {
      PreferenceStrings[`${StrType}`].UserName = this.props.userData.username;
      return null;
    });
    PreferenceStrings.userPrefData.UserPreferencesString = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.MixpanelProps.Category = JSON.stringify(ExtractedCat);
    window.Mixpanel.MixpanelProps.Preference = JSON.stringify(ExtractedTitle);
    window.Mixpanel.MixpanelProps.Status = value === true ? 'ON' : value === false ? 'OFF' : value;
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track(`Coin Detail Column Changed Search Preferences`, window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username,
      });
      window.Mixpanel.actions.resetProp('Category');
      window.Mixpanel.actions.resetProp('Preference');
      window.Mixpanel.actions.resetProp('Status');
    });
    if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
      this.props.setUserPreferences_LLU(PreferenceStrings.userPrefData, false, true, 'tmcd', '', SecurityName);
    } else {
      this.props.setUserPreferences(PreferenceStrings.userPrefData, false, true, 'tmcd', '', SecurityName);
    }
    
  };

  filterSecurities = (status) => {
    let AllSecFilterObj = { [`${this.props.userData.username}`]: status };
    window.localStorage.setItem('AllSecuritiesFilterStatus', JSON.stringify(AllSecFilterObj));
    // console.log(status)
    this.props.resetStories();
    // window.location.reload()
  };

  getAllSecFilter = () => {
    let filtStats = window.localStorage.getItem('AllSecuritiesFilterStatus');
    if (filtStats) {
      let obj = JSON.parse(filtStats);
      // console.log(obj, this.props.userData.username)
      if (obj[`${this.props.userData.username}`]) {
        return true;
      }
    }
    return false;
  };
  setTMFrame = (frame) => {
    window.localStorage.setItem('TMFrame', frame);
    this.props.setTMFrame(frame);
  };

  SetTMActiveTab = (Tab, index = 0, sortedcolumn = false, lastsortingtype = false, listkey) => {
    if (Tab !== '') {
      this.props.resetTM(index);
      this.props.TabColumnChangeRequest(true, index);
      let coin_column_preferences  = JSON.parse(JSON.stringify(this.props.coin_column_preferences));
      let prefObj = JSON.parse(JSON.stringify(coin_column_preferences[index]));
      console.log(sortedcolumn, lastsortingtype)
      if(sortedcolumn) {
        prefObj.tmSortedColumn = sortedcolumn
      }
      
      prefObj = TrendingMentionsHelper.updateTabChangePreferences(prefObj, Tab);
      
      
      console.log(prefObj)
      // return;
      this.updateCoinColumnPreferences(prefObj, index, listkey);
      this.props.ChangeTMActiveTab(Tab, prefObj, index);
    }
  };

  tmSortChange = (activeTab, lastSortedColumn, lastSortingType, columnChange, index = 0, listkey) => {
    let { coin_column_preferences } = this.props;
    let prefObj = coin_column_preferences[index];
    prefObj = TrendingMentionsHelper.updateSortChangePreferences(prefObj, activeTab, lastSortedColumn, lastSortingType, columnChange);
    this.updateCoinColumnPreferences(prefObj, index, listkey);
    this.props.SetTMSort(activeTab, lastSortedColumn, lastSortingType, columnChange, prefObj, index);
  }

  toggleCoinDetailColumnCollapse = (status, index, listkey) => {
    let { tmDetailPreferences, coin_column_preferences, columnlist } = this.props;
    let preferences = JSON.parse(JSON.stringify(this.props.preferences));
    if(preferences.user_interface.HasColumnOrderSettings === false) {
      preferences.user_interface.HasColumnOrderSettings = true;
    }
    let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))
    let changeAtIndex = columnlist.indexOf(listkey)

    let tm_prefs = JSON.parse(JSON.stringify(tmDetailPreferences));
    tm_prefs[index].CD_Collapsed_View = status

    columnorderlist[changeAtIndex] = tm_prefs[index]


    let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
    let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
    let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tm_prefs, 'coindetails');
    let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)

    Object.keys(PreferenceStrings).map((StrType) => {
      PreferenceStrings[`${StrType}`].UserName = this.props.userData.username;
      return null;
    });
    PreferenceStrings.userPrefData.UserPreferencesString = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`;
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.MixpanelProps.Category = tm_prefs[index].SecurityName;
    window.Mixpanel.MixpanelProps.Preference = 'Collapse View';
    window.Mixpanel.MixpanelProps.Status = status
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track(`Coin Detail column Collapsed View Preferences Changed`, window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username,
      });
      window.Mixpanel.actions.resetProp('Category');
      window.Mixpanel.actions.resetProp('Preference');
      window.Mixpanel.actions.resetProp('Status');
    });
    if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
      this.props.setUserPreferences_LLU(PreferenceStrings.userPrefData);
    } else {
      this.props.setUserPreferences(PreferenceStrings.userPrefData);
    }
    
  }
  toggleColumnCollapse = (column, status) => {
   
    let preferences = { ...this.props.preferences };
    let { coin_column_preferences, tmDetailPreferences, columnlist } = this.props;
    preferences.user_interface[column] = status
    if(preferences.user_interface.HasColumnOrderSettings === false) {
      preferences.user_interface.HasColumnOrderSettings = true;
    }
    let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))
    let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
    let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
    let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tmDetailPreferences, 'coindetails');
    let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)
    Object.keys(PreferenceStrings).map((StrType) => {
      PreferenceStrings[`${StrType}`].UserName = this.props.userData.username;
      return null;
    });
    // console.log(PreferenceStrings.userPrefData.UserPreferencesString);
    PreferenceStrings.userPrefData.UserPreferencesString = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.MixpanelProps.Category = 'User Interface';
    window.Mixpanel.MixpanelProps.Preference = column;
    window.Mixpanel.MixpanelProps.Status = status
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track(`Collapsed View Preferences Changed`, window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username,
      });
      window.Mixpanel.actions.resetProp('Category');
      window.Mixpanel.actions.resetProp('Preference');
      window.Mixpanel.actions.resetProp('Status');
    });
    if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
      this.props.setUserPreferences_LLU(PreferenceStrings.userPrefData);
    } else {
      this.props.setUserPreferences(PreferenceStrings.userPrefData);
    }
    
  }
  toggleCollapseSavedSearchList = (savedId, collapseStatus) => {
    // console.log(savedId, collapseStatus)
    try {
      // this.props.showModal('Loader');
      let saveData = this.state.searchSaveData[savedId] && this.state.searchSaveData[savedId];
      if (saveData) {
        let savedDetails = JSON.parse(saveData.SavedDataDetails);
        savedDetails['columnCollapsePreference'] = collapseStatus;

        saveData.SavedDataDetails = JSON.stringify(savedDetails);
        if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
          this.props.updateSavedSearch_LLU(saveData, 'columnCollapsePreference');
        } else {
          this.props.updateSavedSearch(saveData, 'columnCollapsePreference');
        }
        
      }
    } catch(err) {
      console.log(err)
      // this.props.showModal('ErrorMsg', 'There seems to be a temporary error. Please refresh or try later. If the problem persists, ');
    }
    
  };

  CloseRecentUpdates = () => {
    
    try {
      // this.props.SetPostponeUpdate(true);
      this.props.showModal('Loader');
      let preferences = { ...this.props.preferences };
      let { coin_column_preferences, tmDetailPreferences, columnlist } = this.props;
      let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))
      preferences.user_interface.RecentUpdateView = false;
      if(preferences.user_interface.HasColumnOrderSettings === false) {
        preferences.user_interface.HasColumnOrderSettings = true;
      }
      let removeAtIndex = columnlist.indexOf('Main Feeds')
      columnorderlist.splice(removeAtIndex, 1)
      let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
      let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
      let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tmDetailPreferences, 'coindetails');
      let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)
      Object.keys(PreferenceStrings).map((StrType) => {
        PreferenceStrings[`${StrType}`].UserName = this.props.userData.username;
        return null;
      });
      // console.log(PreferenceStrings.userPrefData.UserPreferencesString);
      PreferenceStrings.userPrefData.UserPreferencesString = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.MixpanelProps.Category = 'User Interface';
      window.Mixpanel.MixpanelProps.RecentUpdateView = false;
      window.Mixpanel.actions.identify(this.props.userData.username);
      window.Mixpanel.actions.track(`Close Recent Updates Column`, window.Mixpanel.MixpanelProps).then((data) => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username,
        });
        window.Mixpanel.actions.resetProp('Category');
        window.Mixpanel.actions.resetProp('RecentUpdateView');
      });
      this.props.updateclosedpinnedcolumn()
      if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
        this.props.setUserPreferences_LLU(PreferenceStrings.userPrefData);
      } else {
        this.props.setUserPreferences(PreferenceStrings.userPrefData);
      }
    } catch(err) {
      this.props.showModal('ErrorMsg', 'There seems to be a temporary error. Please refresh or try later. If the problem persists, ');
    }
    
    
    
  }

  pinSavedSearch = (Tickerdata, listkey) => {
    try {
      console.log(Tickerdata, listkey)
      let columnFilter = ""
      let tm_prefs = JSON.parse(JSON.stringify(this.props.tmDetailPreferences));
      let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))
      let { columnlist } = this.props;
      // if(listkey === false || typeof listkey !== 'undefined') {
      //   listkey = this.props.columnlist[this.props.columnlist.length -1];
      // }
      if(listkey.startsWith('Trending Mentions__')) {
        columnFilter = 'Trending'
      } else if(listkey.startsWith('Coin_Column_details__')) {
        // console.log(listkey)
        let tmpref_index = listkey.split('__')[1]
        // console.log(listkey, tmpref_index, tm_prefs[tmpref_index])
        columnFilter = tm_prefs[tmpref_index].columnFilter
      } else if(listkey === 'Main Feeds') {
        columnFilter = 'Inbox'
      } else if(listkey.startsWith('SavedSearch')) {
        if(this.props.columnlist.indexOf(listkey) > -1) {
          columnFilter = this.props.columnorderlist[this.props.columnlist.indexOf(listkey)].columnFilter
        } else {
          columnFilter = 'Search'
        }
      }
      // console.log(columnFilter, this.props.searchSaveData, columnorderlist)
      // return;
      let samesectionduplicateindex = ''
      if(columnorderlist.length) {
        columnorderlist.forEach((col, i) => {
          if (Object.keys(this.props.searchSaveData).length) {
            Object.keys(this.props.searchSaveData).map((saveSearchId) => {
              if((col.UserSavedDataID && col.UserSavedDataID === saveSearchId) && (col.columnFilter && col.columnFilter === columnFilter)) {
                let savedetails = JSON.parse(this.props.searchSaveData[saveSearchId].SavedDataDetails)
                // console.log(savedetails);
                if(savedetails.sec.length === 1 && savedetails.sec[0] === Tickerdata.SecurityName && savedetails.ticker[0] === Tickerdata.SecurityTicker) {
                  samesectionduplicateindex = saveSearchId;
                }
              }
            })
          }
        })
      }
      
      console.log('save search same section duplicate',samesectionduplicateindex)
      // return 
      if(samesectionduplicateindex !== '') {
        let existingColumnName = `SavedSearch${samesectionduplicateindex}` 
        // console.log(SecNameArr, existingColumnName)
        setTimeout(() => {
          console.log('calling modal close, same column exists')
          this.props.SetPostponeUpdate(false);
          this.props.modalClose();
        }, 1500)
        this.props.switchcolumnfocus(existingColumnName)
      } else {
        // console.log('here in else')
        // console.log('listkey', listkey)
        this.props.initPinnedSearchSave(true, listkey);
        let searchData = { event: [], secTyp: [], sec: [`${Tickerdata.SecurityName}`], ticker: [`${Tickerdata.SecurityTicker}`], src: [] }
        let searchStartDate = moment().subtract(7, 'days');
        let searchEndDate = this.props.userData.userPackage.toLowerCase() === 'bronze' ?  moment().subtract(15, 'minutes') : moment()

        searchData.startDate = moment(searchStartDate).format('YYYY-MM-DD HH:mm:ss');
        searchData.endDate = moment(searchEndDate).format('YYYY-MM-DD HH:mm:ss');
        searchData.freeText = '';
        searchData.withCurated = false//this.props.Search_with_gray;
        searchData.withBlack = false//this.props.Search_with_black;
        searchData.withWhite = false//this.props.Search_with_white;
        searchData.withYellow = true//this.props.Search_with_yellow;
        searchData.withOrange = true//this.props.Search_with_orange;
        searchData.withRed = true//this.props.Search_with_red;
        searchData.columnCollapsePreference = this.props.Search_Collapsed_View;
        let savedSearchCount = Object.keys(this.props.searchSaveData).length;
        let Data = {
          UserSavedDataID: savedSearchCount + 1,
          SavedDataTitle: Tickerdata.SecurityName,
          SavedDataDetails: JSON.stringify(searchData),
          UserName: this.props.userData.username,
          columntype: 'SavedSearch',
          columnFilter: columnFilter
        };
        window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
        window.Mixpanel.MixpanelProps.SavedSearchData = JSON.stringify(searchData);
        window.Mixpanel.actions.identify(this.props.userData.username);
        window.Mixpanel.actions.track('Search Saved on clicking Security tag', window.Mixpanel.MixpanelProps).then((data) => {
          window.Mixpanel.actions.people.set({
            UserName: this.props.userData.username,
          });
          window.Mixpanel.actions.resetProp('SavedSearchData');
        });
        // console.log(Data)
        if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
          this.props.saveCurrentSearch_LLU(Data)
        } else {
          this.props.saveCurrentSearch(Data);
        }
        
      }
    } catch(err) {
      this.props.showModal('ErrorMsg', 'There seems to be a temporary error. Please refresh or try later. If the problem persists, ');
    }
    
    
  }

  pinCoinColumn = (TickerData, listkey = false) => {
    // console.log('Pin coin column starts')
    // console.log(TickerData, listkey)
    // console.log(this.props.columnlist)
    // console.log(this.props.columnorderlist)
    // console.log(extrasecurities)
    // console.log(this.props.securities)
    
    // return
    // checking if Tickerdata value is from extra securities (forex)
    // this.props.SetPostponeUpdate(true);
    this.props.showModal('Loader');
    // this.props.showModal('ErrorMsg', 'There seems to be a temporary error. Please refresh or try later. If the problem persists, ');
    let tickerSecurity = this.props.securities.filter(itm => TickerData.SecurityName === itm.Name || TickerData.SecurityTicker === itm.Symbol)
    
    // let TickerDataExistsInExtraSecurities = extrasecurities.filter(itm => TickerData.SecurityName === itm.Name || TickerData.SecurityTicker === itm.Name);
    // console.log('is Extra Securities: ',TickerDataExistsInExtraSecurities)
    
    if(tickerSecurity.length > 0  && tickerSecurity[0].SecurityTypeID !== 718) {// if Tickerdata value is not crypto opening as savedsearch
      
      this.pinSavedSearch(TickerData, listkey)
    } else {
      try {
        
        if(listkey === false) {
          listkey = this.props.columnlist[this.props.columnlist.length -1];
        }
        // console.log('listkey', listkey)
        let { tmDetailPreferences, coin_column_preferences, columnlist } = this.props;
        let preferences = JSON.parse(JSON.stringify(this.props.preferences));
        let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))
        
        let tm_prefs = JSON.parse(JSON.stringify(tmDetailPreferences));
        // console.log('tm prefs', tm_prefs)
        
        if(tm_prefs.length) {
          // check if columnfilter variable is defined and assigned to settings
          let hascolumnFilter = true
          tm_prefs.forEach(itm => {
            if(typeof itm.columnFilter === 'undefined' || itm.columnFilter === '') {
              hascolumnFilter = false
            }
          })
          // console.log('hascolumnfilter', hascolumnFilter)
          // return;
          //columnfilter variable  is not defined and assigned to settings
          // assigning column filter to column order settings and column data settings
          if(!hascolumnFilter) {
            let columnFilter = '';
            columnorderlist.forEach((column, i) => {
              if(column.columntype === 'tm') {
                columnFilter = 'Trending'
              } else if(column.columntype === 'tmcd') {
                column.columnFilter = columnFilter
                tm_prefs.forEach(itm => {
                  if(itm.SecurityName === column.SecurityName && itm.SecurityTicker === column.SecurityTicker) {
                    itm.columnFilter = columnFilter
                  }
                })
              } else if(column.columntype === 'Main Feeds') {
                columnFilter = 'Inbox'
              } else if(column.columntype === 'SavedSearch') {
                columnFilter = 'Search'
              }
            })
          }
        }
        
        
        if(preferences.user_interface.HasColumnOrderSettings === false) {
          preferences.user_interface.HasColumnOrderSettings = true;
        }
        let newPrefObj = TrendingMentionsHelper.newCoinDetailColumnPreference(TickerData);
        // console.log('new column object: ', newPrefObj)
        // return;
        //identifying the new column filter
        if(listkey.startsWith('Trending Mentions__')) {
          newPrefObj.columnFilter = 'Trending'
        } else if(listkey.startsWith('Coin_Column_details__')) {
          console.log(listkey)
          let tmpref_index = listkey.split('__')[1]
          // console.log(listkey, tmpref_index, tm_prefs[tmpref_index])
          newPrefObj.columnFilter = tm_prefs[tmpref_index].columnFilter
        } else if(listkey === 'Main Feeds') {
          newPrefObj.columnFilter = 'Inbox'
        } else if(listkey.startsWith('SavedSearch')) {
          
          if(this.props.columnlist.indexOf(listkey) > -1) {
            newPrefObj.columnFilter = this.props.columnorderlist[this.props.columnlist.indexOf(listkey)].columnFilter
          } else {
            newPrefObj.columnFilter = 'Search'
          }
        }
        let samesectionduplicateindex = ''
        if(columnorderlist.length) {
          // console.log(columnorderlist, newPrefObj, columnlist, tm_prefs)
          
          columnorderlist.forEach((col, i) => {
            // console.log(col, i, newPrefObj)
            if(col.columntype === 'tmcd' && col.SecurityName === newPrefObj.SecurityName && col.SecurityTicker === newPrefObj.SecurityTicker && col.columnFilter === newPrefObj.columnFilter && (typeof col.sourceFilter !== 'undefined' && typeof newPrefObj.sourceFilter !== 'undefined' && col.sourceFilter === newPrefObj.sourceFilter)) {
              // console.log((typeof col.sourceFilter !== 'undefined' && typeof newPrefObj.sourceFilter !== 'undefined' && col.sourceFilter === newPrefObj.sourceFilter))
              // && (col.sourceFilter && newPrefObj.sourceFilter && col.sourceFilter === newPrefObj.sourceFilter)
              let listkey = typeof columnlist[i] !== 'undefined' && columnlist[i].startsWith('Coin_Column_details') ? columnlist[i] : '';
              // console.log(listkey)
              let tm_pref_id = listkey !== '' ? listkey.split('__')[1] : '';
              // console.log(tm_pref_id)
              if(tm_pref_id !== '') {
                if(tm_prefs[tm_pref_id].SecurityName === newPrefObj.SecurityName && tm_prefs[tm_pref_id].SecurityTicker === newPrefObj.SecurityTicker && tm_prefs[tm_pref_id].columnFilter === newPrefObj.columnFilter && (typeof tm_prefs[tm_pref_id].sourceFilter !== 'undefined' && typeof newPrefObj.sourceFilter !== 'undefined' && tm_prefs[tm_pref_id].sourceFilter === newPrefObj.sourceFilter)) {
                  samesectionduplicateindex = tm_pref_id
                }
              }
              
            }
          })
        }
        // if(tm_prefs.length) {
        //   tm_prefs.forEach((itm, i) => {
        //     if(itm.SecurityName === newPrefObj.SecurityName && itm.SecurityTicker === newPrefObj.SecurityTicker && itm.columnFilter === newPrefObj.columnFilter) {
        //       samesectionduplicateindex = i
        //     }
        //   })
        // }
        // console.log('section duplicte', samesectionduplicateindex)
        // console.log('')
        // return
        if(samesectionduplicateindex !== '') {
          let existingColumnName = `Coin_Column_details__${samesectionduplicateindex}` 
          // console.log(SecNameArr, existingColumnName)
          console.log('same column exists')
          setTimeout(() => {
            console.log('calling modal close, same column exists')
            this.props.SetPostponeUpdate(false);
            this.props.modalClose();
          }, 1500)
          
          this.props.switchcolumnfocus(existingColumnName)
          
        } else {
          // console.log('new preference', newPrefObj)
          // return
          tm_prefs.push(newPrefObj)
          let insertAfterIndex = columnlist.indexOf(listkey)
          // console.log(insertAfterIndex, columnlist)
          
          columnorderlist.splice(insertAfterIndex +1, 0, newPrefObj)
          // check if column filter category has more than the specified amount of column
          let new_columnFilter = newPrefObj.columnFilter
          let new_columnFilter_count = 0
          columnorderlist.forEach((column, i) => {
            if(new_columnFilter === 'Search') {
              if(column.columntype === 'SavedSearch') {
                new_columnFilter_count++;
              } else if(column.columntype === 'tmcd' && column.columnFilter === 'Search') {
                new_columnFilter_count++;
              }
              // console.log('for search filter count', new_columnFilter_count)
            } else {
              if(column.columntype === 'tmcd') {
                if(column.columnFilter === new_columnFilter) {
                  new_columnFilter_count++;
                }
              }
            }
            
          })
          
          // console.log(tm_prefs, this.props.tmDetailPreferences, columnorderlist, columnlist)
          // return
          // if column filter category has more than the specified amount of column remove the oldest one in the category
          if(new_columnFilter === 'Search') {
            if(new_columnFilter_count > 4) {
              let remove_search_index = false;
              let removingsearchcolumntype = ''
              columnorderlist.forEach((column, rm_indx) => {
                if(column.columntype === 'SavedSearch') {
                  if(!remove_search_index) {
                    remove_search_index = rm_indx
                    removingsearchcolumntype = 'SavedSearch'
                  }
                  
                } else if(column.columntype === 'tmcd' && column.columnFilter === 'Search') {
                  if(!remove_search_index) {
                    remove_search_index = rm_indx
                    removingsearchcolumntype = 'tmcd'
                  }
                }
              })
              if(removingsearchcolumntype === 'SavedSearch') {
                let saveid = columnorderlist[remove_search_index].UserSavedDataID
                let deletedData = this.state.searchSaveData[saveid];
                
                if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
                  this.props.deleteSavedSearch_LLU(deletedData);
                } else {
                  this.props.deleteSavedSearch(deletedData);
                }
                
                columnorderlist.splice(remove_search_index, 1)
              } else if(removingsearchcolumntype === 'tmcd') {
                let remove_tmpref_index = false
                tm_prefs.forEach((itm, ind) => {
                  if(itm.SecurityName === columnorderlist[remove_search_index].SecurityName && itm.SecurityTicker === columnorderlist[remove_search_index].SecurityTicker && (itm.sourceFilter && columnorderlist[remove_search_index].sourceFilter && itm.sourceFilter === columnorderlist[remove_search_index].sourceFilter)) {
                    if(remove_tmpref_index === false) {
                      remove_tmpref_index = ind
                    }
                  }
                })
                if(remove_tmpref_index !== false) {
                  let settingstoremove = tm_prefs[remove_tmpref_index]
                  let removelistkey = `Coin_Column_details__${remove_tmpref_index}`
                  let orderremoveAtIndex = false//columnlist.indexOf(removelistkey)
                  columnorderlist.forEach((column, index) => {
                    if(column.columntype === 'tmcd') {
                      if(column.SecurityName === settingstoremove.SecurityName && column.SecurityTicker === settingstoremove.SecurityTicker && column.columnFilter === new_columnFilter && (column.sourceFilter && settingstoremove.sourceFilter && column.sourceFilter === settingstoremove.sourceFilter)) {
                        if(orderremoveAtIndex === false) {
                          orderremoveAtIndex = index
                        }
                      }
                    }
                  })
                  let tmp_columnorderlist = JSON.parse(JSON.stringify(columnorderlist))
                  tmp_columnorderlist.splice(orderremoveAtIndex, 1)
                  columnorderlist = JSON.parse(JSON.stringify(tmp_columnorderlist))
    
                  let tmp_tm_prefs = JSON.parse(JSON.stringify(tm_prefs))
                  tm_prefs.splice(remove_tmpref_index, 1)
                  tm_prefs = JSON.parse(JSON.stringify(tmp_tm_prefs))
                }
              }
              
            }
          } else {
            if(new_columnFilter_count > 3) {
              let remove_search_index = false;
              let removingsearchcolumntype = ''
              columnorderlist.forEach((column, rm_indx) => {
                if(column.columntype === 'SavedSearch'  && column.columnFilter === new_columnFilter) {
                  if(!remove_search_index) {
                    remove_search_index = rm_indx
                    removingsearchcolumntype = 'SavedSearch'
                  }
                  
                } else if(column.columntype === 'tmcd' && column.columnFilter === new_columnFilter) {
                  if(!remove_search_index) {
                    remove_search_index = rm_indx
                    removingsearchcolumntype = 'tmcd'
                  }
                }
              })
              if(removingsearchcolumntype === 'SavedSearch') {
                let saveid = columnorderlist[remove_search_index].UserSavedDataID
                let deletedData = this.state.searchSaveData[saveid];
                if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
                  this.props.deleteSavedSearch_LLU(deletedData);
                } else {
                  this.props.deleteSavedSearch(deletedData);
                }
                
                columnorderlist.splice(remove_search_index, 1)
              } else if(removingsearchcolumntype === 'tmcd') {
                let remove_tmpref_index = false
                tm_prefs.forEach((itm, indx) => {
                  if(itm.columnFilter === new_columnFilter) {
                    if(remove_tmpref_index === false) {
                      remove_tmpref_index = indx
                    }
                  }
                })
                
                if(remove_tmpref_index !== false) {
                  
                  let settingstoremove = tm_prefs[remove_tmpref_index]
                  console.log(remove_tmpref_index, settingstoremove)
                  let removelistkey = `Coin_Column_details__${remove_tmpref_index}`
                  let orderremoveAtIndex = false//columnlist.indexOf(removelistkey)
                  columnorderlist.forEach((column, index) => {
                    if(column.columntype === 'tmcd') {
                      if(column.SecurityName === settingstoremove.SecurityName && column.SecurityTicker === settingstoremove.SecurityTicker && column.columnFilter === new_columnFilter) {
                        if(orderremoveAtIndex === false) {
                          orderremoveAtIndex = index
                        }
                      }
                    }
                  })
                  // console.log(tm_prefs, remove_tmpref_index, columnorderlist, orderremoveAtIndex)
                  let tmp_columnorderlist = JSON.parse(JSON.stringify(columnorderlist))
                  tmp_columnorderlist.splice(orderremoveAtIndex, 1)
                  columnorderlist = JSON.parse(JSON.stringify(tmp_columnorderlist))
      
                  let tmp_tm_prefs = JSON.parse(JSON.stringify(tm_prefs))
                  tmp_tm_prefs.splice(remove_tmpref_index, 1)
                  tm_prefs = JSON.parse(JSON.stringify(tmp_tm_prefs))
                  // console.log(tmp_tm_prefs, remove_tmpref_index, tmp_columnorderlist, orderremoveAtIndex)
                  
                }
              }
              
              
            }
          }
          //update settings 
          let searchData = {
            sec : [`${TickerData.SecurityName}`],
            startDate : moment().utcOffset(0).subtract(7,'days').format('YYYY-MM-DD HH:mm:ss'),
            endDate : moment().utcOffset(0).format('YYYY-MM-DD HH:mm:ss'),
            withCurated : newPrefObj.CDSearch_with_gray,
            withBlack : newPrefObj.CDSearch_with_black,
            withYellow : newPrefObj.CDSearch_with_yellow,
            withOrange : newPrefObj.CDSearch_with_orange,
            withRed : newPrefObj.CDSearch_with_red
          }
          let TickerFeedReqData = {
            RequestState: true, 
            InitialFeedRequest: searchData.startDate, 
            LastFeedRequest: searchData.endDate,
            LastFeedRequestEnd: searchData.endDate,
            InitialFeedResponse: searchData.endDate,
            LastFeedResponse: '', 
          }
          // console.log(tm_prefs, columnorderlist)
          // return
          let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
          let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
          let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tm_prefs, 'coindetails');
          let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)
          
          let newPrefStr = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`
        
          
          PreferenceStrings.userPrefData.UserPreferencesString = newPrefStr;
          PreferenceStrings.userPrefData.UserName = this.props.userData.username;
          
          this.props.CloseTMDetails()
    
          this.props.updatepinnedcoindetailcolumn()
          if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
            this.props.setUserPreferences_LLU(PreferenceStrings.userPrefData, false, false, 'tmcd', '', false, TickerData);
          } else {
            this.props.setUserPreferences(PreferenceStrings.userPrefData, false, false, 'tmcd', '', false, TickerData);
          }
          
          // this.props.loadtickerdata(TickerData.SecurityName, TickerData.SecurityTicker, tm_prefs.length -1);
          this.props.searchCoinDetailTickerEvents(searchData, tm_prefs.length -1, TickerData.SecurityName);
        }
      } catch(err) {
        this.props.showModal('ErrorMsg', 'There seems to be a temporary error. Please refresh or try later. If the problem persists, ');
      }
      
      
  
    }
    
    
    
  }

  CloseCoinDetailColumn = (columnIndex, listkey) => {
    try {
      // this.props.SetPostponeUpdate(true);
      this.props.showModal('Loader');
      let { tmDetailPreferences, coin_column_preferences, columnlist } = this.props;
      let preferences = JSON.parse(JSON.stringify(this.props.preferences));
      if(preferences.user_interface.HasColumnOrderSettings === false) {
        preferences.user_interface.HasColumnOrderSettings = true;
      }
      let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))
      let removeAtIndex = columnlist.indexOf(listkey)

      let tm_prefs = JSON.parse(JSON.stringify(tmDetailPreferences));
      tm_prefs.splice(columnIndex, 1);

      columnorderlist.splice(removeAtIndex, 1)

      let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
      let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
      let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tm_prefs, 'coindetails');
      let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)

      let newPrefStr = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`
      // console.log(newPrefStr);
      PreferenceStrings.userPrefData.UserPreferencesString = newPrefStr;
      PreferenceStrings.userPrefData.UserName = this.props.userData.username;
      // this.props.CloseCoinDetailsColumn(columnIndex, SecurityName),
      this.props.updateclosedpinnedcolumn()
      if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
        this.props.setUserPreferences_LLU(PreferenceStrings.userPrefData);
      } else {
        this.props.setUserPreferences(PreferenceStrings.userPrefData);
      }
    } catch(err) {
      this.props.showModal('ErrorMsg', err.toString());
    }
    
    
  }

  coindetailcolumnmetricchange = (metric, index, listkey) => {
    let { tmDetailPreferences, coin_column_preferences, columnlist } = this.props;
    let preferences = JSON.parse(JSON.stringify(this.props.preferences));
    if(preferences.user_interface.HasColumnOrderSettings === false) {
      preferences.user_interface.HasColumnOrderSettings = true;
    }
    let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))
    let changeAtIndex = columnlist.indexOf(listkey)

    let tm_prefs = JSON.parse(JSON.stringify(tmDetailPreferences));
    tm_prefs[index].metric = metric

    columnorderlist[changeAtIndex] = tm_prefs[index];

    let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
    let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
    let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tm_prefs, 'coindetails');
    let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)
    Object.keys(PreferenceStrings).map((StrType) => {
      PreferenceStrings[`${StrType}`].UserName = this.props.userData.username;
      return null;
    });
    PreferenceStrings.userPrefData.UserPreferencesString = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`;
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.MixpanelProps.Category = tm_prefs[index].SecurityName;
    window.Mixpanel.MixpanelProps.Preference = 'Metric change';
    window.Mixpanel.MixpanelProps.Metric = metric
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track(`Coin Detail column Metric Preferences Changed`, window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username,
      });
      window.Mixpanel.actions.resetProp('Category');
      window.Mixpanel.actions.resetProp('Preference');
      window.Mixpanel.actions.resetProp('Metric');
    });
    if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
      this.props.setUserPreferences_LLU(PreferenceStrings.userPrefData);
    } else {
      this.props.setUserPreferences(PreferenceStrings.userPrefData);
    }
    
  }

  newColumnOrderSettings = (settings) => {
    // console.log(settings)
    if(settings.length) {
      // let preferences = JSON.parse(JSON.stringify(this.props.preferences));
      // preferences.user_interface.HasColumnOrderSettings = true;
      // let { tmDetailPreferences, coin_column_preferences } = this.props;
      // let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
      // // console.log(PreferenceStrings.userPrefData.UserPreferencesString)
      // let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
      // // console.log(CoinCoulmnStrings)
      // let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tmDetailPreferences, 'coindetails');
      // console.log(CoinDetailCoulmnStrings)
      // let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(settings)
      // console.log(ColumnOrderStrings)
      this.props.initiallysetcolumnorderlist(settings)
    }
  }

  verifynewsettings = (settings) => {
    if(settings.length) {
      let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))
      let hasDiff = false;
      columnorderlist.forEach((itm, i) => {
        if(JSON.stringify(itm) !== JSON.stringify(settings[i])) {
          hasDiff = true;
        }
      })
      if(hasDiff) {
        this.props.initiallysetcolumnorderlist(settings)
      }
      this.props.verifiednewsettings(true)
    }
  }



 

  render() {
    // console.log(this.props.tmDetailPreferences)
    return this.props.userData.userRole !== 'Blocked' ? (
      // <div id="storyWrapper">
        <TweetDeck
          {...this.props}
          homeviewport={this.props.homeviewport}
          storyState={this.state}
          initSaveSearch={this.initSaveSearch}
          deleteSaveSearch={this.deleteSaveSearch}
          updateNACEPreference={this.updateNACEPreference}
          updateSavedSearchPreference={this.updateSavedSearchPreference}
          updateSearchPreference={this.updateSearchPreference}
          updateTMAutoRefresh={this.props.updateTMAutoRefresh}
          filterSecurities={this.filterSecurities}
          getAllSecFilter={this.getAllSecFilter}
          setTMFrame={this.setTMFrame}
          TickerIconsList={this.props.TickerIconsList}
          SetTMActiveTab={this.SetTMActiveTab}
          updateUserPreferences_EventImportance={this.updateUserPreferences_EventImportance}
          toggleColumnCollapse={this.toggleColumnCollapse}
          toggleCollapseSavedSearchList={this.toggleCollapseSavedSearchList}
          tmListMaxCoinCount={this.props.tmListMaxCoinCount}
          tmtotalmaxcoincount={this.props.tmtotalmaxcoincount}
          tmFromWatchlist={this.props.tmFromWatchlist}
          freeTrial={this.props.freeTrial}
          daystogo={this.props.daystogo}
          // coin_column_preferences={this.props.coin_column_preferences}
          updateCoinColumnPreferences={this.updateCoinColumnPreferences}
          closeTopcoins={this.closeTopcoins}
          tmSortChange={this.tmSortChange}
          expandTopCoins={this.expandTopCoins}
          pinCoinColumn={this.pinCoinColumn}
          CloseCoinDetailColumn={this.CloseCoinDetailColumn}
          updateCoinDetailColumnSliderPreference={this.updateCoinDetailColumnSliderPreference}
          toggleCoinDetailColumnCollapse={this.toggleCoinDetailColumnCollapse}
          coindetailcolumnmetricchange={this.coindetailcolumnmetricchange}
          securitiesFilter={this.props.securitiesFilter}
          CloseRecentUpdates={this.CloseRecentUpdates}
          newColumnOrderSettings={this.newColumnOrderSettings}
          verifynewsettings={this.verifynewsettings}
          // collapseFlag={this.state.collapseFlag}
          // clickToCollapse={this.collapse}
        />
      // </div>
    ) : (
      ''
    );
  }
}
const mapStateToProps = (state) => {
  // console.log(state.trendingMentions.coin_column_preferences)
  return {
    searchData: state.search.searchData,
    preferencesTabMode: state.app_preferences.preferences.user_interface.Tab_Mode,
    preferencesEarlyEvents: state.app_preferences.preferences.user_interface.Early_Events,
    preferencesAnalyzedEvents: state.app_preferences.preferences.user_interface.Analyzed_Events,
    preferencesEarlyAnalyzedEvents: state.app_preferences.preferences.user_interface.Early_In_Analyzed_Events,
    preferencesMainstreamNews: state.app_preferences.preferences.user_interface.Mainstream_News,
    userpreference_requestState: state.app_preferences.preferenceRequestStatus.User_Preference,
    userpreference_NACE: state.app_preferences.preferences.event_importance_filters.Non_Active_Curated_Events.Show_NACE,
    userpreference_LOW: state.app_preferences.preferences.event_importance_filters.White_Colored_Events.Show_White,
    userpreference_MEDIUM: state.app_preferences.preferences.event_importance_filters.Gray_Colored_Events.Show_Gray,
    userpreference_HIGH: state.app_preferences.preferences.event_importance_filters.Yellow_Colored_Events.Show_Yellow,
    userpreference_HIGHER: state.app_preferences.preferences.event_importance_filters.Red_Colored_Events.Show_Red,
    userpreference_TM: state.app_preferences.preferences.user_interface.Trending_Mentions,
    Search_with_gray: state.app_preferences.preferences.search_preferences.Search_with_gray,
    Search_with_black: state.app_preferences.preferences.search_preferences.Search_with_black,
    Search_with_white: state.app_preferences.preferences.search_preferences.Search_with_white,
    Search_with_yellow: state.app_preferences.preferences.search_preferences.Search_with_yellow,
    Search_with_orange: state.app_preferences.preferences.search_preferences.Search_with_orange,
    Search_with_red: state.app_preferences.preferences.search_preferences.Search_with_red,
    CDSearch_with_gray: state.app_preferences.preferences.coindetailsearch_preferences.CDSearch_with_gray,
    CDSearch_with_black: state.app_preferences.preferences.coindetailsearch_preferences.CDSearch_with_black,
    CDSearch_with_yellow: state.app_preferences.preferences.coindetailsearch_preferences.CDSearch_with_yellow,
    CDSearch_with_orange: state.app_preferences.preferences.coindetailsearch_preferences.CDSearch_with_orange,
    CDSearch_with_red: state.app_preferences.preferences.coindetailsearch_preferences.CDSearch_with_red,
    Event_Collapsed_View: state.app_preferences.preferences.user_interface.Event_Collapsed_View,
    CD_Collapsed_View: state.app_preferences.preferences.user_interface.CD_Collapsed_View,
    Search_Collapsed_View: state.app_preferences.preferences.user_interface.Search_Collapsed_View,
    RecentUpdateView: state.app_preferences.preferences.user_interface.RecentUpdateView,
    HasColumnOrderSettings: state.app_preferences.preferences.user_interface.HasColumnOrderSettings,
    MenuFilter: state.app_preferences.preferences.user_interface.MenuFilter,
    // SavedSearch_Collapsed_View: state.app_preferences.preferences.user_interface.SavedSearch_Collapsed_View,
    req_error: state.error,
    searchSaveData: state.search.searchSaveData,
    searchsavedata_initload: state.search.searchsavedata_initload,
    preferences: state.app_preferences.preferences,
    securities: state.filters.securities,
    securitiesRawCollection: state.filters.securitiesRawCollection,
    tmActiveFrame: state.trendingMentions.tmFrame,
    activeTab: state.trendingMentions.activeTab,
    TickerIconsList: state.settings.TickerIconsList,
    TickerViewDetails: state.trendingMentions.tmDetails.TickerViewDetails,
    FromTopCoins: state.trendingMentions.tmDetails.FromTopCoins,
    TickerData: state.trendingMentions.tmDetails.TickerData,
    searchCount: state.search.searchCount,
    pinnedSearchCount: state.search.pinnedSearchCount,
    pinnedcoincolumn: state.trendingMentions.pinnedcoincolumn,
    pinnedcoindetailcolumn: state.trendingMentions.pinnedcoindetailcolumn,
    pinnedmainfeeds: state.settings.pinnedmainfeeds,
    closedpinnedcolumn: state.settings.closedpinnedcolumn,
    singleCoinSearch: state.search.singleCoinSearch,
    tmFromWatchlist: state.trendingMentions.tmFromWatchlist,
    tmListMaxCoinCount: state.trendingMentions.tmListMaxCoinCount,
    tmtotalmaxcoincount: state.trendingMentions.tmtotalmaxcoincount,
    freeTrial: state.settings.freeTrial,
    daystogo: state.settings.freeTrialDetails.daystogo,
    coin_column_preferences: state.trendingMentions.coin_column_preferences,
    tmDetailPreferences: state.trendingMentions.tmDetailPreferences,
    securitiesFilter: state.filters.securitiesFilter,
    columnorderlist: state.settings.columnorderlist,
    columnlist: state.settings.columnlist,
    verifiednewcolumnordersettings: state.settings.verifiednewcolumnordersettings,
    columnorderlist_initload: state.settings.columnorderlist_initload
    // SecuritiesTickerIdList: state.filters.SecuritiesTickerIdList
  };
};

const mapDispatchToProps = {
  ...feedActionCreators,
  ...preferencesActionCreator,
  ...searchActionCreators,
  ...tmActionCreators,
  updateTMAutoRefresh,
  setTMFrame,
  ChangeTMActiveTab,
  CloseTMDetails,
  resetTickerEvents,
  TMViewDetails,
  resetTM,
  TabColumnChangeRequest,
  addLogAction,
  switchcolumnfocus,
  initiallysetcolumnorderlist,
  verifiednewsettings,
  updateclosedpinnedcolumn,
  SetPostponeUpdate
};

export default connect(mapStateToProps, mapDispatchToProps)(Stories);
