//{ "EURUSD", "USDJPY", "GBPUSD", "AUDUSD", "USDCAD", "USDCHF", "NZDUSD", "EURJPY", "GBPJPY", "EURGBP", "AUDJPY", "EURAUD", "EURCHF", "GBPAUD", "GBPCAD", "AUDCAD", "GBPCHF", "AUDCHF", "EURCAD", "USDMXN", "Crude Oil WTI", "Gold", "Crude Oil Brent", "Natural Gas", "Copper", "Silver", "US 500", "UK 100", "US 100", "AU 200", "US 30", "DAX", "FR 40", "EU 50", "JP 225", "Nifty 50" };
export const extrasecurities = [
    {
        Description: null,
        Link: null,
        Name: 'EURUSD',
        Scope: '10000000',
        SecurityID: '649',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 728,
        Symbol: 'EURUSD',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'USDJPY',
        Scope: '10000000',
        SecurityID: '663',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 728,
        Symbol: 'USDJPY',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'GBPUSD',
        Scope: '10000000',
        SecurityID: '659',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 728,
        Symbol: 'GBPUSD',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'AUDUSD',
        Scope: '10000000',
        SecurityID: '648',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 728,
        Symbol: 'AUDUSD',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'USDCAD',
        Scope: '10000000',
        SecurityID: '813',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 728,
        Symbol: 'USDCAD',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'USDCHF',
        Scope: '10000000',
        SecurityID: '660',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 728,
        Symbol: 'USDCHF',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'NZDUSD',
        Scope: '10000000',
        SecurityID: '662',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 728,
        Symbol: 'NZDUSD',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'EURJPY',
        Scope: '10000000',
        SecurityID: '651',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 728,
        Symbol: 'EURJPY',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'GBPJPY',
        Scope: '10000000',
        SecurityID: '706',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 728,
        Symbol: 'GBPJPY',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'EURGBP',
        Scope: '10000000',
        SecurityID: '650',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 728,
        Symbol: 'EURGBP',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'AUDJPY',
        Scope: '10000000',
        SecurityID: '658',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 728,
        Symbol: 'AUDJPY',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'EURAUD',
        Scope: '10000000',
        SecurityID: '652',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 728,
        Symbol: 'EURAUD',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'EURCHF',
        Scope: '10000000',
        SecurityID: '653',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 728,
        Symbol: 'EURCHF',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'GBPAUD',
        Scope: '10000000',
        SecurityID: '8886',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 728,
        Symbol: 'GBPAUD',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'GBPCAD',
        Scope: '10000000',
        SecurityID: '704',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 728,
        Symbol: 'GBPCAD',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'AUDCAD',
        Scope: '10000000',
        SecurityID: '656',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 728,
        Symbol: 'AUDCAD',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'GBPCHF',
        Scope: '10000000',
        SecurityID: '8882',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 728,
        Symbol: 'GBPCHF',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'AUDCHF',
        Scope: '10000000',
        SecurityID: '657',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 728,
        Symbol: 'AUDCHF',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'EURCAD',
        Scope: '10000000',
        SecurityID: '654',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 728,
        Symbol: 'EURCAD',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'USDMXN',
        Scope: '10000000',
        SecurityID: '670',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 728,
        Symbol: 'USDMXN',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Crude Oil WTI',
        Scope: '10000000',
        SecurityID: '45',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 729,
        Symbol: 'WTI',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Gold',
        Scope: '10000000',
        SecurityID: '23',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 729,
        Symbol: 'XAU',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Crude Oil Brent',
        Scope: '10000000',
        SecurityID: '645',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 729,
        Symbol: 'Brent',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Natural Gas',
        Scope: '10000000',
        SecurityID: '47',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 729,
        Symbol: 'NG1',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Copper',
        Scope: '10000000',
        SecurityID: '27',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 729,
        Symbol: 'HG:CMX',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Silver',
        Scope: '10000000',
        SecurityID: '24',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 729,
        Symbol: 'XAG',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'US 500',
        Scope: '10000000',
        SecurityID: '8858',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 730,
        Symbol: 'US500',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'UK 100',
        Scope: '10000000',
        SecurityID: '8851',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 730,
        Symbol: 'UK100',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'US 100',
        Scope: '10000000',
        SecurityID: '8857',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 730,
        Symbol: 'Us100',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'AU 200',
        Scope: '10000000',
        SecurityID: '8860',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 730,
        Symbol: 'AU200',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'US 30',
        Scope: '10000000',
        SecurityID: '8852',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 730,
        Symbol: 'US30',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'DAX',
        Scope: '10000000',
        SecurityID: '6742',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 730,
        Symbol: 'GE40',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'FR 40',
        Scope: '10000000',
        SecurityID: '772',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 730,
        Symbol: 'FR40',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'EU 50',
        Scope: '10000000',
        SecurityID: '768',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 730,
        Symbol: 'EU50',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'JP 225',
        Scope: '10000000',
        SecurityID: '8859',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 730,
        Symbol: 'JP225',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Nifty 50',
        Scope: '10000000',
        SecurityID: '8875',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 730,
        Symbol: 'NIFTY50',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Bitcoin',
        Scope: '10000000',
        SecurityID: '1375',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'BTC',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Ethereum',
        Scope: '10000000',
        SecurityID: '1376',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'ETH',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Solana',
        Scope: '10000000',
        SecurityID: '1975',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'SOL',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Dogecoin',
        Scope: '10000000',
        SecurityID: '1383',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'DOGE',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'BNB',
        Scope: '10000000',
        SecurityID: '1380',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'BNB',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'XRP',
        Scope: '10000000',
        SecurityID: '1379',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'XRP',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Shiba INU',
        Scope: '10000000',
        SecurityID: '7005',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'SHIB',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Litecoin',
        Scope: '10000000',
        SecurityID: '1381',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'LTC',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Avalanche',
        Scope: '10000000',
        SecurityID: '1984',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'AVAX',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Bitcoin Cash',
        Scope: '10000000',
        SecurityID: '1968',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'BCH',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Cardano',
        Scope: '10000000',
        SecurityID: '1963',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'ADA',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Polygon',
        Scope: '10000000',
        SecurityID: '2009',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'MATIC',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Internet Computer',
        Scope: '10000000',
        SecurityID: '6353',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'ICP',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Chainlink',
        Scope: '10000000',
        SecurityID: '1382',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'LINK',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Filecoin',
        Scope: '10000000',
        SecurityID: '1971',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'FIL',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Polkadot',
        Scope: '10000000',
        SecurityID: '1378',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'DOT',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Tron',
        Scope: '10000000',
        SecurityID: '1977',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'TRX',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'EOS',
        Scope: '10000000',
        SecurityID: '1981',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'EOS',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Stellar',
        Scope: '10000000',
        SecurityID: '1969',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'XLM',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Aave',
        Scope: '10000000',
        SecurityID: '1978',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'AAVE',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Hedera',
        Scope: '10000000',
        SecurityID: '1991',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'HBAR',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Tezos',
        Scope: '10000000',
        SecurityID: '1988',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 718,
        Symbol: 'XTZ',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Tesla',
        Scope: '10000000',
        SecurityID: '1373',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 727,
        Symbol: 'TSLA',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Apple',
        Scope: '10000000',
        SecurityID: '1372',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 727,
        Symbol: 'APPL',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Nvidia',
        Scope: '10000000',
        SecurityID: '4662',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 727,
        Symbol: 'NVDA',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Amazon',
        Scope: '10000000',
        SecurityID: '4121',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 727,
        Symbol: 'AMZN',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Alphabet',
        Scope: '10000000',
        SecurityID: '4422',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 727,
        Symbol: 'GOOG',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Microsoft',
        Scope: '10000000',
        SecurityID: '4619',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 727,
        Symbol: 'MSFT',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Meta',
        Scope: '10000000',
        SecurityID: '518',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 727,
        Symbol: 'META',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Adidas AG',
        Scope: '10000000',
        SecurityID: '3743',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 727,
        Symbol: 'ADS',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Nike',
        Scope: '10000000',
        SecurityID: '4644',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 727,
        Symbol: 'NKE',
        VirtualParents: null
    },
    {
        Description: null,
        Link: null,
        Name: 'Volkswagen',
        Scope: '10000000',
        SecurityID: '8720',
        SecurityParent: 0,
        SecurityType: null,
        SecurityTypeID: 727,
        Symbol: 'VOW3',
        VirtualParents: null
    },

]