import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { APPPATH } from '../../../../modules/helper.module';
import * as Auth from '../../../../actions/authenticate.action';

class PasswordlessLoginPopup extends Component {
    constructor(props) {
      super();
      this.state = {
        _this: this,
        passwordlessloginError: props.passwordlesslogin_usernotfound
      };
    }

    componentDidMount() {
        
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ...this.state,
            passwordlessloginError: nextProps.passwordlesslogin_usernotfound
        })
    }

    
    
    
    render() {
        
       const { passwordlessloginError } = this.state;
        console.log(passwordlessloginError)
        return(
            <div className="watchlist-content trd-window-brdr justify-content-center text-center">
                <div className="btn tradeWindowClose text-white nopad marg-0" onClick={() => {
                    this.props.setsendmagiclinkerror(false);
                    this.props.onHide();

                }}>
                    <span className="pad10">
                    <img className="tradeWindowClose-closeicon" src={`${APPPATH.ImageUrl}/button-close.svg`} alt="" />
                    </span>
                </div>
                <Row className="marg-0 storymenuoptions-tabheader">
                    <Col className="nopad">
                        <div className="nav nav-tabs watchlist nav-fill  ">
                            <div className={`nav-item fs16 active`}>
                                <span>
                                
                                </span>
                                <span className="verifyemailcaption">{ `Login without Password` }</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="cScroll scroll-notrack marg-0 justify-content-left  pad5 text-left " style={{ maxHeight: 400 }}>
                    {
                        passwordlessloginError ?(
                            <Col lg={12} sm={12} xs={12} md={12} xl={12} className="justify-content-center text-center padb15 fs14"> 
                                We could not find <span style={{color: '#50c0ff'}}><b>{this.props.modalData.username}</b></span>. Please check your email-id.
                            </Col>
                        ) : (
                            <Col lg={12} sm={12} xs={12} md={12} xl={12} className="justify-content-center text-center padb15 fs14"> 
                                We just sent an email to <span style={{color: '#50c0ff'}}><b>{this.props.modalData.username}</b></span>. Please check your inbox and click on the confirmation link.
                            </Col>
                        )
                    }
                    
                    
                </Row>
                
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
    //    userData: state.settings.userData
        passwordlesslogin_usernotfound: state.error.passwordlesslogin_usernotfound
    }
}
  
const mapDispatchToProps = {
    ...Auth
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordlessLoginPopup)