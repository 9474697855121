import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Logo from './logo';
import PremiumButton from './PremiumButton';
import PremiumButtonMobile from './PremiumButtonMobile';
import PremiumTag from './PremiumTag';
import Logmenu from './logmenu';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { APPPATH, TrendingMentionsHelper } from '../../../modules/helper.module';

class Headbar extends Component {
  constructor() {
    super();
    this.state = {
      searchTogglerIsHidden: true,
      searchContent: '',
      logoutTip: false,
    };
    this.OverlayRef = React.createRef();
    // window.sessionStorage.removeItem('isProFlag')
  }
  componentDidMount = () => {
    // console.log(window.sessionStorage.getItem('isProFlag'), this.props.userData.isPro)
    // if (window.sessionStorage.getItem('isProFlag') === 'true' && this.props.userData.isPro === undefined) {
    //   this.props.togglePro();
    // }
    // if (window.sessionStorage.getItem('isProFlag') === 'false') {
    //   this.props.togglePro();
    // }
  };

  componentWillReceiveProps(nextProps) {
    // console.log(window.sessionStorage.getItem('isProFlag'), this.props.userData.isPro, nextProps.userData.isPro)
    if(window.sessionStorage.getItem('isProFlag') === null && nextProps.userData.isPro === false) {
      this.props.togglePro();
    }
    if (this.props.location.pathname !== nextProps.location.pathname) {
      let sth =
        nextProps.location.state && !nextProps.location.state.searchTogglerIsHidden
          ? nextProps.location.state.searchTogglerIsHidden
          : true;

      this.setState({
        ...this.state,
        searchTogglerIsHidden: sth,
        searchContent: '',
      });
    }
    // if (nextProps.searchData === {}) {
    //   this.setState({
    //     ...this.state,
    //     searchTogglerIsHidden: true,
    //     searchContent: '',
    //   });
    // }
  }
  // componentDidUpdate = () => {
  //   // if(this.props.page !== 'login' && typeof this.props.setHomeBodyStyle !== 'undefined') {
  //   //   this.props.setHomeBodyStyle(false)
  //   // }

  // }

  toggleLogout = () => {
    this.setState({
      ...this.state,
      logoutTip: !this.state.logoutTip,
    });
  };
  redirector = (target) => {
    this.setState(
      {
        ...this.state,
        logoutTip: !this.state.logoutTip,
      },
      () => {
        this.props.redirect(target);
      }
    );
  };
  logout = () => {
    this.props.logout();
  };
  render() {
    const { page, userData, ActiveColumn } = this.props;
    // console.log(ActiveColumn);
    let VideoHelpLink = '';
    switch(ActiveColumn) {
      case 'Main Feeds':
        VideoHelpLink = 'IntroVideo';
        break;
      case 'Breaking Feeds':
        VideoHelpLink = 'IntroVideo';
        break;
      case 'Searched Feeds':
        VideoHelpLink = 'search';
        break;
      case 'Analyzed Feeds':
        VideoHelpLink = 'IntroVideo';
        break;
      case 'Trending Mentions':
        VideoHelpLink = 'top-daily-coins';
        break;
      case 'Trending Mentions Details':
        VideoHelpLink = 'coin-column';
        break;
      default:
        break;
    }
    if (ActiveColumn.toLowerCase().includes('savedsearch')) {
      VideoHelpLink = 'search';
    }
    let searchInit = '';
    let logout = '';
    let logMenu = '';
    let advanceTopCoinsSwitch = '';
    let helpervideo = '';
    if (
      page === 'stories' ||
      page === 'securities' ||
      page === 'settings' ||
      page === 'changepassword' ||
      page === 'contactus' ||
      page === 'feedbacks' ||
      page === 'userpreferences' ||
      page === 'termsandconditions' ||
      page === 'privacypolicy' ||
      page === 'faq'
    ) {
      if (
        page !== 'settings' &&
        page !== 'changepassword' &&
        page !== 'contactus' &&
        page !== 'feedbacks' &&
        page !== 'userpreferences' &&
        page !== 'termsandconditions' &&
        page !== 'privacypolicy' &&
        page !== 'faq'
      ) {
        searchInit = '';
        // <i className="fa fa-search float-right search-init-min" onClick={this.toggleSearch}></i>
      }

      logout =
        this.props.userData.userRole !== 'Blocked' ? (
          <i className={`fa fa-align-justify float-right search-init-min `} onClick={this.toggleLogout}></i>
        ) : (
          <i className={`fa fa-sign-out float-right search-init-min `} onClick={this.logout}></i>
        );
      logMenu =
        this.props.userData.userRole !== 'Blocked' ? (
          <Logmenu
            {...this.props}
            logoutTip={this.state.logoutTip}
            toggleOverlay={this.props.toggleOverlay}
            toggleLogout={this.toggleLogout}
            searchToggler={this.state.searchTogglerIsHidden}
            redirector={this.redirector}
          />
        ) : (
          ''
        );
      // advanceTopCoinsSwitch = (
      //   <div className="toggle-wrapper" ref={this.OverlayRef} style={{ position: 'relative' }}>
      //     {/* <small>Pro</small> */}
      //     {!userData.isPro && <span style={{ color: '#96a2ab' }}>Pro</span>}
      //     {userData.isPro && <span style={{ color: '#2281b5', fontWeight: 'bold' }}>Pro</span>}

      //     <label className="switch">
      //       <input
      //         type="checkbox"
      //         onChange={() => {
      //           this.props.togglePro();
      //         }}
      //         checked={userData.isPro}
      //       />
      //       <span className="slider round"></span>
      //     </label>
      //   </div>
      // );
      helpervideo = (
        <span className='float-right'>
          <button className="tooltip-button " style={{position: 'relative'}}>
            <span 
            className='helpervideobutton'
              // ref={this.OverlayRef}
              // onMouseEnter={(e) =>
              //   this.props.toggleOverlay(true, this.OverlayRef, this.state.toolTipText, ToolTipStyleData)
              // }
              // onMouseLeave={(e) =>
              //   this.props.toggleOverlay(false)
              // }
              
            >
              <img
                
                style={{ marginRight: 5 }}
                src={`${APPPATH.ImageUrl}/video-help.png`}
                className="helper-image helpervideobutton"
                alt=""
                onClick={() => this.props.showModal(VideoHelpLink)}
                
              />
            </span>
          </button>
        </span>
      )
    }
    return (
      <Row className={`bg-headbar bg-hdbr1 marg-0 padtb10lr12 smheadbarpad justify-content-center text-center ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
        <Col
          lg={!this.props.userData.isLoggedIn ? 5 : 7}
          sm={!this.props.userData.isLoggedIn ? 5 : 7}
          xs={!this.props.userData.isLoggedIn ? 5 : 7}
          md={!this.props.userData.isLoggedIn ? 5 : 7}
          xl={!this.props.userData.isLoggedIn ? 5 : 7}
          className={` text-center ${!this.state.searchTogglerIsHidden ? 'nodisp' : ''} padl3 padright0`}
        >
          <Logo noTitle={false} align={true} beta={false} userpackage={userData.userPackage.toLowerCase()} infreetrial={TrendingMentionsHelper.FreeSubscriptionFilter()}/>
          {/* {userData.userPackage && userData.userPackage.toLowerCase() === 'premium' && (
            <PremiumTag toggleOverlay={this.props.toggleOverlay} noTitle={false} align={true} beta={false} />
          )} */}
          {userData.userPackage &&
            isMobile &&
            this.props.viewPremiumButton &&
            userData.userPackage.toLowerCase() === 'bronze' && (
              <PremiumButtonMobile
                noTitle={false}
                align={true}
                beta={false}
                toggleConfirmPopup={this.props.toggleConfirmPopup}
                popup={true}
              />
            )}
          {userData.userPackage &&
            !isMobile &&
            this.props.viewPremiumButton &&
            userData.userPackage.toLowerCase() === 'bronze' && (
              <span style={{float: 'left'}}>
                <PremiumButton
                  noTitle={false}
                  align={true}
                  beta={false}
                  toggleConfirmPopup={this.props.toggleConfirmPopup}
                  popup={true}
                  isCalledFromTrendingColumn={false}
                />
              </span>
            )}
        </Col>

        {this.state.searchTogglerIsHidden && this.props.userData.isLoggedIn ? (
          <Col
            lg={5}
            sm={5}
            xs={5}
            md={5}
            xl={5}
            className={`padright5 ${!this.state.searchTogglerIsHidden ? 'nodisp' : ''} searchActionBoxMin `}
          >
            {logout}
            {searchInit}
            {/* {advanceTopCoinsSwitch} */}
            {isMobile && helpervideo}
            {logMenu}
            {!this.props.VerifiedEmail &&
            !isMobile &&
            this.props.viewVerifyEmailButton &&
            this.props.userData.isLoggedIn ? (
              <div className=" dispinb fright verify-email-margin">
                <a onClick={() => this.props.showModal('VerifyEmail')} className="bronzeBtn verify pointer">
                  Verify Email
                </a>
              </div>
            ) : (
              ''
            )}
          </Col>
        ) : (
          <Col
            lg={12}
            sm={12}
            xs={12}
            md={12}
            xl={12}
            className={`nopad ${this.state.searchTogglerIsHidden ? 'nodisp' : ''}`}
          ></Col>
        )}
      </Row>
    );
  }
}

const mapStateToApp = (state) => {
  return {
    // showPremiumBanner: state.settings.showPremiumBanner,
    VerifiedEmail: state.settings.VerifiedEmail,
    ActiveColumn: state.settings.ActiveColumn,
    Theme: state.app_preferences.preferences.user_interface.Theme,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToApp, mapDispatchToProps)(Headbar);
