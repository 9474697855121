export const ArabicTranslations = {
    "Sentiment": "تحسسات",
    "Positive": "إيجابي",
    "Negative": "سلبي",
    "Negative AI Sentiment": "تحسسات الذكاء الاصطناعي السلبية",
    "Positive AI Sentiment": "تحسسات الذكاء الاصطناعي الايجابية",
    "Yellow": "أصفر",
    "Orange": "البرتقالي",
    "Red": "أحمر",
    "Alert": "تحذير",
    "Regular": "عادي",
    "Important": "مهم",
    "Critical": "شديد الأهمية",
    "Critical AI Alert": "تنبيه طارئ للذكاء الاصطناعي ",
    "Important AI Alert": "تنبيه مهم للذكاء الاصطناعي",
    "Regular AI Alert": "تنبيه عادي للذكاء الاصطناعي",
    "Price Change": "تغيير الأسعار",
    "Price Prediction": "التنبؤ بالسعر",
    "Technical Analysis": "التحليل الفني",
    "Product Launch": "طرح المنتج",
    "Labor Update": "تحديث العمل",
    "Legal Update": "التحديث القانوني",
    "Regulatory Update": "التحديث التنظيمي",
    "Security Update": "التحديث الأمني",
    "Project Update": "تحديث المشروع",
    "Company Update": "تحديث الشركة",
    "Blockchain Update": "تحديث البلوكتشين",
    "Whale Trade": "تجارة الحيتان",
    "Exchange Update": "تحديث الصرف",
    "Airdrop": "إرسال سريع",
    "Supply Update": "تحديث العرض",
    "Demand Update": "تحديث الطلب",
    "Investment": "استثمار",
    "Financial Update": "التحديث المالي",
    "Agreement": "اتفاق",
    "Announcement": "إعلان",
    "Feedback": "تعليق",
    "Charity": "صدقة",
    "General Update": "التحديث العام",
    "Trade on": "التداول في",
    "Legislative Update": "التحديث التشريعي",
    "Economic Update": "التحديث الاقتصادي",
    "Political Update": "التحديث السياسي",
    "Central Bank Update": "تحديث البنك المركزي",
    "Trade Update": "تحديث التداول",
    "Acquisition Update": "تحديث الاستحواذ",
    "Agreement Update": "تحديث الاتفاقية",
    "Chatter": "ضجيج السوق",
    "Commercial Update": "تحديث تجاري",
    "Earnings Update": "تحديث الأرباح",
    "Feedback": "تقييم",
    "Influencers": "المؤثرون",
    "Leader Update": "تحديث القائد",
    "Merger Update": "تحديث الاندماج",
    "Shipping Update": "تحديث الشحن",
    "Social Spikes": "تغييرات اجتماعية حادة",
    "Top Ranking": "على مستوى عالي",
    "Market Update": "تحديث السوق",
    "Dividend Update": "تحديث الأرباح",
    "Stock Split": "تقسيم الأسهم",
    "Oil Field Update": "تحديث حقل النفط",
    "Oil Refinery Update": "تحديث معمل تكرير النفط",
    "Gas Production Update": "تحديث إنتاج الغاز",
    "Mine Update": "تحديث الألغام",
    "Natural Disaster": "كارثة طبيعية",
    "Buy": "شراء",
    "Sell": "بيع",
    "Up Trend Potential": "احتمالية الاتجاه الصاعد",
    "Down Trend Potential": "احتمالية الاتجاه الهبوطي",
    "View on Algense": "شرح عن Algense",
    "From": "من",
    "Sources": "مصادر",
    "Across": "عبر",
    "Detected": "تم الكشف",
    "Updated": "تم التحديث",
    "minutes": "دقيقة",
    "seconds": "ثواني",
    "hours": "ساعة",
    "days": "يوم",
    "weeks": "أسبوع",
    "ago": "منذ",
    "year": "سنة",
    "years": "سنين",
    "Updated 12 minutes ago": "تم التحديث منذ 12 دقيقة",
    "Detected 12 minutes ago": "تم الكشف عنه منذ 12 دقيقة",
    "Positive AI Sentiment For Event": "مشاعر إيجابية للذكاء الاصطناعي بخصوص الحدث",
    "Negative AI Sentiment For Event": "مشاعر سلبية للذكاء الاصطناعي بخصوص الحدث",
    "Number of sources mentioning this event": "عدد المصادر التي ذكرت هذا الحدث",
    "Total number of followers for event sources": "إجمالي عدد المتابعين لمصادر الأحداث",
    "Event Magnitude Based on Sources Credibility and Followers": "حجم الحدث بناءً على مصداقية المصادر والمتابعين)",
    'Gold': 'الذهب',
    'USDJPY': 'USD/JPY',
    'Bitcoin': 'Bitcoin',
    'BTC': 'Bitcoin',
    'EURUSD': 'EUR/USD',
    'Crude Oil WTI': 'النفط الخام WTI',
    'Crude Oil Brent': 'النفط الخام Brent',
    'Natural Gas': 'الغاز الطبيعي',
    'US 100': 'US Tech 100',
    'US 30': 'US 30',
    'US 500': 'US 500',
    'GBPUSD': 'GBP/USD',
    'GBPJPY': 'GBP/JPY',
    'EURJPY': 'EUR/JPY',
    'JP 225': 'Japan 225 Yen',
    'Ethereum': 'Ethereum',
    'ETH': 'Ethereum',
    'Silver': 'الفضة',
    'Copper': 'نحاس',
    'AUDUSD': 'AUD/USD',
    'Nifty 50': 'NIFTY 50',
    'DAX': 'Germany 40',
    'Solana': 'Solana',
    'SOL': 'Solana',
    'AUDJPY': 'AUD/JPY',
    'DogeCoin': 'Dogecoin',
    'DOGE': 'Dogecoin',
    'Shiba INU': 'SHIBA INU (1000)',
    'SHIB': 'SHIBA INU (1000)',
    'USDCAD': 'USD/CAD',
    'USDCHF': 'USD/CHF',
    'Bitcoin Cash': 'Bitcoin Cash',
    'BCH': 'Bitcoin Cash',
    'Internet Computer': 'Internet Computer',
    'ICP': 'Internet Computer',
    'NZDUSD': 'NZD/USD',
    'USDMXN': 'USD/MXN',
    'Tesla': 'Tesla',
    'Nvidia': 'nVIDIA',
    'Apple': 'Apple',
    'Microsoft': 'Microsoft',
    'Trade': 'تداول',
    'Top AI Events': 'أهم أحداث الذكاء الاصطناعي'
};

export const SpanishTranslations = {
    "Sentiment": "Sentimiento",
    "Positive": "Positivo",
    "Negative": "Negativo",
    "Negative AI Sentiment": "Sentimiento Negativo de la IA",
    "Positive AI Sentiment": "Sentimiento Positivo de la IA",
    "Yellow": "Amarillo",
    "Orange": "Naranja",
    "Red": "Roja",
    "Alert": "Alerta",
    "Regular": "Regular",
    "Important": "Importante",
    "Critical": "Crítico",
    "Critical AI Alert": "Aviso crítico de IA",
    "Important AI Alert": "Importante aviso de IA",
    "Regular AI Alert": "Aviso periodico de IA",
    "Price Change": "Cambio de precio",
    "Price Prediction": "Predicción de precios",
    "Technical Analysis": "Análisis técnico",
    "Product Launch": "Lanzamiento de producto",
    "Labor Update": "Actualización Laboral",
    "Legal Update": "Actualización Legal",
    "Regulatory Update": "Actualización Regulatoria",
    "Security Update": "Actualización De Seguridad",
    "Project Update": "Actualización Del Proyecto",
    "Company Update": "Actualización De La Empresa",
    "Blockchain Update": "Actualización De Cadena De Bloques",
    "Whale Trade": "Trading De Ballenas",
    "Exchange Update": "Actualización De Intercambio",
    "Airdrop": "Airdrop",
    "Supply Update": "Actualización De Suministros",
    "Demand Update": "Actualización De La Demanda",
    "Investment": "Inversión",
    "Financial Update": "Actualización Financiera",
    "Announcement": "Anuncio",
    "Feedback": "Retroalimentación",
    "Charity": "Caridad",
    "General Update": "Actualización General",
    "Acquisition Update": "Actualización De Adquisición",
    "Agreement Update": "Actualización Del Acuerdo",
    "Chatter": "Charla",
    "Commercial Update": "Actualización Comercial",
    "Earnings Update": "Actualización De Ganancias",
    "Economic Update": "Actualización Económica",
    "Influencers": "Influencers",
    "Leader Update": "Actualización de guía",
    "Legislative Update": "Actualización Legislativa",
    "Merger Update": "Actualización de incorporación",
    "Shipping Update": "Actualización De Envío",
    "Social Spikes": "Picos sociales",
    "Trade Update": "Actualización Comercial",
    "Top Ranking": "Rango Más Alto",
    "Central Bank Update": "Actualización Del Banco Central",
    "Market Update": "Actualización Del Mercado",
    "Dividend Update": "Actualización De Dividendos",
    "Stock Split": "División De Acciones",
    "Political Update": "Actualización Política",
    "Oil Field Update": "Actualización Del Campo Petrolero",
    "Oil Refinery Update": "Actualización De Refinería De Petróleo",
    "Gas Production Update": "Actualización De Producción De Gas",
    "Mine Update": "Actualización De La Mina",
    "Natural Disaster": "Desastre Natural",
    "Trade on": "Opera en",
    "Buy": "Comprar",
    "Sell": "Vender",
    "Up Trend Potential": "Potencial tendencia al alza",
    "Down Trend Potential": "Potencial de tendencia a la baja",
    "View on Algense": "Ver en Algense",
    "From": "De",
    "Sources": "Fuentes",
    "Across": "en",
    "Detected": "Detectado",
    "Updated": "Actualizado",
    "minutes": "minutos",
    "minute": "minuto",
    "seconds": "segundos",
    "hours": "horas",
    "hour": "hora",
    "days": "días",
    "day": "día",
    "weeks": "semanas",
    "weeks": "semana",
    "years": "años",
    "year": "año",
    "Detected ago": "Detectado hace",
    "Updated ago": "Actualizado hace",
    "Updated 12 minutes ago": "Actualizado hace 12 minutos",
    "Detected 12 minutes ago": "Detectado hace 12 minutos",
    "Positive AI sentiment for event": "Sentimiento positivo de la IA para el evento",
    "Negative AI sentiment for event": "Sentimiento negativo de la IA para el evento",
    "Number of sources mentioning this event": "Número de fuentes que mencionan este acontecimiento",
    "Total number of followers for event sources": "Total número de seguidores por acontecimiento",
    "Event Magnitude Based on Sources Credibility and Followers": "La magnitud de acontecimientos basados en las fuentes de credibilidad y seguidores",
    'Gold': 'Oro',
    'USDJPY': 'USD/JPY',
    'Bitcoin': 'Bitcoin',
    'BTC': 'Bitcoin',
    'EURUSD': 'EUR/USD',
    'Crude Oil WTI': 'Petróleo WTI',
    'Crude Oil Brent': 'Petróleo Brent',
    'Natural Gas': 'Gas natural',
    'US 100': 'US Tech 100',
    'US 30': 'US 30',
    'US 500': 'US 500',
    'GBPUSD': 'GBP/USD',
    'GBPJPY': 'GBP/JPY',
    'EURJPY': 'EUR/JPY',
    'JP 225': 'Japan 225 Yen',
    'Ethereum': 'Ethereum',
    'ETH': 'Ethereum',
    'Silver': 'Plata',
    "Copper": "Cobre",
    'AUDUSD': 'AUD/USD',
    'Nifty 50': 'NIFTY 50',
    'DAX': 'Germany 40',
    'Solana': 'Solana',
    'SOL': 'Solana',
    'AUDJPY': 'AUD/JPY',
    'DogeCoin': 'Dogecoin',
    'DOGE': 'Dogecoin',
    'Shiba INU': 'SHIBA INU (1000)',
    'SHIB': 'SHIBA INU (1000)',
    'USDCAD': 'USD/CAD',
    'USDCHF': 'USD/CHF',
    'Bitcoin Cash': 'Bitcoin Cash',
    'BCH': 'Bitcoin Cash',
    'Internet Computer': 'Internet Computer',
    'ICP': 'Internet Computer',
    'NZDUSD': 'NZD/USD',
    'USDMXN': 'USD/MXN',
    'Tesla': 'Tesla',
    'Nvidia': 'nVIDIA',
    'Apple': 'Apple',
    'Microsoft': 'Microsoft',
    'Trade': 'Operar',
    'Top AI Events': 'Principales eventos de IA'
}

export const JapaneseTranslations = {
    "Sentiment": "センチメント",
    "Positive": "ポジティブ",
    "Negative": "ネガティブ",
    "Negative AI Sentiment": "AIのネガティブセンチメント",
    "Positive AI Sentiment": "AIのポジティブセンチメント",
    "Yellow": "黄色",
    "Orange": "オレンジ",
    "Red": "赤",
    "Alert": "警告",
    "Regular": "通常",
    "Important": "重要",
    "Critical": "非常に重要",
    "Critical AI Alert": "AIの緊急警告",
    "Important AI Alert": "AIの重要警告",
    "Regular AI Alert": "AIの通常警告",
    "Price Change": "価格変動",
    "Price Prediction": "価格予測",
    "Technical Analysis": "技術分析",
    "Product Launch": "製品発売",
    "Labor Update": "労働更新",
    "Legal Update": "法的更新",
    "Regulatory Update": "規制更新",
    "Security Update": "セキュリティ更新",
    "Project Update": "プロジェクト更新",
    "Company Update": "会社更新",
    "Blockchain Update": "ブロックチェーン更新",
    "Whale Trade": "大口取引",
    "Exchange Update": "取引所更新",
    "Airdrop": "エアドロップ",
    "Supply Update": "供給更新",
    "Demand Update": "需要更新",
    "Investment": "投資",
    "Financial Update": "財務更新",
    "Agreement": "契約",
    "Announcement": "発表",
    "Feedback": "フィードバック",
    "Charity": "慈善",
    "General Update": "一般更新",
    "Trade on": "取引日",
    "Legislative Update": "法規制更新",
    "Economic Update": "経済更新",
    "Political Update": "政治更新",
    "Central Bank Update": "中央銀行更新",
    "Trade Update": "取引更新",
    "Acquisition Update": "買収更新",
    "Agreement Update": "契約更新",
    "Chatter": "噂",
    "Commercial Update": "商業更新",
    "Earnings Update": "収益更新",
    "Feedback": "フィードバック",
    "Influencers": "インフルエンサー",
    "Leader Update": "リーダー更新",
    "Merger Update": "合併更新",
    "Shipping Update": "出荷更新",
    "Social Spikes": "社会的急上昇",
    "Top Ranking": "上位ランク",
    "Market Update": "市場更新",
    "Dividend Update": "配当更新",
    "Stock Split": "株式分割",
    "Oil Field Update": "油田更新",
    "Oil Refinery Update": "製油所更新",
    "Gas Production Update": "ガス生産更新",
    "Mine Update": "鉱山更新",
    "Natural Disaster": "自然災害",
    "Buy": "買い",
    "Sell": "売り",
    "Up Trend Potential": "上昇トレンドの可能性",
    "Down Trend Potential": "下降トレンドの可能性",
    "View on Algense": "Algenseに関する見解",
    "From": "から",
    "Sources": "ソース",
    "Across": "全体",
    "Detected": "検出",
    "Updated": "更新済み",
    "minutes": "分",
    "seconds": "秒",
    "hours": "時間",
    "day": "日",
    "days": "日々",
    "weeks": "週間",
    "week": "週",
    "years": "年",
    "Updated 12 minutes ago": "12分前に更新",
    "Detected 12 minutes ago": "12分前に検出",
    "Detected ago": "前に検出",
    "Updated ago": "前に更新",
    "Asset may have a positive impact from this event": "このイベントにより資産にポジティブな影響がある可能性があります",
    "Asset may have a negative impact from this event": "このイベントにより資産にネガティブな影響がある可能性があります",
    "Number of sources mentioning this event": "このイベントに言及するソースの数",
    "Total number of followers for event sources": "イベントソースのフォロワー総数",
    "Event Magnitude Based on Sources Credibility and Followers": "ソースの信頼性とフォロワーに基づくイベントの規模",
    'Gold': '金',
    'USDJPY': '米ドル/円',
    'Bitcoin': 'ビットコイン',
    'BTC': 'ビットコイン',
    'EURUSD': 'ユーロ/米ドル',
    'Crude Oil WTI': 'WTI原油',
    'Crude Oil Brent': 'Brent原油',
    'Natural Gas': '天然ガス',
    'US 100': '米国テック100',
    'US 30': '米国30',
    'US 500': '米国500',
    'GBPUSD': 'ポンド/米ドル',
    'GBPJPY': 'ポンド/円',
    'EURJPY': 'ユーロ/円',
    'JP 225': '日本225円',
    'Ethereum': 'イーサリアム',
    'ETH': 'イーサリアム',
    'Silver': '銀',
    'Copper': '銅',
    'AUDUSD': '豪ドル/米ドル',
    'Nifty 50': 'NIFTY 50',
    'DAX': 'ドイツ40',
    'Solana': 'ソラナ',
    'SOL': 'ソラナ',
    'AUDJPY': '豪ドル/円',
    'DogeCoin': 'ドージコイン',
    'DOGE': 'ドージコイン',
    'Shiba INU': '柴犬コイン (1000)',
    'SHIB': '柴犬コイン (1000)',
    'USDCAD': '米ドル/カナダドル',
    'USDCHF': '米ドル/スイス',
    'Bitcoin Cash': 'ビットコインキャッシュ',
    'BCH': 'ビットコインキャッシュ',
    'Internet Computer': 'インターネットコンピューター',
    'ICP': 'インターネットコンピューター',
    'NZDUSD': 'NZドル/米ドル',
    'USDMXN': '米ドル/メキシコペソ',
    'Tesla': 'テスラ',
    'Nvidia': 'エヌビディア',
    'Apple': 'アップル',
    'Microsoft': 'マイクロソフト',
    'Trade': '取引',
    'Top AI Events': '人気の AI イベント'
}

export const PortugueseTranslations = {
    "Sentiment": "Sentimento",
    "Positive": "Positivo",
    "Negative": "Negativo",
    "NegativeSentiment": "Sentimento Negativo",
    "PositiveSentiment": "Sentimento Positivo",
    "Yellow": "Amarelo",
    "Orange": "Laranja",
    "Red": "Vermelho",
    "Alert": "Alerta",
    "Regular": "Regular",
    "Important": "Importante",
    "Critical": "Crítico",
    "CriticalAlert": "Alerta Critico",
    "ImportantAlert": "Alerta Importante",
    "RegularAlert": "Alerta Regular",
    "Price Change": "Mudança de preço",
    "Price Prediction": "Previsão de preço",
    "Technical Analysis": "Análise técnica",
    "Product Launch": "Lançamento de produto",
    "Labor Update": "Atualização Trabalhista",
    "Legal Update": "Atualização jurídica",
    "Regulatory Update": "Atualização regulatória",
    "Security Update": "Atualização de segurança",
    "Project Update": "Atualização do projeto",
    "Company Update": "Atualização da empresa",
    "Blockchain Update": "Atualização Blockchain",
    "Whale Trade": "Negociação de baleias",
    "Exchange Update": "Atualização do Exchange",
    "Airdrop": "Lançamento",
    "Supply Update": "Atualização de fornecimento",
    "Demand Update": "Atualização de demanda",
    "Investment": "Investimento",
    "Financial Update": "Atualização Financeira",
    "Agreement": "Acordo",
    "Announcement": "Anúncio",
    "Feedback": "Opinião",
    "Charity": "Caridade",
    "General Update": "Atualização Geral",
    "Trade on": "Negocie na",
    "Legislative Update": "Atualização Legislativa",
    "Economic Update": "Atualização Econômica",
    "Political Update": "Atualização Política",
    "Central Bank Update": "Atualização Do Banco Central",
    "Trade Update": "Atualização comercial",
    "Acquisition Update": "Atualização de aquisição",
    "Agreement Update": "Atualização do contrato",
    "Chatter": "Conversa",
    "Commercial Update": "Atualização Comercial",
    "Earnings Update": "Atualização de ganhos",
    "Feedback": "Opinião",
    "Influencers": "Influenciadores",
    "Leader Update": "Atualização do Líder",
    "Merger Update": "Atualização sobre fusão",
    "Shipping Update": "Atualização de envio",
    "Social Spikes": "Picos Sociais",
    "Top Ranking": "Classificação superior",
    "Market Update": "Atualização de mercado",
    "Dividend Update": "Atualização de dividendos",
    "Stock Split": "Divisão de ações",
    "Oil Field Update": "Atualização do campo petrolífero",
    "Oil Refinery Update": "Atualização da refinaria de petróleo",
    "Gas Production Update": "Atualização de produção de gás",
    "Mine Update": "Atualização da mina",
    "Natural Disaster": "Desastre natural",
    "Buy": "Comprar",
    "Sell": "Vender",
    "Up Trend Potential": "Potencial tendência de alta",
    "Down Trend Potential": "Potencial tendência de baixa",
    "View on Crowdsense": "Ver no Crowdsense",
    "View on Algense": "Ver no Algense",
    "Now": "Agora",
    "From": "De",
    "Sources": "fontes",
    "Source": "fonte",
    "Across": "do",
    "Detected": "Detectado",
    "Updated": "Actualizado",
    "minutes": "minutos",
    "minute": "minuto",
    "seconds": "segundos",
    "hours": "horas",
    "hour": "hora",
    "days": "días",
    "day": "día",
    "weeks": "semanas",
    "weeks": "semana",
    "years": "años",
    "year": "año",
    "Detected ago": "Detectado há",
    "Updated ago": "Atualizado há",
    "Updated 12 minutes ago": "Actualizado hace 12 minutos",
    "Detected 12 minutes ago": "Detectado hace 12 minutos",
    "Positive AI sentiment for event": "Sentimento positivo de IA para evento",
    "Negative AI sentiment for event": "Sentimento negativo de IA para evento",
    "Number of sources mentioning this event": "Número de fontes que mencionam este evento",
    "Total number of followers for event sources": "Número total de seguidores para fontes de eventos",
    "Event Magnitude Based on Sources Credibility and Followers": "Magnitude do Evento Baseada na Credibilidade das Fontes e Seguidores",
    'Gold': 'Ouro',
    'USDJPY': 'USD/JPY',
    'Bitcoin': 'Bitcoin',
    'BTC': 'Bitcoin',
    'EURUSD': 'EUR/USD',
    'Crude Oil WTI': 'Petróleo WTI',
    'Crude Oil Brent': "Petróleo Brent",
    'Natural Gas': 'Gás Natural',
    'US 100': 'US Tech 100',
    'US 30': 'US 30',
    'US 500': 'US 500',
    'GBPUSD': 'GBP/USD',
    'GBPJPY': 'GBP/JPY',
    'EURJPY': 'EUR/JPY',
    'JP 225': 'Nikkei 225',
    'Ethereum': 'Ethereum',
    'ETH': 'Ethereum',
    'Silver': 'Prata',
    "Copper": "Cobre",
    'AUDUSD': 'AUD/USD',
    'Nifty 50': 'NIFTY 50',
    'DAX': 'Germany 40',
    'Solana': 'Solana',
    'SOL': 'Solana',
    'AUDJPY': 'AUD/JPY',
    'DogeCoin': 'Dogecoin',
    'DOGE': 'Dogecoin',
    'Shiba INU': 'SHIBA INU (1000)',
    'SHIB': 'SHIBA INU (1000)',
    'USDCAD': 'USD/CAD',
    'USDCHF': 'USD/CHF',
    'Bitcoin Cash': 'Bitcoin Cash',
    'BCH': 'Bitcoin Cash',
    'Internet Computer': 'Internet Computer',
    'ICP': 'Internet Computer',
    'NZDUSD': 'NZD/USD',
    'USDMXN': 'USD/MXN',
    'Tesla': 'Tesla',
    'Nvidia': 'nVIDIA',
    'Apple': 'Apple',
    'Microsoft': 'Microsoft',
    'Trade': 'Troca',
    'Top AI Events': 'Principais eventos de IA'
}
