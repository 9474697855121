import appGlobalState from '../appconfig';
// import { tmData, updateTM } from '../static_data/dummydata';
import { TrendingMentionsHelper } from '../modules/helper.module';
import * as moment from 'moment';

const trendingMentionReducer = (state = {...appGlobalState.trendingMentions}, action) => {

    if(action.type === 'TM_SET_ONLY') {
      let list = {};
      let sortedTMIndex = [];
      if(action.payload.length) {
        let TMs = action.payload;
        TMs.forEach(tm => {
          const { Mentions, MentionsChange, Reach, ReachChange, Positive, PositiveChange, Negative, NegativeChange, Virality, SecurityPrice, Change, Volume } = TrendingMentionsHelper.TMExtract(tm)
          tm[`Mentions`] = Mentions;
          tm[`MentionsChange`] = MentionsChange;
          tm[`MentionsTrend`] = 0;
          tm[`Reach`] = Reach;
          tm[`ReachChange`] = ReachChange;
          tm[`ReachTrend`] = 0
          tm[`Positive`] = Positive;
          tm[`PositiveChange`] = PositiveChange;
          tm[`PositiveTrend`] = 0;
          tm[`Negative`] = Negative;
          tm[`NegativeChange`] = NegativeChange;
          tm[`NegativeTrend`] = 0;
          tm[`Virality`] = Virality;
          tm[`SecurityPrice`] = SecurityPrice;
          tm[`SecurityPriceTrend`] = 0;
          tm[`Change`] = Change;
          tm[`ChangeTrend`] = 0;
          tm[`Volume`] = Volume;
          // console.log(tm)
          list[`${tm.SummarizationItemId}`] = tm
          // console.log(tm.SecurityTicker, DefaultTickerData)
        })
        
        let index = [];
        for(let LKey in list) {
          let newTMIndexObj = {
            SummarizationItemId: LKey,
            CreatedDate: list[LKey].CreatedDate,
            Mentions: list[LKey].Mentions,
            MentionsChange: list[LKey].MentionsChange,
            Reach: list[LKey].Reach,
            ReachChange: list[LKey].ReachChange,
            Positive: list[LKey].Positive,
            PositiveChange: list[LKey].PositiveChange,
            Negative: list[LKey].Negative,
            NegativeChange: list[LKey].NegativeChange,
            Price: list[LKey].SecurityPrice,
            PriceChange: list[LKey].Change,
            Volume: list[LKey].Volume
          }
          index.push(newTMIndexObj)
        }
        // sortedTMIndex = index.sort((a,b) => {
        //   return moment(b.CreatedDate) - moment(a.CreatedDate)
        // });
       
        let SortingColumn = TrendingMentionsHelper.getSortedColumnKey(action.SortingColumn, action.packageName);
        // console.log(SortingColumn, index)
        sortedTMIndex = TrendingMentionsHelper.TMSort(index, SortingColumn, action.SortingType);
        
      }
      // window.localStorage.removeItem('SavedCoinDetail')
      // console.log(DefaultTickerData, coinColumnShow)
      let { coin_column_data } = state;
      coin_column_data[action.index].coinsDump = JSON.parse(JSON.stringify(action.coinsDump))
      coin_column_data[action.index].tmList = JSON.parse(JSON.stringify(list))
      coin_column_data[action.index].tmIndex = JSON.parse(JSON.stringify(sortedTMIndex))
      return {
        ...state,
        coinsDump: action.coinsDump,
        tmList: list,
        tmIndex: sortedTMIndex,
        coin_column_data
      }
    }
    if(action.type === 'COIN_DETAIL_COLUMN_SET') {
      let DefaultTickerData = action.payload;
      const { Mentions, MentionsChange, Reach, ReachChange, Positive, PositiveChange, Negative, NegativeChange, Virality, SecurityPrice, Change } = TrendingMentionsHelper.TMExtract(DefaultTickerData)
      DefaultTickerData[`Mentions`] = Mentions;
      DefaultTickerData[`MentionsChange`] = MentionsChange;
      DefaultTickerData[`MentionsTrend`] = 0;
      DefaultTickerData[`Reach`] = Reach;
      DefaultTickerData[`ReachChange`] = ReachChange;
      DefaultTickerData[`ReachTrend`] = 0
      DefaultTickerData[`Positive`] = Positive;
      DefaultTickerData[`PositiveChange`] = PositiveChange;
      DefaultTickerData[`PositiveTrend`] = 0;
      DefaultTickerData[`Negative`] = Negative;
      DefaultTickerData[`NegativeChange`] = NegativeChange;
      DefaultTickerData[`NegativeTrend`] = 0;
      DefaultTickerData[`Virality`] = Virality;
      DefaultTickerData[`SecurityPrice`] = SecurityPrice;
      DefaultTickerData[`SecurityPriceTrend`] = 0;
      DefaultTickerData[`Change`] = Change;
      DefaultTickerData[`ChangeTrend`] = 0;
      // console.log(DefaultTickerData)
      // let coindetail = window.localStorage.getItem('SavedCoinDetail');
      let coinColumnShow = window.localStorage.getItem('CoinDetailColumnShowState')
      
      // if(coindetail !== null) {
      //   DefaultTickerData = JSON.parse(coindetail)
      // }
      if(coinColumnShow !== null) {
        coinColumnShow = coinColumnShow === 'false' ? false : true;
      }
      return {
        ...state,
        tmDetails : {
          ...state.tmDetails,
          TickerData: DefaultTickerData,
          TickerViewDetails: coinColumnShow !== null && coinColumnShow ? true : false
        }
      }
    }
    if(action.type === 'TM_SET') {
      
      let list = {};
      
      let sortedTMIndex = [];
      
      // console.log(coindetail, coinColumnShow)
      if(action.payload.length) {
        let TMs = action.payload;
        TMs.forEach(tm => {
          const { Mentions, MentionsChange, Reach, ReachChange, Positive, PositiveChange, Negative, NegativeChange, Virality, SecurityPrice, Change, Volume } = TrendingMentionsHelper.TMExtract(tm)
          tm[`Mentions`] = Mentions;
          tm[`MentionsChange`] = MentionsChange;
          tm[`MentionsTrend`] = 0;
          tm[`Reach`] = Reach;
          tm[`ReachChange`] = ReachChange;
          tm[`ReachTrend`] = 0
          tm[`Positive`] = Positive;
          tm[`PositiveChange`] = PositiveChange;
          tm[`PositiveTrend`] = 0;
          tm[`Negative`] = Negative;
          tm[`NegativeChange`] = NegativeChange;
          tm[`NegativeTrend`] = 0;
          tm[`Virality`] = Virality;
          tm[`SecurityPrice`] = SecurityPrice;
          tm[`SecurityPriceTrend`] = 0;
          tm[`Change`] = Change;
          tm[`ChangeTrend`] = 0;
          tm[`Volume`] = Volume;
          // console.log(tm)
          list[`${tm.SummarizationItemId}`] = tm
          // console.log(tm.SecurityTicker, DefaultTickerData)
          
        })
        
        let index = [];
        for(let LKey in list) {
          let newTMIndexObj = {
            SummarizationItemId: LKey,
            CreatedDate: list[LKey].CreatedDate,
            Mentions: list[LKey].Mentions,
            MentionsChange: list[LKey].MentionsChange,
            Reach: list[LKey].Reach,
            ReachChange: list[LKey].ReachChange,
            Positive: list[LKey].Positive,
            PositiveChange: list[LKey].PositiveChange,
            Negative: list[LKey].Negative,
            NegativeChange: list[LKey].NegativeChange,
            Price: list[LKey].SecurityPrice,
            PriceChange: list[LKey].Change,
            Volume: list[LKey].Volume
          }
          index.push(newTMIndexObj)
        }
        
       
        let SortingColumn = TrendingMentionsHelper.getSortedColumnKey(action.SortingColumn, action.packageName);
        
        sortedTMIndex = TrendingMentionsHelper.TMSort(index, SortingColumn, action.SortingType);
        
      }
      
      let { coin_column_data } = state;
      coin_column_data[action.index].coinsDump = JSON.parse(JSON.stringify(action.coinsDump))
      coin_column_data[action.index].tmList = JSON.parse(JSON.stringify(list))
      coin_column_data[action.index].tmIndex = JSON.parse(JSON.stringify(sortedTMIndex))
      return {
        ...state,
        // coinsDump: action.coinsDump,
        // tmList: list,
        // tmIndex: sortedTMIndex,
        coin_column_data
      }
    }
    if(action.type === 'RESET_TM') {
      let { coin_column_data } = state;
      coin_column_data[action.payload].coinsDump = []
      coin_column_data[action.payload].tmList = {}
      coin_column_data[action.payload].tmIndex = []
      return {
        ...state,
        coinsDump: [],
        tmList: {},
        tmIndex: [],
        coin_column_data
      }
    }
    if(action.type === 'TM_AUTOCALL') {
      let { tm_autocall } = state;
      let val = tm_autocall[action.payload]
      tm_autocall[action.payload] = !val
      return {
        ...state,
        tm_autocall : JSON.parse(JSON.stringify(tm_autocall)),
      }
    }
    if(action.type === 'CHANGE_COL_TAB_TM') {
      let { TabColumnChangeRequest } = state;
      TabColumnChangeRequest[action.index] = action.payload
      return {
        ...state,
        TabColumnChangeRequest: JSON.parse(JSON.stringify(TabColumnChangeRequest))
      }
    }

    if(action.type === 'TM_UPDATE') {
      // console.log('in tm reducer')
      let list = {};
      let sortedTMIndex = [];
      if(action.payload.length) {
        let TMs = action.payload;
        TMs.forEach(tm => {
          const { Mentions, MentionsChange, Reach, ReachChange, Positive, PositiveChange, Negative, NegativeChange, Virality, SecurityPrice, Change, Volume } = TrendingMentionsHelper.TMExtract(tm)
          tm[`Mentions`] = Mentions;
          tm[`MentionsChange`] = MentionsChange;
          tm[`MentionsTrend`] = 0;
          tm[`Reach`] = Reach;
          tm[`ReachChange`] = ReachChange;
          tm[`ReachTrend`] = 0
          tm[`Positive`] = Positive;
          tm[`PositiveChange`] = PositiveChange;
          tm[`PositiveTrend`] = 0;
          tm[`Negative`] = Negative;
          tm[`NegativeChange`] = NegativeChange;
          tm[`NegativeTrend`] = 0;
          tm[`Virality`] = Virality;
          tm[`SecurityPrice`] = SecurityPrice;
          tm[`SecurityPriceTrend`] = 0;
          tm[`Change`] = Change;
          tm[`ChangeTrend`] = 0;
          tm[`Volume`] = Volume;
          // console.log(tm)
          list[`${tm.SummarizationItemId}`] = tm
          // console.log(tm.SecurityTicker, DefaultTickerData)
          
        })
        
        let index = [];
        for(let LKey in list) {
          let newTMIndexObj = {
            SummarizationItemId: LKey,
            CreatedDate: list[LKey].CreatedDate,
            Mentions: list[LKey].Mentions,
            MentionsChange: list[LKey].MentionsChange,
            Reach: list[LKey].Reach,
            ReachChange: list[LKey].ReachChange,
            Positive: list[LKey].Positive,
            PositiveChange: list[LKey].PositiveChange,
            Negative: list[LKey].Negative,
            NegativeChange: list[LKey].NegativeChange,
            Price: list[LKey].SecurityPrice,
            PriceChange: list[LKey].Change,
            Volume: list[LKey].Volume
          }
          index.push(newTMIndexObj)
        }
        // sortedTMIndex = index.sort((a,b) => {
        //   return moment(b.CreatedDate) - moment(a.CreatedDate)
        // });
       
        let SortingColumn = TrendingMentionsHelper.getSortedColumnKey(action.SortingColumn, action.packageName);
        // console.log(SortingColumn, index)
        sortedTMIndex = TrendingMentionsHelper.TMSort(index, SortingColumn, action.SortingType);
        
      }
      // window.localStorage.removeItem('SavedCoinDetail')
      // console.log(DefaultTickerData, coinColumnShow)

      let { coin_column_data } = state;
      coin_column_data[action.index].coinsDump = JSON.parse(JSON.stringify(action.coinsDump))
      coin_column_data[action.index].tmList = JSON.parse(JSON.stringify(list))
      coin_column_data[action.index].tmIndex = JSON.parse(JSON.stringify(sortedTMIndex))
      return {
        ...state,
        coinsDump: action.coinsDump,
        tmList: list,
        tmIndex: sortedTMIndex,
        coin_column_data
      }
    }

    if(action.type === 'SET_TMREQDATA') {
      let reqDataArr = state.cc_tmReqData;
      reqDataArr[action.index] = action.payload;
      return {
        ...state,
        tmReqData : action.payload,
        cc_tmReqData: JSON.parse(JSON.stringify(reqDataArr))
      }
    }

    
    if(action.type === 'UPDATE_TM_AUTOREFRESH') {
      return {
        ...state,
        autoRefresh: action.payload
      }
    }

    if(action.type === 'SET_TMFRAME') {
      return {
        ...state,
        tmFrame: JSON.parse(JSON.stringify(action.payload))
      }
    }
    if(action.type === 'SET_ACTIVETAB') {
      return {
        ...state,
        activeTab: action.payload
      }
    }
    if(action.type === 'SET_ACTIVECOLUMNS') {
      // console.log(action.payload)
      return {
        ...state,
        tmSortedColumn: action.payload.sortedColumn,
        tmSortingType: action.payload.sortingType,
      }
    }
    if(action.type === 'TM_SETPACKAGE') {
      return {
        ...state,
        packageName: action.payload
      }
    }

    if(action.type === 'SET_TMSORT') {
      let { sortedColumn, sortingType, FilteredList, reversedCoinsDump } = action.payload;
      let TMs = FilteredList;
      let list = {};
      TMs.forEach(tm => {
        const { Mentions, MentionsChange, Reach, ReachChange, Positive, PositiveChange, Negative, NegativeChange, Virality, SecurityPrice, Change, Volume } = TrendingMentionsHelper.TMExtract(tm)
        tm[`Mentions`] = Mentions;
        tm[`MentionsChange`] = MentionsChange;
        tm[`MentionsTrend`] = 0;
        tm[`Reach`] = Reach;
        tm[`ReachChange`] = ReachChange;
        tm[`ReachTrend`] = 0
        tm[`Positive`] = Positive;
        tm[`PositiveChange`] = PositiveChange;
        tm[`PositiveTrend`] = 0;
        tm[`Negative`] = Negative;
        tm[`NegativeChange`] = NegativeChange;
        tm[`NegativeTrend`] = 0;
        tm[`Virality`] = Virality;
        tm[`SecurityPrice`] = SecurityPrice;
        tm[`SecurityPriceTrend`] = 0;
        tm[`Change`] = Change;
        tm[`ChangeTrend`] = 0;
        tm[`Volume`] = Volume;
        list[`${tm.SummarizationItemId}`] = tm
      })
      let tmIndex = [];
      for(let LKey in list) {
        let newTMIndexObj = {
          SummarizationItemId: LKey,
          CreatedDate: list[LKey].CreatedDate,
          Mentions: list[LKey].Mentions,
          MentionsChange: list[LKey].MentionsChange,
          Reach: list[LKey].Reach,
          ReachChange: list[LKey].ReachChange,
          Positive: list[LKey].Positive,
          PositiveChange: list[LKey].PositiveChange,
          Negative: list[LKey].Negative,
          NegativeChange: list[LKey].NegativeChange,
          Price: list[LKey].SecurityPrice,
          PriceChange: list[LKey].Change,
          Volume: list[LKey].Volume
        }
        tmIndex.push(newTMIndexObj)
      }

      // let tmIndex = JSON.parse(JSON.stringify(state.tmIndex));
      let sortedTMIndex;
      if(sortedColumn === 'Mentions Score') {
        sortedTMIndex = TrendingMentionsHelper.TMSort(tmIndex, 'Mentions', sortingType);
      } else if(sortedColumn === 'Reach Score') {
        sortedTMIndex = TrendingMentionsHelper.TMSort(tmIndex, 'Reach', sortingType);
      } else if(sortedColumn === 'Mentions Change') {
        sortedTMIndex = TrendingMentionsHelper.TMSort(tmIndex, 'MentionsChange', sortingType);
      } else if(sortedColumn === 'Reach Change') {
        sortedTMIndex = TrendingMentionsHelper.TMSort(tmIndex, 'ReachChange', sortingType);
      } /*else if(sortedColumn === 'Price') {
        sortedTMIndex = TrendingMentionsHelper.TMSort(tmIndex, 'Price', sortingType);
      } else if(sortedColumn === 'Volume') {
        sortedTMIndex = TrendingMentionsHelper.TMSort(tmIndex, 'Volume', sortingType);
      }*/

      return {
        ...state,
        coinsDump: reversedCoinsDump,
        tmSorted: true,
        tmSortedColumn: sortedColumn,
        tmSortingType: sortingType,
        tmList: list,
        tmIndex: JSON.parse(JSON.stringify(sortedTMIndex)),
      }
    }

    if(action.type === 'RESET_TMSORT') {
      return {
        ...state,
        tmSorted: false,
        tmSortedTab: '',
        // tmSortedColumn: '',
        // tmSortingType: 0,
        tmSortedIndex: [],
      }
    }

    if(action.type === 'EVENT_DETAILS') {
      // console.log(action.payload)
      return {
        ...state,
        eventDetails:action.payload
      }
    }

    if(action.type === 'VIEW_TM_DETAILS') {
      // console.log(action.payload)
      if(action.payload.TM.SecurityName !== null) {
        let tm = action.payload.TM
        const { Mentions, MentionsChange, Reach, ReachChange, Positive, PositiveChange, Negative, NegativeChange, Virality, SecurityPrice, Change } = TrendingMentionsHelper.TMExtract(tm)
        tm[`Mentions`] = Mentions;
        tm[`MentionsChange`] = MentionsChange;
        tm[`MentionsTrend`] = 0;
        tm[`Reach`] = Reach;
        tm[`ReachChange`] = ReachChange;
        tm[`ReachTrend`] = 0
        tm[`Positive`] = Positive;
        tm[`PositiveChange`] = PositiveChange;
        tm[`PositiveTrend`] = 0;
        tm[`Negative`] = Negative;
        tm[`NegativeChange`] = NegativeChange;
        tm[`NegativeTrend`] = 0;
        tm[`Virality`] = Virality;
        tm[`SecurityPrice`] = SecurityPrice;
        tm[`SecurityPriceTrend`] = 0;
        tm[`Change`] = Change;
        tm[`ChangeTrend`] = 0;
        window.localStorage.setItem('SavedCoinDetail', JSON.stringify(tm))
        window.localStorage.setItem('CoinDetailColumnShowState', action.payload.ViewDetailsState);
        return {
          ...state,
          tmDetails : {
            ...state.tmDetails,
            TickerViewDetails: action.payload.ViewDetailsState,
            TickerData: tm,
            TickerEventList: {},
            TickerEventIndex: [],
            TickerSearchData: {}
          }
        }
      }
      
    }
    if(action.type === 'VIEW_TMTICKER_DETAILS_UPDATE') {
      // console.log(action.payload)
      let tm = action.payload.TM
      const { Mentions, MentionsChange, Reach, ReachChange, Positive, PositiveChange, Negative, NegativeChange, Virality, SecurityPrice, Change } = TrendingMentionsHelper.TMExtract(tm)
      tm[`Mentions`] = Mentions;
      tm[`MentionsChange`] = MentionsChange;
      tm[`MentionsTrend`] = 0;
      tm[`Reach`] = Reach;
      tm[`ReachChange`] = ReachChange;
      tm[`ReachTrend`] = 0
      tm[`Positive`] = Positive;
      tm[`PositiveChange`] = PositiveChange;
      tm[`PositiveTrend`] = 0;
      tm[`Negative`] = Negative;
      tm[`NegativeChange`] = NegativeChange;
      tm[`NegativeTrend`] = 0;
      tm[`Virality`] = Virality;
      tm[`SecurityPrice`] = SecurityPrice;
      tm[`SecurityPriceTrend`] = 0;
      tm[`Change`] = Change;
      tm[`ChangeTrend`] = 0;
      window.localStorage.setItem('SavedCoinDetail', JSON.stringify(tm));
      return {
        ...state,
        tmDetails : {
          ...state.tmDetails,
          TickerData: tm
        }
      }
    }
    if(action.type === 'SET_TICKERDETAILS_FEEDREQDATA') {
      // console.log(action.payload)
      
      return {
        ...state,
        tmDetails : {
          ...state.tmDetails,
          TickerDetailsFeedReqData: action.payload
        }
      }
    }
    if(action.type === 'SET_TICKERDETAILS_FEEDREQDATA_COINCOLUMN') {
      // console.log(action.payload)
      // let tmdataStore = JSON.parse(JSON.stringify(state.tmDetailPreferencesDataStore))
      // tmdataStore[action.SecurityName].TickerDetailsFeedReqData = action.payload
      return {
        ...state,
        tmDetailPreferencesDataStore: {
          ...state.tmDetailPreferencesDataStore,
          [`${action.SecurityName}${action.sourceFilter}`]: {
            ...state.tmDetailPreferencesDataStore[`${action.SecurityName}${action.sourceFilter}`],
            TickerDetailsFeedReqData : action.payload
          }
        }
      }
    }
    if(action.type === 'RESET_TICKER_EVENTS') {
      return {
        ...state,
        tmDetails : {
          ...state.tmDetails,
          TickerEventList: {},
          TickerEventIndex: [],
          TickerSearchData: {}
        }
      }
    }
    if(action.type === 'RE_SEARCH_TICKER_EVENTS') {
      return {
        ...state,
        tmDetails : {
          ...state.tmDetails,
          TickerResearch: action.payload
        }
      }
    }
    if(action.type === 'CLOSE_TM_DETAILS') {
      let ViewDetailsState = state.tmDetails.TickerViewDetails;
      // console.log(ViewDetailsState)
      if(ViewDetailsState) {
       
        window.localStorage.setItem('CoinDetailColumnShowState', false);
        // console.log(window.localStorage.getItem('CoinDetailColumnShowState'))
        return {
          ...state,
          tmDetails : {
            ...state.tmDetails,
            TickerViewDetails: false,
            TickerData: {},
            TickerEventList: {},
            TickerEventIndex: [],
            TickerSearchData: {}
          }
        }
      }
      
    }
    if(action.type === 'CLOSE_COIN_DETAILS_COLUMN') {
      let tmdataStore = JSON.parse(JSON.stringify(state.tmDetailPreferencesDataStore))
      // tmdataStore.splice(action.payload, 1);
      delete tmdataStore[action.SecurityName]
      return {
        ...state,
        tmDetailPreferencesDataStore: tmdataStore
      }
      
    }
    if(action.type === 'UPDATE_PINNED_COINCOLUMN') {
      
      return {
        ...state,
        pinnedcoincolumn: state.pinnedcoincolumn + 1
      }
      
    }
    if(action.type === 'UPDATE_PINNED_COINDETAILCOLUMN') {
      
      return {
        ...state,
        pinnedcoindetailcolumn: state.pinnedcoindetailcolumn + 1
      }
      
    }
    if(action.type === 'SET_TICKER_EVENTS_COINCOLUMN') {
      // const maxCount = state.tweetListMaxCount;
      // let limitedStoriesList = {}
      //   action.payload.TickerFeedsIndexedRef.map(key => {
      //     limitedStoriesList[`${key.FeedId}`] = action.payload.TickerFeedsList[`${key.FeedId}`]
      //     return null;
      //   })
        let tmdataStore = JSON.parse(JSON.stringify(state.tmDetailPreferencesDataStore))
        let srcfilter = action.payload.searchData.sourceFilters ? action.payload.searchData.sourceFilters : ''
        // console.log(tmdataStore, action.payload, srcfilter)
       
        // tmdataStore[action.payload.index].TickerEventList = action.payload.TickerFeedsList
        // tmdataStore[action.payload.index].TickerEventIndex = JSON.parse(JSON.stringify(action.payload.TickerFeedsIndexedRef))
        // tmdataStore[action.payload.index].TickerEventIndexUpdated = tmdataStore[action.payload.index].TickerEventIndexUpdated +1
        // tmdataStore[action.payload.index].TickerSearchData = action.payload.searchData
        // tmdataStore[action.payload.index].TickerResearch = false
        return {
          ...state,
          tmDetailPreferencesDataStore: {
            ...state.tmDetailPreferencesDataStore,
            [`${action.payload.SecurityName}${srcfilter}`]: {
              ...state.tmDetailPreferencesDataStore[`${action.payload.SecurityName}${srcfilter}`],
              TickerEventList : action.payload.TickerFeedsList,
              TickerEventIndex : JSON.parse(JSON.stringify(action.payload.TickerFeedsIndexedRef)),
              TickerEventIndexUpdated : tmdataStore[`${action.payload.SecurityName}${srcfilter}`].TickerEventIndexUpdated +1,
              TickerSearchData : action.payload.searchData,
              TickerResearch : false
            }
          }
        }
      // return {
      //   ...state,
      //   tmDetails : {
      //     ...state.tmDetails,
      //     TickerEventList: action.payload.TickerFeedsList,
      //     TickerEventIndex: JSON.parse(JSON.stringify(action.payload.TickerFeedsIndexedRef)),
      //     TickerSearchData: action.payload.searchData,
      //     TickerResearch: false
      //   }
      // }
    }
    if(action.type === 'SET_TICKER_EVENTS') {
      // const maxCount = state.tweetListMaxCount;
      let limitedStoriesList = {}
        action.payload.TickerFeedsIndexedRef.map(key => {
          limitedStoriesList[`${key.FeedId}`] = action.payload.TickerFeedsList[`${key.FeedId}`]
          return null;
        })
      return {
        ...state,
        tmDetails : {
          ...state.tmDetails,
          TickerEventList: action.payload.TickerFeedsList,
          TickerEventIndex: JSON.parse(JSON.stringify(action.payload.TickerFeedsIndexedRef)),
          TickerSearchData: action.payload.searchData,
          TickerResearch: false
        }
      }
    }

    if(action.type === 'UPDATE_COINCOLUMNEVENT_LIKES') {
      if(action.payload.SecurityName !== null) {
        // console.log(action.payload.SecurityName, state.tmDetailPreferencesDataStore)
        let TickerEvents = JSON.parse(JSON.stringify(state.tmDetailPreferencesDataStore[action.payload.SecurityName].TickerEventList))
        // console.log(TickerEvents[action.payload.EventID])
        if(TickerEvents[action.payload.EventID]) {
          let Story = TickerEvents[action.payload.EventID]
          Story.NumberOfLikes = action.payload.Like === 1 ? parseInt(Story.NumberOfLikes)+1 : parseInt(Story.NumberOfLikes)-1
          return {
            ...state,
            tmDetailPreferencesDataStore: {
              ...state.tmDetailPreferencesDataStore,
              [action.payload.SecurityName]: {
                ...state.tmDetailPreferencesDataStore[action.payload.SecurityName],
                TickerEventList : TickerEvents
              }
            }
          }
        }
      }
      // let tickerEvents = state.tmDetails.TickerEventList;
      // if(tickerEvents[action.payload.EventID]) {
      //   let Story = tickerEvents[action.payload.EventID]
      //   Story.NumberOfLikes = action.payload.Like === 1 ? parseInt(Story.NumberOfLikes)+1 : parseInt(Story.NumberOfLikes)-1
      //   return {
      //     ...state,
      //     tmDetails : {
      //       ...state.tmDetails,
      //       TickerEventList: {
      //         ...state.tmDetails.TickerEventList,
      //         [action.payload.EventID] : JSON.parse(JSON.stringify(Story))
      //       }
      //     }
      //   }
      // }
    }

    if(action.type === 'UPDATE_TICKER_EVENTS_COINCOLUMN') {
      let srcfilter = action.payload.searchData.sourceFilter ? action.payload.searchData.sourceFilter : ''
      let TickerEvents = JSON.parse(JSON.stringify(state.tmDetailPreferencesDataStore[`${action.payload.SecurityName}${srcfilter}`].TickerEventList))
      // let TickerEvents = state.tmDetails.TickerEventList;
      // let TickerEventsIndex = state.tmDetails.TickerEventIndex;
      let TickerEventsIndex = JSON.parse(JSON.stringify(state.tmDetailPreferencesDataStore[`${action.payload.SecurityName}${srcfilter}`].TickerEventIndex))
      let tmdataStore = JSON.parse(JSON.stringify(state.tmDetailPreferencesDataStore))
      // console.log(tmdataStore, action.payload.index)
      const tweetListMaxCount = state.tweetListMaxCount;
      if(Object.keys(action.payload.TickerFeedsList).length) {
        let incomingFeeds = action.payload.TickerFeedsList;
        let RootFeedIdArr = Object.keys(TickerEvents);
        let incomingFeedIdArr = Object.keys(incomingFeeds);
        let RootFeedHeadlines = RootFeedIdArr.map(it => (TickerEvents[it].HeadlineText && TickerEvents[it].HeadlineText.trim()))
        // let incomingFeedHeadlines = incomingFeedIdArr.map(it => (incomingFeeds[it].HeadlineText.trim()))
        RootFeedIdArr.forEach(eachFeedId => {
          let eachFeed = TickerEvents[eachFeedId];
          
          
  
          if(eachFeed) {
            
            if(incomingFeedIdArr.indexOf(eachFeedId) > -1) {

              let inc = incomingFeeds[eachFeedId]
              TickerEvents[eachFeedId] = JSON.parse(JSON.stringify(inc));
              incomingFeedIdArr.splice(incomingFeedIdArr.indexOf(eachFeedId), 1);
              
            }
          }
        })
        let filtInc = incomingFeedIdArr.filter(inFdid => { return (RootFeedIdArr.indexOf(inFdid) === -1) })
        filtInc.forEach((item, i) => {
          let incNewFeed = incomingFeeds[item];
          if(RootFeedHeadlines.indexOf(incNewFeed.HeadlineText) > -1) {
            let existingHeadLineFeed = TickerEvents[RootFeedIdArr[RootFeedHeadlines.indexOf(incNewFeed.HeadlineText)]];
            let existingHeadlineFeedIndex = '';
            TickerEventsIndex.forEach((ns, indx) => {
              if(ns.FeedId === existingHeadLineFeed.FeedId) {
                existingHeadlineFeedIndex = indx;
              }
            })
            TickerEvents = Object.assign({[`${item}`]: incomingFeeds[item]}, TickerEvents)
            TickerEventsIndex[existingHeadlineFeedIndex] = {
              FeedId : item,
              SortByDate : incomingFeeds[item].SortByDate,
              notifyEvent : incomingFeeds[item].NotifyEvent,
              magnitudeRate : incomingFeeds[item].MagnitudeRate
            }
            let changedTickerEvents = {}
            TickerEventsIndex.forEach((ns) => {
              changedTickerEvents[`${ns.FeedId}`] = TickerEvents[`${ns.FeedId}`]
            })
            TickerEvents = {};
            TickerEvents = changedTickerEvents;
          } else {
            let nowTime = moment();
            let thenTime = moment(incomingFeeds[item].FeedTime);
            let days = moment(nowTime,'DD-MM-YYYYThh:mm:ss').diff(thenTime, 'days');
            if(days < 2){
              TickerEventsIndex.unshift({
                FeedId : item,
                SortByDate : incomingFeeds[item].SortByDate,
                notifyEvent : incomingFeeds[item].NotifyEvent,
                magnitudeRate : incomingFeeds[item].MagnitudeRate
              })
              TickerEvents = Object.assign({[`${item}`]: incomingFeeds[item]}, TickerEvents)
  
              TickerEventsIndex.length = TickerEventsIndex.length > tweetListMaxCount ? tweetListMaxCount : TickerEventsIndex.length;
              
              let limitedNewStories = {}
              if(TickerEventsIndex.length) {
                TickerEventsIndex.map(stindx => {
                  limitedNewStories[`${stindx.FeedId}`] = TickerEvents[stindx.FeedId];
                  return null;
                })
              }
              TickerEvents = {};
              TickerEvents = limitedNewStories;
              
            }
          }
        })
        let sortedNewStoriesIndex = TickerEventsIndex.sort((a,b) => {
          return moment(b.SortByDate) - moment(a.SortByDate)
        });
        // tmdataStore[action.payload.index].TickerEventList = TickerEvents
        // tmdataStore[action.payload.index].TickerEventIndex = JSON.parse(JSON.stringify(sortedNewStoriesIndex))
        // tmdataStore[action.payload.index].TickerEventIndexUpdated = tmdataStore[action.payload.index].TickerEventIndexUpdated +1
        console.log(tmdataStore)
        return {
          ...state,
          tmDetailPreferencesDataStore: {
            ...state.tmDetailPreferencesDataStore,
            [`${action.payload.SecurityName}${srcfilter}`]: {
              ...state.tmDetailPreferencesDataStore[`${action.payload.SecurityName}${srcfilter}`],
              TickerEventList : TickerEvents,
              TickerEventIndex : JSON.parse(JSON.stringify(sortedNewStoriesIndex)),
              TickerEventIndexUpdated : tmdataStore[`${action.payload.SecurityName}${srcfilter}`].TickerEventIndexUpdated +1
            }
          }
        }
        // tmdataStore[action.index].TickerSearchData = action.payload.searchData
        // tmdataStore[action.index].TickerResearch = false
        // return {
        //   ...state,
        //   tmDetails : {
        //     ...state.tmDetails,
        //     TickerEventList: TickerEvents,
        //     TickerEventIndex: JSON.parse(JSON.stringify(sortedNewStoriesIndex))
        //   }
        // }
        
      } else {
        // tmdataStore[action.payload.index].TickerEventList = TickerEvents
        // tmdataStore[action.payload.index].TickerEventIndex = JSON.parse(JSON.stringify(TickerEventsIndex))
        // tmdataStore[action.payload.index].TickerEventIndexUpdated = tmdataStore[action.payload.index].TickerEventIndexUpdated +1
        // console.log(tmdataStore)
        return {
          ...state,
          tmDetailPreferencesDataStore: {
            ...state.tmDetailPreferencesDataStore,
            [`${action.payload.SecurityName}${srcfilter}`]: {
              ...state.tmDetailPreferencesDataStore[`${action.payload.SecurityName}${srcfilter}`],
              TickerEventList : TickerEvents,
              TickerEventIndex : JSON.parse(JSON.stringify(TickerEventsIndex)),
              TickerEventIndexUpdated : tmdataStore[`${action.payload.SecurityName}${srcfilter}`].TickerEventIndexUpdated +1
            }
          }
        }
        // return {
        //   ...state,
        //   tmDetails : {
        //     ...state.tmDetails,
        //     TickerEventList: TickerEvents,
        //     TickerEventIndex: JSON.parse(JSON.stringify(TickerEventsIndex))
        //   }
        // }
      }
    }
    if(action.type === 'UPDATE_TICKER_EVENTS') {
      let TickerEvents = state.tmDetails.TickerEventList;
      let TickerEventsIndex = state.tmDetails.TickerEventIndex;
      const tweetListMaxCount = state.tweetListMaxCount;
      if(Object.keys(action.payload.TickerFeedsList).length) {
        let incomingFeeds = action.payload.TickerFeedsList;
        let RootFeedIdArr = Object.keys(TickerEvents);
        let incomingFeedIdArr = Object.keys(incomingFeeds);
        let RootFeedHeadlines = RootFeedIdArr.map(it => (TickerEvents[it].HeadlineText && TickerEvents[it].HeadlineText.trim()))
        // let incomingFeedHeadlines = incomingFeedIdArr.map(it => (incomingFeeds[it].HeadlineText.trim()))
        RootFeedIdArr.forEach(eachFeedId => {
          let eachFeed = TickerEvents[eachFeedId];
          
          
  
          if(eachFeed) {
            
            if(incomingFeedIdArr.indexOf(eachFeedId) > -1) {

              let inc = incomingFeeds[eachFeedId]
              TickerEvents[eachFeedId] = JSON.parse(JSON.stringify(inc));
              incomingFeedIdArr.splice(incomingFeedIdArr.indexOf(eachFeedId), 1);
              
            }
          }
        })
        let filtInc = incomingFeedIdArr.filter(inFdid => { return (RootFeedIdArr.indexOf(inFdid) === -1) })
        filtInc.forEach((item, i) => {
          let incNewFeed = incomingFeeds[item];
          if(RootFeedHeadlines.indexOf(incNewFeed.HeadlineText) > -1) {
            let existingHeadLineFeed = TickerEvents[RootFeedIdArr[RootFeedHeadlines.indexOf(incNewFeed.HeadlineText)]];
            let existingHeadlineFeedIndex = '';
            TickerEventsIndex.forEach((ns, indx) => {
              if(ns.FeedId === existingHeadLineFeed.FeedId) {
                existingHeadlineFeedIndex = indx;
              }
            })
            TickerEvents = Object.assign({[`${item}`]: incomingFeeds[item]}, TickerEvents)
            TickerEventsIndex[existingHeadlineFeedIndex] = {
              FeedId : item,
              SortByDate : incomingFeeds[item].SortByDate,
              notifyEvent : incomingFeeds[item].NotifyEvent,
              magnitudeRate : incomingFeeds[item].MagnitudeRate
            }
            let changedTickerEvents = {}
            TickerEventsIndex.forEach((ns) => {
              changedTickerEvents[`${ns.FeedId}`] = TickerEvents[`${ns.FeedId}`]
            })
            TickerEvents = {};
            TickerEvents = changedTickerEvents;
          } else {
            let nowTime = moment();
            let thenTime = moment(incomingFeeds[item].FeedTime);
            let days = moment(nowTime,'DD-MM-YYYYThh:mm:ss').diff(thenTime, 'days');
            if(days < 2){
              TickerEventsIndex.unshift({
                FeedId : item,
                SortByDate : incomingFeeds[item].SortByDate,
                notifyEvent : incomingFeeds[item].NotifyEvent,
                magnitudeRate : incomingFeeds[item].MagnitudeRate
              })
              TickerEvents = Object.assign({[`${item}`]: incomingFeeds[item]}, TickerEvents)
  
              TickerEventsIndex.length = TickerEventsIndex.length > tweetListMaxCount ? tweetListMaxCount : TickerEventsIndex.length;
              
              let limitedNewStories = {}
              if(TickerEventsIndex.length) {
                TickerEventsIndex.map(stindx => {
                  limitedNewStories[`${stindx.FeedId}`] = TickerEvents[stindx.FeedId];
                  return null;
                })
              }
              TickerEvents = {};
              TickerEvents = limitedNewStories;
              
            }
          }
        })
        let sortedNewStoriesIndex = TickerEventsIndex.sort((a,b) => {
          return moment(b.SortByDate) - moment(a.SortByDate)
        });
        return {
          ...state,
          tmDetails : {
            ...state.tmDetails,
            TickerEventList: TickerEvents,
            TickerEventIndex: JSON.parse(JSON.stringify(sortedNewStoriesIndex))
          }
        }
        
      } else {
        return {
          ...state,
          tmDetails : {
            ...state.tmDetails,
            TickerEventList: TickerEvents,
            TickerEventIndex: JSON.parse(JSON.stringify(TickerEventsIndex))
          }
        }
      }
    }

    if(action.type === 'EXCH_RATE_REQ') {
      return {
        ...state,
        tmDetails : {
          ...state.tmDetails,
          ExchangesAndRatesLoading: action.payload
        }
      }
    }
    if(action.type === 'SET_EXCH_RATE') {
      return {
        ...state,
        tmDetails : {
          ...state.tmDetails,
          ExchangesAndRates: action.payload
        }
      }
    }
    if(action.type === 'CHANGE_TM_LIMIT') {
      let data = {
        tmListMaxCoinCount: action.payload.limit,
        tmtotalmaxcoincount: action.payload.maxLimit,
        tmFromWatchlist: typeof action.payload.watchlist !== 'undefined' ? action.payload.watchlist : state.tmFromWatchlist
      }
      return {
        ...state,
        ...data
      }
    }
    if(action.type === 'SET_COINCOLUMN_PREFERENCES') {
      // console.log(action.showtopreach, action.payload)
      let prefArr = [];
      if(typeof action.payload !== 'undefined' && action.payload !== '') {
        let prefs = action.payload.split(';').filter(itm => (itm));
        
        if(prefs.length) {
          prefs.forEach((itm, ln) => {
            let PrefData = {};
            let prefObjStr = itm.split(':')[1]
            let prefObjArr = prefObjStr.split(',');
            if(prefObjArr.length) {
              prefObjArr.forEach((objItm, yn) => {
                let objKeyVal = objItm.split('__')
                PrefData[`${objKeyVal[0]}`] = objKeyVal[1];
              })
            }
            // console.log(PrefData.activeTab, action.showtopreach)
            if(PrefData.activeTab === 'TopReach' && !action.showtopreach) {
              PrefData.activeTab = 'TopSentiment'
              let coltosort = PrefData.tmSortedColumn === 'Reach Score' ? 'score' : 'change'
              if(parseInt(PrefData.tmSortingType) === 1) {
                if(coltosort === 'change') {
                  PrefData.packageName = 'mentionschangepositive'
                  PrefData.tmSortedColumn = 'Sentiment Change'
                } else {
                  PrefData.packageName = 'mentionspositive';
                  PrefData.tmSortedColumn = 'Sentiment';
                }
              } else {
                if(coltosort === 'change') {
                  PrefData.packageName = 'mentionschangenegative';
                  PrefData.tmSortedColumn = 'Sentiment Change';
                } else {
                  PrefData.packageName = 'mentionsnegative';
                  PrefData.tmSortedColumn = 'Sentiment';
                }
              }
              
            }
            prefArr.push(PrefData)
          })
        }
      } 
      // console.log('Top Coins Prefs:-', prefArr)
      return {
        ...state,
        coin_column_preferences: JSON.parse(JSON.stringify(prefArr)),
        topcoinsinitialpreference: action.initialpreference ? action.initialpreference : state.topcoinsinitialpreference
      }
    }
    if(action.type === 'SET_COINDETAILCOLUMN_PREFERENCES') {
      // console.log(action.payload)
      let prefArr = [];
      let tmdataStore = JSON.parse(JSON.stringify(state.tmDetailPreferencesDataStore))
      if(typeof action.payload !== 'undefined' && action.payload !== '') {
        let prefs = action.payload.split(';').filter(itm => (itm));
       
        if(prefs.length) {
          prefs.forEach((itm, ln) => {
            // console.log(itm)
            let PrefData = {};
            let securityName = '';
            let prefObjStr = itm.split(':')[1]
            let prefObjArr = prefObjStr.split(',');
            if(prefObjArr.length) {
              prefObjArr.forEach((objItm, yn) => {
                let objKeyVal = objItm.split('__')
                PrefData[`${objKeyVal[0]}`] = objKeyVal[1];
                if(objKeyVal[0] === 'SecurityName') {
                  securityName = objKeyVal[1]
                }
              })
            }
            
            if(!PrefData.sourceFilter) {
              PrefData.sourceFilter = '';
            }
            // if(!PrefData.CDSearch_with_white) {

            // }
            // if(PrefData.SecurityName === 'Bitcoin') {
            //   console.log(PrefData)
            // }
            prefArr.push(PrefData)
            // if(typeof tmdataStore[securityName] === 'undefined') {
            //   tmdataStore[securityName] = {
            //     TickerData: {},
            //     TickerEventList: {},
            //     TickerEventIndex: [],
            //     TickerEventIndexUpdated: 0,
            //     TickerSearchData : {},
            //     TickerDetailsFeedReqData: {},
            //     TickerResearch: false,
            //     ChartFeeds: []
            //   }
            // }
            // console.log(tmdataStore[ln])
          })
          prefArr.forEach(prefData => {
            if(typeof tmdataStore[`${prefData.SecurityName}${prefData.sourceFilter}`] === 'undefined') {
              tmdataStore[`${prefData.SecurityName}${prefData.sourceFilter}`] = {
                TickerData: action.tickerdata !== false ? action.tickerdata : {},
                TickerEventList: {},
                TickerEventIndex: [],
                TickerEventIndexUpdated: 0,
                TickerSearchData : {},
                TickerDetailsFeedReqData: {},
                TickerResearch: false,
                ChartFeeds: []
              }
            }
          })
        }
      } 
      // console.log(prefArr)
      return {
        ...state,
        tmDetailPreferences: prefArr,
        tmDetailPreferencesDataStore: tmdataStore
        // topcoinsinitialpreference: action.initialpreference ? action.initialpreference : state.topcoinsinitialpreference
      }
    }

  
    if(action.type === 'COINDETAILCOLUMN_CHART_TICKERFEEDS') {
      // let tmdataStore = JSON.parse(JSON.stringify(state.tmDetailPreferencesDataStore))
      // tmdataStore[action.columnIndex].ChartFeeds = JSON.parse(JSON.stringify(action.payload))
      // tmdataStore[action.columnIndex].ChartFeeds = JSON.parse(JSON.stringify(tmdataStore[action.columnIndex].ChartFeeds))
      return {
        ...state,
        tmDetailPreferencesDataStore: {
          ...state.tmDetailPreferencesDataStore,
          [`${action.SecurityName}${action.SourceFilter}`]: {
            ...state.tmDetailPreferencesDataStore[`${action.SecurityName}${action.SourceFilter}`],
            ChartFeeds : JSON.parse(JSON.stringify(action.payload))
          }
        }
      }
    }
    if(action.type === 'COINDETAILCOLUMN_TICKERDATA') {
      let tm = action.payload
      const { Mentions, MentionsChange, Reach, ReachChange, Positive, PositiveChange, Negative, NegativeChange, Virality, SecurityPrice, Change } = TrendingMentionsHelper.TMExtract(tm)
      tm[`Mentions`] = Mentions;
      tm[`MentionsChange`] = MentionsChange;
      tm[`MentionsTrend`] = 0;
      tm[`Reach`] = Reach;
      tm[`ReachChange`] = ReachChange;
      tm[`ReachTrend`] = 0
      tm[`Positive`] = Positive;
      tm[`PositiveChange`] = PositiveChange;
      tm[`PositiveTrend`] = 0;
      tm[`Negative`] = Negative;
      tm[`NegativeChange`] = NegativeChange;
      tm[`NegativeTrend`] = 0;
      tm[`Virality`] = Virality;
      tm[`SecurityPrice`] = SecurityPrice;
      tm[`SecurityPriceTrend`] = 0;
      tm[`Change`] = Change;
      tm[`ChangeTrend`] = 0;
      
      // let tmdataStore = JSON.parse(JSON.stringify(state.tmDetailPreferencesDataStore))
      // console.log(tmdataStore)
      // let data = {
      //   TickerData: tm,
      //   TickerEventList: {},
      //   TickerEventIndex: [],
      //   TickerEventIndexUpdated: 0,
      //   TickerSearchData : {},
      //   TickerDetailsFeedReqData: {},
      //   TickerResearch: false,
      //   ChartFeeds: []
      // }
      // tmdataStore[action.securityname].TickerData = tm
    //  console.log(action.SecurityName, tm)
    // console.log(`${action.SecurityName}${action.SourceFilter}`)
      return {
        ...state,
        tmDetailPreferencesDataStore: {
          ...state.tmDetailPreferencesDataStore,
          [`${action.SecurityName}${action.SourceFilter}`]: {
            ...state.tmDetailPreferencesDataStore[`${action.SecurityName}${action.SourceFilter}`],
            TickerData : tm
          }
        }
      }
    }
    if(action.type === 'COINDETAILCOLUMN_TICKERDATA_UPDATE') {
      let tm = action.payload
      const { Mentions, MentionsChange, Reach, ReachChange, Positive, PositiveChange, Negative, NegativeChange, Virality, SecurityPrice, Change } = TrendingMentionsHelper.TMExtract(tm)
      tm[`Mentions`] = Mentions;
      tm[`MentionsChange`] = MentionsChange;
      tm[`MentionsTrend`] = 0;
      tm[`Reach`] = Reach;
      tm[`ReachChange`] = ReachChange;
      tm[`ReachTrend`] = 0
      tm[`Positive`] = Positive;
      tm[`PositiveChange`] = PositiveChange;
      tm[`PositiveTrend`] = 0;
      tm[`Negative`] = Negative;
      tm[`NegativeChange`] = NegativeChange;
      tm[`NegativeTrend`] = 0;
      tm[`Virality`] = Virality;
      tm[`SecurityPrice`] = SecurityPrice;
      tm[`SecurityPriceTrend`] = 0;
      tm[`Change`] = Change;
      tm[`ChangeTrend`] = 0;
      // console.log(action.payload)
      // let tmdataStore = JSON.parse(JSON.stringify(state.tmDetailPreferencesDataStore))
      
      // tmdataStore[action.securityname].TickerData = tm
      return {
        ...state,
        tmDetailPreferencesDataStore: {
          ...state.tmDetailPreferencesDataStore,
          [`${action.SecurityName}${action.SourceFilter}`]: {
            ...state.tmDetailPreferencesDataStore[`${action.SecurityName}${action.SourceFilter}`],
            TickerData : tm
          }
        }
      }
    }

    if(action.type === 'RESET_COINDETAIL_TICKER_EVENTS') {
      let tmdataStore = JSON.parse(JSON.stringify(state.tmDetailPreferencesDataStore))
      tmdataStore[action.columnindex].TickerEventList = {}
      tmdataStore[action.columnindex].TickerEventIndex = {}
      tmdataStore[action.columnindex].TickerEventIndexUpdated = 0
      tmdataStore[action.columnindex].TickerSearchData = {}
      tmdataStore[action.columnindex].TickerDetailsFeedReqData = {}
      return {
        ...state,
        tmDetailPreferencesDataStore: tmdataStore
      }
      
    }
    if(action.type === 'RE_SEARCH_COINDETAIL_TICKER_EVENTS') {
      let tmdataStore = JSON.parse(JSON.stringify(state.tmDetailPreferencesDataStore))
      
      tmdataStore[action.columnindex].TickerResearch = action.payload
      return {
        ...state,
        tmDetailPreferencesDataStore: tmdataStore
      }
      
    }



    return state;

}

export default trendingMentionReducer;