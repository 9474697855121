import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { APPPATH } from '../../../../modules/helper.module';
import { updateEventSecurityFilter } from '../../../../actions/filters.action';
// import { isMobile } from "react-device-detect";

class EventTypeList extends Component {
  constructor(props) {
    super();
    this.state = {
      eventTypes: props.eventTypes,
      eventFilter: props.eventFilter,
      List: [],
    };
    this.OverlayRef10 = React.createRef();
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      List: this.props.eventTypes.length ? this.makeEventTypes(this.props.eventTypes, this.props.eventFilter) : [],
    });
  }
  componentWillReceiveProps(nextProps, prevState) {
    if (nextProps.eventTypes !== this.props.eventTypes || nextProps.eventFilter !== this.props.eventFilter) {
      this.setState({
        ...this.state,
        eventTypes: nextProps.eventTypes !== this.props.eventTypes ? nextProps.eventTypes : this.state.eventTypes,
        eventFilter: nextProps.eventFilter !== this.props.eventFilter ? nextProps.eventFilter : this.state.eventFilter,
        List:
          nextProps.eventTypes !== this.props.eventTypes || nextProps.eventFilter !== this.props.eventFilter
            ? this.makeEventTypes(nextProps.eventTypes, nextProps.eventFilter)
            : this.state.List,
      });
    }
  }
  entering = (e) => {
    e.children[1].style.backgroundColor = '#37aaf8';
  };
  makeEventTypes = (EventTypes, EventFilter) => {
    // console.log(EventTypes, EventFilter)
    let parentState = [];
    if (EventTypes.length) {
      EventTypes.forEach((evnt, i) => {
        switch (evnt.Name) {
          case 'Top Coins Updates':
            evnt.Tooltip = ' Alerts of coins entering to the Top 5 of one of the metrics and change in the last 24hs';
            break;
          case 'Social Spikes':
            evnt.Tooltip = 'Social Media Sentiment and Mentions Spikes ';
            break;
          case 'Influencers':
            evnt.Tooltip = 'Crypto Posts of the most relevant users';
            break;
          case 'Whale Trades':
            evnt.Tooltip = 'Events with news of the biggest and important transactions';
            break;
          case 'Transfers Involving Exchanges':
            evnt.Tooltip = 'Crypto transfers between exchanges and wallets';
            break;
          case 'Market Updates':
            evnt.Tooltip = 'Events with price and market news';
            break;
          case 'Airdrops':
            evnt.Tooltip = 'Relevant news regarding Airdrops';
            break;
          case 'General Updates':
            evnt.Tooltip = 'General Crypto News';
            break;
          case 'Supply Updates':
            evnt.Tooltip = ' Mining, Halving, Burning and other supply updates';
            break;
          case 'Legal':
            evnt.Tooltip = 'Legal Crypto news';
            break;
          case 'Regulatory':
            evnt.Tooltip = 'Crypto Regulatory news';
            break;
          case 'Company Updates':
            evnt.Tooltip = 'Crypto Partnerships, adoptions and other Crypto Company news';
            break;
          case 'Cyber Security':
            evnt.Tooltip = 'Attacks, Hacks and other security news';
            break;
          case 'Chain Updates':
            evnt.Tooltip = 'News on Mainnets, Hard Forks, Soft Forks, Network upgrades and other Chain Updates';
            break;
          case 'Technical Analysis':
            evnt.Tooltip = 'Crypto Analysis, Price Predictions and Crypto Signals ';
            break;
          default:
            break;
        }

        if (EventFilter.length) {
          EventFilter.forEach((filt) => {
            if (evnt.Name.toString().trim().toLowerCase() === filt.toString().trim().toLowerCase()) {
              evnt.selected = true;
            }
          });
        }
        if (evnt.EventTypeParent === 0) {
          console.log(evnt)
          if(this.props.userdata.usertype === 'None' || this.props.userdata.usertype === 'WUser' || this.props.userdata.usertype === 'WAdmin') {
            if(evnt.Name === 'Whale Trades' || evnt.Name === 'Chain Updates') {
              parentState.push(evnt);
            } else if(parseInt(evnt.Radius) % 2 > 0 || parseInt(evnt.Radius) === 0) {
              parentState.push(evnt);
            }
          } else {
            if(evnt.Name === 'Influencers') {
              parentState.push(evnt);
            } else if(parseInt(evnt.Radius) % 2 === 0 ) {
              if(evnt.Name !== 'Whale Trades' && evnt.Name !== 'Chain Updates') {
                parentState.push(evnt);
              }
              
            }
          }
          
          
        }
      });
    }
    let sortedEventTypes = parentState.sort((a, b) => {
      return a.Radius - b.Radius;
    });

    return sortedEventTypes;
  };

  filterEventTypeTree = (eventTypeChildren, eventTypes, action, eventFilter) => {
    // console.log(eventTypeChildren, eventTypes, action, eventFilter)
    eventTypes.forEach((event) => {
      if (eventTypeChildren.indexOf(event.EventTypeID) > -1) {
        event.selected = action === 'remove' ? false : true;
        if (action === 'remove') {
          if (eventFilter.indexOf(event.Name) > -1) {
            eventFilter.splice(eventFilter.indexOf(event.Name), 1);
          }
        } else {
          if (eventFilter.indexOf(event.Name) === -1) {
            eventFilter.push(event.Name);
          }
        }
        if (event.children.length > 0) {
          eventFilter = this.filterEventTypeTree(event.children, eventTypes, action, eventFilter);
        }
      }
    });
    return eventFilter;
  };

  toggleSelectEventType = (eventType) => {
    // console.log(eventType);
    let { eventTypes, eventFilter } = this.state;
    let { userdata } = this.props;
    if (eventFilter.indexOf(eventType.Name) > -1) {
      eventFilter.splice(eventFilter.indexOf(eventType.Name), 1);
      eventTypes.forEach((evnt) => {
        if (evnt.Name === eventType.Name) {
          evnt.selected = false;
          if (evnt.children.length > 0) {
            eventFilter = this.filterEventTypeTree(evnt.children, eventTypes, 'remove', eventFilter);
          }
        }
      });
    } else {
      eventFilter.push(eventType.Name);
      eventTypes.forEach((evnt) => {
        if (evnt.Name === eventType.Name) {
          evnt.selected = true;
          if (evnt.children.length > 0) {
            eventFilter = this.filterEventTypeTree(evnt.children, eventTypes, 'add', eventFilter);
          }
        }
      });
    }
    let data = {
      UserName: userdata.username,
      FilterString: eventFilter.join(';'),
    };
    this.props.updateEventSecurityFilter(data);
    // this.setState({
    //   ...this.state,
    //   eventTypes,
    //   eventFilter,
    // });
  };

  render() {
    const { SecuritiesChecklist, EventTypesCheckList, welcomemode } = this.props;
    const { eventTypes, eventFilter, List } = this.state;
    const conditionCheck = !(SecuritiesChecklist && EventTypesCheckList);
    // console.log(eventTypes, eventFilter)
    let ListView = List.length
      ? List.map((lst, i) => (
          <OverlayTrigger
            placement="bottom"
            onEntering={this.entering}
            key={`overlay-${lst.Name}${i}`}
            overlay={<Tooltip>{lst.Tooltip}</Tooltip>}
          >
            <div
              className={`text-center marg5 EventTypeList-Label ${this.props.Theme === 'light' ? 'lightmode' : ''} ${lst.selected ? 'selected' : ''}`}
              key={`${lst.Name}${i}`}
              onClick={() => this.toggleSelectEventType(lst)}
            >
              <span className="align-self-center">
                <i className={`fa fa-check ${lst.selected ? '' : 'nodisp'}`} style={{ marginRight: 5 }}></i>
                {lst.Name}
              </span>
            </div>
          </OverlayTrigger>
        ))
      : [];
    return (
      <div className={`watchlist-content justify-content-center text-center ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
        <div className="btn tradeWindowClose text-white nopad marg-0" onClick={() => {
          this.props.welcomemodeon(false);
          this.props.modalClose(conditionCheck, 4)
        }}>
          <span className="pad10">
            <img className="tradeWindowClose-closeicon" src={`${APPPATH.ImageUrl}/button-close.svg`} alt="" />
          </span>
        </div>
        <Row className="marg-0 storymenuoptions-tabheader">
          <Col className="nopad">
            <div className={`nav nav-tabs watchlist nav-fill ${this.props.Theme === 'light' ? 'lightmode' : ''} `}>
              <div className={`nav-item fs16 active`}>
                {/* <span >
                                    <img className="nav-tab-img" src={`${APPPATH.ImageUrl}/likedislikevectoricon.svg`} alt="" />
                                </span> */}
                <span>My Events</span>
              </div>
            </div>
          </Col>
        </Row>

        <Row
          className="cScroll scroll-notrack marg-0 justify-content-center  pad5 text-center"
          style={{ maxHeight: 300 }}
        >
          {eventTypes.length && eventFilter.length ? (
            <Row className="marg-0 ">
              <Col lg={12} sm={12} xs={12} md={12} xl={12} className=" text-left">
                {ListView}
              </Col>
            </Row>
          ) : (
            <Col lg={12} sm={12} xs={12} md={12} xl={12} className="nopad text-center ">
              <div>
                <i className="fa fa-spinner fa-spin"></i>
              </div>
            </Col>
          )}
        </Row>
        {/*  */}
        <Row
          className={` marg-0 justify-content-center  pad5 text-center ${
            eventTypes.length && eventFilter.length ? '' : 'nodisp'
          }`}
        >
          <Col lg={3} sm={3} xs={3} md={3} xl={3} className={`text-left watchlistNavigatorButtons ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
            <span
              className={`${(!SecuritiesChecklist && !EventTypesCheckList) || welcomemode ? '' : 'nodisp'} pointer`}
              onClick={() => {
                window.Mixpanel.MixpanelProps.UserName = this.props.userdata.username;
                window.Mixpanel.actions.identify(this.props.userdata.username);
                window.Mixpanel.actions
                  .track('Clicking back to Watchlist modal', window.Mixpanel.MixpanelProps)
                  .then((data) => {
                    window.Mixpanel.actions.people.set({
                      UserName: this.props.userdata.username,
                    });
                  });
                this.props.changeView('WatchList');
              }}
            >
              Back
            </span>
          </Col>
          <Col lg={6} sm={6} xs={6} md={6} xl={6} className="text-center pad5 ">
            <div className={`${(!SecuritiesChecklist && !EventTypesCheckList) || welcomemode ? '' : 'nodisp'}`}>
              <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
              <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
              <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
              <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
              {
                (this.props.userdata.usertype === 'None' || this.props.userdata.usertype === 'WUser' || this.props.userdata.usertype === 'WAdmin') ? (
                  <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
                ) : ('')
              }
            </div>
          </Col>
          <Col lg={3} sm={3} xs={3} md={3} xl={3} className={`text-right watchlistNavigatorButtons ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
            <span
              className="pointer"
              onClick={() => {
                if ((!SecuritiesChecklist && !EventTypesCheckList) || welcomemode) {
                  
                  window.Mixpanel.MixpanelProps.UserName = this.props.userdata.username;
                  window.Mixpanel.actions.identify(this.props.userdata.username);
                  window.Mixpanel.actions
                    .track('Welcome process Event list continued', window.Mixpanel.MixpanelProps)
                    .then((data) => {
                      window.Mixpanel.actions.people.set({
                        UserName: this.props.userdata.username,
                      });
                    });
                  this.props.changeView('UserPreferenceList');
                } else {
                  this.props.hardRefreshSystem();
                }
              }}
            >
              {(!SecuritiesChecklist && !EventTypesCheckList) || welcomemode ? 'Next' : 'Confirm'}
            </span>
          </Col>
        </Row>
        {/* <div className={`${this.state.plwStatus ? '' : 'nodisp'} margin-auto`} style={{position: 'absolute', top :'50%', left: '50%' }}>
                    <i className="fa fa-spinner fa-2x fa-spin"></i>
                </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    welcomemode: state.settings.welcomemode,
    SecuritiesChecklist: state.settings.checkListStatus.SecuritiesCheckList,
    EventTypesCheckList: state.settings.checkListStatus.EventTypesCheckList,
    eventTypes: state.filters.eventTypes,
    eventFilter: state.filters.eventFilter,
    Theme: state.app_preferences.preferences.user_interface.Theme,
  };
};

const mapDispatchToProps = {
  updateEventSecurityFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventTypeList);
