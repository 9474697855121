import Store from '../../store';

export const TMExtract = (TMObject, prevTMObject = false) => {
    // console.log(TMObject)
    // let tmProps = Object.keys(TMObject);
    let Mentions = 0;
    let MentionsChange = 0;
    let PositiveMentions = 0;
    let PositiveMentionsChange = 0;
    let Reach = 0;
    let ReachChange = 0;
    let NegativeMentions = 0;
    let NegativeMentionsChange = 0;
    let Virality = 0;
    let SecurityPrice = 0;
    let SecurityPriceChangePerc = 0;
    let Volume = 0;
    Mentions = TMObject.Sentiment;
    MentionsChange = TMObject.SentimentChange;
    Reach = TMObject.Exposure;
    ReachChange = TMObject.ExposureChange;
    Virality = TMObject.Exposure;
    PositiveMentions = TMObject.PositiveSentimentPrec;
    PositiveMentionsChange = TMObject.PositiveSentimentPrecChange
    NegativeMentions = TMObject.NegativeSentimentPrec
    NegativeMentionsChange = TMObject.NegativeSentimentPrecChange
    SecurityPrice = parseFloat(TMObject.SecurityPrice).toFixed(10)
    SecurityPriceChangePerc = TMObject.SecurityPriceChange
    Volume = TMObject.Volume24hUsd
    // tmProps.length && tmProps.map(prop => { 
    //     Mentions += prop.toLowerCase().includes('sentiment') ? TMObject[prop] : 0
    //     PositiveMentions += prop.toLowerCase().includes('sentiment') && prop.toLowerCase().includes('positive') ? TMObject[prop] : 0
    //     NegativeMentions += prop.toLowerCase().includes('sentiment') && prop.toLowerCase().includes('negative') ? TMObject[prop] : 0
    //     Virality += prop.toLowerCase().includes('exposure') ? TMObject[prop] : 0
    // })
    return { 
        Mentions, 
        MentionsChange,
        Reach,
        ReachChange,
        Positive: PositiveMentions, 
        PositiveChange: PositiveMentionsChange,
        Negative: NegativeMentions, 
        NegativeChange: NegativeMentionsChange,
        Virality,
        SecurityPrice,
        Change: SecurityPriceChangePerc,
        Volume

    }
}



export const TMSort = (tmIndex, sortOnColumn, sortingType) => {
    
    let sortedTMIndex = [];
    if(sortingType === 1 || sortingType === 0) {
        sortedTMIndex = tmIndex.sort((a,b) => {
            // console.log('in sorting function', sortOnColumn, sortingType)
            // console.log(b, a)
            // console.log(b[`${sortOnColumn}`], a[`${sortOnColumn}`])
            return b[`${sortOnColumn}`] - a[`${sortOnColumn}`]
        });
    } else if(sortingType === -1) {
        sortedTMIndex = tmIndex.sort((a,b) => {
            return a[`${sortOnColumn}`] - b[`${sortOnColumn}`]
        });
    } else {
        // sortedTMIndex = tmIndex
    }

    return sortedTMIndex;
}

export function getSortedColumnKey(sortedColumn, PackageName)  {

    if(sortedColumn === 'Mentions Score') {
        return 'Mentions';
    } else if(sortedColumn === 'Reach Score') {
        return 'Reach';
    } else if(sortedColumn === 'Mentions Change') {
        return 'MentionsChange';
    } else if(sortedColumn === 'Reach Change') {
        return 'ReachChange';
    } else if(sortedColumn === 'Sentiment') {
        if(PackageName === 'mentionspositive') {
            return 'Positive';
        } else if(PackageName === 'mentionsnegative') {
            return 'Positive';
        }
    } else if(sortedColumn === 'Sentiment Change') {
        
        if(PackageName === 'mentionschangepositive') {
            return 'PositiveChange';
        } else if(PackageName === 'mentionschangenegative') {
            return 'PositiveChange';
        }
    } else if(sortedColumn === 'Price Change') {
        
        if(PackageName === 'pricechangepositive') {
            return 'PriceChange';
        } else if(PackageName === 'pricechangenegative') {
            return 'PriceChange';
        }
    } else if(sortedColumn === 'Price') {
        
        if(PackageName === 'pricepositive') {
            return 'Price';
        } else if(PackageName === 'pricenegative') {
            return 'Price';
        }
    } else if(sortedColumn === 'Volume') {
        return 'Volume';
    }
}

export const abbreviateNumber = (number) => {
    let SI_SYMBOL = ["", "K", "M", "B", "T", "B", "B"];
    // what tier? (determines SI symbol)
    let tier = Math.log10(Math.abs(number)) / 3 | 0;

    // if zero, we don't need a suffix
    if(tier === 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;
    // remove decimal if decimal is zero 
    var scaledToOneSplitParts = scaled.toFixed(1).split('.');
    if(parseInt(scaledToOneSplitParts[1]) <= 0) {
        scaled = parseInt(scaledToOneSplitParts[0])
    } else {
        scaled = scaled.toFixed(1)
    }

    // format number and add suffix
    return scaled + suffix;
}

export const currencyFormat = (Value, Prefix) => {
    // if(ticker === 'KISHU') {
    //     console.log(Value)
    // }
    let realValue = Value.toString().split('.')[0]
    let decimalValue = Value.toString().split('.')[1] ? Value.toString().split('.')[1] : '';
    let newDecimalValue = decimalValue;
    
    if(parseInt(realValue) > 99) {
        newDecimalValue = newDecimalValue.substring(0, 2)
    } else if(parseInt(realValue) > 0) {
        newDecimalValue = newDecimalValue.substring(0, 4)
    } else {
        let valCounter = 3;
        let maxCounter = 9;
        let newVal = '';
        for(let i= 0; i < decimalValue.toString().length ; i++) {
            maxCounter--;
            newVal = `${newVal}${decimalValue[i]}`
            if(parseInt(decimalValue[i]) !== 0) {
                valCounter--;
            }
            if(valCounter === 0 || maxCounter === 0) {
                newDecimalValue = newVal;
                break;
            }
        }
    }
   
    decimalValue = newDecimalValue;
    if(decimalValue.length > 2) {
        for(let i= decimalValue.length-1; i >=0 ; i--) {
       
            if(parseInt(decimalValue[i]) === 0) {
                newDecimalValue = decimalValue.slice(0,-1)
                decimalValue = newDecimalValue
            } else {
                break;
            }
        }
    }  
    
    
    let Amnt = `${Prefix}${realValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${newDecimalValue !== '' ? '.':''}${newDecimalValue !== '' ? newDecimalValue:''}`;
    
    return Amnt;
}

export const SortonSortingType = (tmIndex, sortOnColumn, sortingType) => {
    let TMArr = JSON.parse(JSON.stringify(tmIndex));
    TMArr.forEach((tm, index) => {
        const { Mentions, MentionsChange, Reach, ReachChange, Positive, PositiveChange, Negative, NegativeChange, Virality, SecurityPrice, Change, Volume } = TMExtract(tm)
        
        tm[`Mentions`] = Mentions;
        tm[`MentionsChange`] = MentionsChange;
        tm[`MentionsTrend`] = 0;
        tm[`Reach`] = Reach;
        tm[`ReachChange`] = ReachChange;
        tm[`ReachTrend`] = 0
        tm[`Positive`] = Positive;
        tm[`PositiveChange`] = PositiveChange;
        tm[`PositiveTrend`] = 0;
        tm[`Negative`] = Negative;
        tm[`NegativeChange`] = NegativeChange;
        tm[`NegativeTrend`] = 0;
        tm[`Virality`] = Virality;
        tm[`SecurityPrice`] = SecurityPrice;
        tm[`SecurityPriceTrend`] = 0;
        tm[`Change`] = Change;
        tm[`ChangeTrend`] = 0;
        tm[`Volume`] = Volume;
    })
    let SortednewList = TMSort(TMArr, sortOnColumn, sortingType);
   
    return SortednewList;
}

export const FormatCoinlist = (TopTrending, SortingType = false) => {
    // let SortedColumn = Store.getState().trendingMentions.tmSortedColumn;
    // SortingType = !SortingType && Store.getState().trendingMentions.tmSortingType;
    // let packageName = Store.getState().trendingMentions.packageName;
    // let SortingColumn = getSortedColumnKey(SortedColumn, packageName);
    let TMArr = JSON.parse(JSON.stringify(TopTrending));
    TMArr.forEach((tm, index) => {
        const { Mentions, MentionsChange, Reach, ReachChange, Positive, PositiveChange, Negative, NegativeChange, Virality, SecurityPrice, Change, Volume } = TMExtract(tm)
        // console.log(Mentions, MentionsChange, Reach, ReachChange, Positive, PositiveChange, Negative, NegativeChange, Virality, SecurityPrice, Change, Volume, tm)
        tm[`Mentions`] = Mentions;
        tm[`MentionsChange`] = MentionsChange;
        tm[`MentionsTrend`] = 0;
        tm[`Reach`] = Reach;
        tm[`ReachChange`] = ReachChange;
        tm[`ReachTrend`] = 0
        tm[`Positive`] = Positive;
        tm[`PositiveChange`] = PositiveChange;
        tm[`PositiveTrend`] = 0;
        tm[`Negative`] = Negative;
        tm[`NegativeChange`] = NegativeChange;
        tm[`NegativeTrend`] = 0;
        tm[`Virality`] = Virality;
        tm[`SecurityPrice`] = SecurityPrice;
        tm[`SecurityPriceTrend`] = 0;
        tm[`Change`] = Change;
        tm[`ChangeTrend`] = 0;
        tm[`Volume`] = Volume;
    })
    // console.log(TMArr, SortingColumn, SortingType)
    // let SortednewList = TMSort(TMArr, SortingColumn, SortingType);
    return TMArr;
}

export const HandleForWatchlist = (TopTrending) => {
    let SecuritiesFilter = Store.getState().filters.securitiesFilter;
    let TMArr = JSON.parse(JSON.stringify(TopTrending));
    let watchListArr = [];
    
    // console.log(tm)
    TMArr.forEach((tm, index) => {
        

        if(SecuritiesFilter.indexOf(tm.SecurityName) > -1) {
            watchListArr.push(tm);
            
        } 
    })
    
    
    return watchListArr ;
}

export const FreeTopTrendingOnlyFilter = (TopTrending, cc_preferences = false) => {
    
    // let topCount = 50
    let slicecount = 0;
    // let Securities = Store.getState().filters.securities;
    let SuscriptionStatus = Store.getState().settings.userData.userPackage;
    let freeTrial = Store.getState().settings.freeTrial;
    let daystogo = Store.getState().settings.freeTrialDetails.daystogo;
    
    
    let FilteredTopTrending = [];
    if(SuscriptionStatus.toLowerCase() === 'bronze' && ( (!freeTrial) || (freeTrial && daystogo <= 0) )) {
    // if(SuscriptionStatus.toLowerCase() === 'bronze' && !freeTrial) {
        // topCount = 25
        slicecount = 10
    } else {
        // topCount = 75
        // slicecount = cc_preferences.tmListMaxCoinCount ? parseInt(cc_preferences.tmListMaxCoinCount) : Store.getState().trendingMentions.tmListMaxCoinCount
        slicecount = parseInt(cc_preferences.tmListMaxCoinCount);
        // slicecount = 50
    }
    
    // Securities = Securities.length > 0 ? (
    //     Securities.sort((a, b) => {
    //         return parseFloat(b.Scope) - parseFloat(a.Scope);
    //     }
    // )) : ([])
    // let SecurityNameArr = Securities.length > 0 ? Securities.slice(0, topCount).map((itm) => (itm.Name.toLowerCase())).filter((itm) => itm) : ([])
    // // console.log(SecurityNameArr, TopTrending)
    // if(TopTrending.length > 0) {
    //     TopTrending.forEach((itm) => {
    //         // console.log(itm)
    //         if(SecurityNameArr.indexOf(itm.SecurityName.toLowerCase()) > -1) {
    //             FilteredTopTrending.push(itm)
    //         } 
    //     })
    // }
    // console.log(TopTrending, slicecount)
    FilteredTopTrending = TopTrending.slice(0, slicecount)
    return FilteredTopTrending;
}

export const FreeCoinFilter = (coinData, topCount = 50) => {
    
    let SortedSecurities = [];
    let SuscriptionStatus = Store.getState().settings.userData.userPackage;
    let freeTrial = Store.getState().settings.freeTrial;
    let daystogo = Store.getState().settings.freeTrialDetails.daystogo;
    
    if(SuscriptionStatus.toLowerCase() === 'bronze' && ( (!freeTrial) || (freeTrial && daystogo <= 0) )) {
    // if(SuscriptionStatus.toLowerCase() === 'bronze' && ! freeTrial) {
        SortedSecurities = Store.getState().filters.securities;
        // console.log(SortedSecurities)
        // console.log(SortedSecurities)
        let SecurityNameArr = SortedSecurities.length > 0 ? SortedSecurities.slice(0,topCount).map((itm) => (itm.Name.toLowerCase())).filter((itm) => itm) : ([])
        // console.log(SecurityNameArr, coinData)
        if(SecurityNameArr.indexOf(coinData.SecurityName.toLowerCase()) > -1) {
            return true
        } else {
            return false
        }
    } else {
        return true;
    }
}

export const FreeSubscriptionFilter = () => {
    let SuscriptionStatus = Store.getState().settings.userData.userPackage;
    let freeTrial = Store.getState().settings.freeTrial;
    let daystogo = Store.getState().settings.freeTrialDetails.daystogo;
    
    if(SuscriptionStatus.toLowerCase() === 'bronze' && ( (!freeTrial) || (freeTrial && daystogo <= 0) )) {
        return true;
    }
    return false;
}
export const FreeBannerFilter = () => {
    let SuscriptionStatus = Store.getState().settings.userData.userPackage;
    
    if(SuscriptionStatus.toLowerCase() === 'bronze') {
        return true;
    }
    return false;
}

export const SortSecuritiesByMarketCapitalisation = (data) => {
    let SortedSecurities = [];
    let Data = JSON.parse(JSON.stringify(data));
    if(Data.length) {
        // SortedSecurities = Data.sort((a, b) => {
        //     console.log()
        //     return parseInt(b.Scope) - parseInt(a.Scope);
        // })
        for(let i=0; i<Data.length; i++) {
            for(let j=i+1; j<Data.length; j++) {
                // if(j !== i) {
                    if(parseInt(Data[i].Scope) < parseInt(Data[j].Scope)) {
                        let Tmp = Data[i];
                        Data[i] = Data[j];
                        Data[j] = Tmp;
                    }
                // }
            }
           
            
        }
    } 
    SortedSecurities = Data
    return SortedSecurities
    
}

const cleanupcoindetailcolumn = (FormattedSettingsArr, pref, orderSettingArr) => {
    if(FormattedSettingsArr.length) {
        FormattedSettingsArr.forEach((CDPref, i) => {
            if(orderSettingArr.indexOf(CDPref) === -1) {
                console.log(CDPref, i)
                pref.splice(i, 1);
            } else {
                if(!CDPref.includes('sourceFilter') || !CDPref.includes('columnFilter') || !CDPref.includes('CDSearch_with_white')) {
                    orderSettingArr.splice(orderSettingArr.indexOf(CDPref), 1)
                    pref.splice(i, 1);
                }
            }
        })
    }
    return {CDprefStr: `${pref.join(';')};`, CDorderSettingArr: orderSettingArr}
}

const cleanuptopcoins = (FormattedSettingsArr, pref, orderSettingArr) => {

}

const cleanupsettings = (CoinColumnPreferencesString, CoinDetailColumnPreferencesString, columorderString) => {
    
    //column order settings
    // console.log('columnorder',columorderString)
    let COPrefObjStr = '';
    let eachordersetting = [];
    if(typeof columorderString !== undefined && columorderString !== '') {
        COPrefObjStr = columorderString.split('--')[1]
        eachordersetting = COPrefObjStr.split('??').filter(itm => (itm))
    }
    // let COPrefObjStr = columorderString.split('--')[1]
    // console.log('COPrefObjStr',COPrefObjStr)
    // let eachordersetting = COPrefObjStr.split('??').filter(itm => (itm))

    //top coins settings
    let CCPrefs = CoinColumnPreferencesString.split(';').filter(itm => (itm));
    let CCformattedPrefArr = []
    if(CCPrefs.length) {
        CCPrefs.forEach((itm, ln) => {
            let prefObjStr = itm.split(':')[1]
            let formattedObjStr = prefObjStr.replace(/,/g, '||')
            CCformattedPrefArr.push(formattedObjStr)
        })
    }
    // let {CCprefStr, CCorderSettingArr} =  cleanuptopcoins(CCformattedPrefArr, CCPrefs, eachordersetting);
    let newCoinColumnPreferencesString = CoinColumnPreferencesString;//CCprefStr;
    // eachordersetting = CCorderSettingArr;

    //coin detail column setting
    let CDPrefs = CoinDetailColumnPreferencesString.split(';').filter(itm => (itm));
    let CDformattedPrefArr = []
    if(CDPrefs.length) {
        CDPrefs.forEach((itm, ln) => {
          let prefObjStr = itm.split(':')[1]
          let formattedObjStr = prefObjStr.replace(/,/g, '||')
          CDformattedPrefArr.push(formattedObjStr)
        })
    }
   
    let {CDprefStr, CDorderSettingArr} =  cleanupcoindetailcolumn(CDformattedPrefArr, CDPrefs, eachordersetting)
    let newCoinDetailColumnPreferencesString = CDprefStr
    eachordersetting = CDorderSettingArr

    let newcolumorderString = eachordersetting.join('??');
    newcolumorderString = `columnorderpreference--${newcolumorderString}`

    // console.log(newCoinColumnPreferencesString)
    // console.log(newCoinDetailColumnPreferencesString)
    // console.log(newcolumorderString)
    return {
        CoinColumnPreferencesString: newCoinColumnPreferencesString,
        CoinDetailColumnPreferencesString: newCoinDetailColumnPreferencesString,
        columorderString: newcolumorderString
    }
}

export const stripCoinColumnPreferencesFromUserPreferences = (prefernceString) => {
    
    let TmpUserArr = [];
    let TmpCoinColArr = [];
    let TmpCoinDetailColArr = [];
    let TmpOrderColArr = [];
    if(prefernceString !== '') {
        
        let eachPreference = prefernceString.split(';')
        // console.log(prefernceString, eachPreference)
        if(eachPreference.length) {
            eachPreference.forEach(pref => {
                if(pref.split('--')[0].startsWith('columnorderpreference')) {
                    
                    TmpOrderColArr.push(pref);
                } else if(pref.split(':')[0].startsWith('coincolumnpreference')) {
                    
                    TmpCoinColArr.push(pref);
                } else if(pref.split(':')[0].startsWith('coindetailcolumnpreference')) {
                    TmpCoinDetailColArr.push(pref)
                } else {
                    TmpUserArr.push(pref);
                }
            })
        }
        TmpUserArr = TmpUserArr.filter(itm => (itm));   
        // console.log('here in preference seperator')
    }
    let {CoinColumnPreferencesString, CoinDetailColumnPreferencesString, columorderString} = cleanupsettings(
        TmpCoinColArr.length ? `${TmpCoinColArr.join(';')};`  : ``, 
        TmpCoinDetailColArr.length ? `${TmpCoinDetailColArr.join(';')};`  : ``, 
        TmpOrderColArr.join('')
    );
    return { 
        UserPreferencesString: `${TmpUserArr.join(';')};`, 
        CoinColumnPreferencesString,//: TmpCoinColArr.length ? `${TmpCoinColArr.join(';')};`  : ``,
        CoinDetailColumnPreferencesString,//: TmpCoinDetailColArr.length ? `${TmpCoinDetailColArr.join(';')};`  : ``,
        columorderString//: TmpOrderColArr.join('')
    };
}

export const CreateCCPreferencesStrings = (cc_preference_obj, columnType = 'topcoins') => {
    // console.log(cc_preference_obj);
    let ccStr = '';
    if(cc_preference_obj.length) {
        cc_preference_obj.forEach((ccpref, i) => {
            switch(columnType) {
                case 'coindetails':
                    ccStr = `${ccStr}coindetailcolumnpreference${i +1}:`
                    break;
                default:
                    ccStr = `${ccStr}coincolumnpreference${i +1}:`
                    break;
            }
            
            if(Object.keys(ccpref).length) {
                let contentStrArr = [];
                let contentStr = '';
                Object.keys(ccpref).map((key, ln) => {
                    contentStrArr.push(`${key}__${ccpref[key]}`)
                })
                contentStr = contentStrArr.join(',');
                ccStr = `${ccStr}${contentStr};`
            }
        })
    }
    return ccStr;
}

export const updateTabChangePreferences = (prefObj, tab) => {
    let PackageName = prefObj.packageName;
    let sortedColumn = prefObj.tmSortedColumn;
    let columnToStay = (sortedColumn === 'Mentions Score' || sortedColumn === 'Reach Score' || sortedColumn === 'Sentiment' || sortedColumn === 'Price' || sortedColumn === 'Volume') ? 'score' : 'change'
    let SortingType = parseInt(prefObj.tmSortingType);
    switch (tab) {
        case 'TopMentions':
          if(columnToStay === 'change') {
            PackageName = 'mentionschange';
            sortedColumn = 'Mentions Change';
            break;
          } else {
            PackageName = 'mentions';
            sortedColumn = 'Mentions Score';
            break;
          }
          
        case 'TopReach':
          if(columnToStay === 'change') {
            PackageName = 'exposurechange';
            sortedColumn = 'Reach Change';
            break;
          } else {
            PackageName = 'exposure';
            sortedColumn = 'Reach Score';
            break;
          }
          
        case 'TopSentiment':
          if(SortingType === 1) {
            if(columnToStay === 'change') {
              PackageName = 'mentionschangepositive';
              sortedColumn = 'Sentiment Change';
              break;
            } else {
              PackageName = 'mentionspositive';
              sortedColumn = 'Sentiment';
              break;
            }
          } else {
            if(columnToStay === 'change') {
              PackageName = 'mentionschangenegative';
              sortedColumn = 'Sentiment Change';
              break;
            } else {
              PackageName = 'mentionsnegative';
              sortedColumn = 'Sentiment';
              break;
            }
          }
          
          
        case 'TopPrice':
          if(SortingType === 1) {
            if(columnToStay === 'change') {
              PackageName = 'pricechangepositive';
              sortedColumn = 'Price Change';
              break;
            } else {
              PackageName = 'pricepositive';
              sortedColumn = 'Price';
              break;
            }
          } else {
            if(columnToStay === 'change') {
              PackageName = 'pricechangenegative';
              sortedColumn = 'Price Change';
              break;
            } else {
              PackageName = 'pricenegative';
              sortedColumn = 'Price';
              break;
            }
          }
        case 'TopVolume':
          if(SortingType === 1) {
            PackageName = 'volumepositive';
            sortedColumn = 'Volume';
            break;
          } else {
            PackageName = 'volumenegative';
            sortedColumn = 'Volume';
            break;
          }
        default:
          break;
    }
    prefObj.activeTab = tab;
    prefObj.packageName = PackageName;
    prefObj.tmSortedColumn = sortedColumn;
    return prefObj;

}

export const updateSortChangePreferences = (prefObj, activeTab, lastSortedColumn, lastSortingType, columnChange) => {
    let PackageName = prefObj.packageName;
    let sortedColumn = lastSortedColumn;
    let SortingType = parseInt(lastSortingType);
    if (!columnChange) {
        // if (activeTab === 'TopMentions' || activeTab === 'TopReach') {
        //     SortingType = lastSortingType;
        // } else 
        if(activeTab === 'TopVolume') {
            let newPackageName = '';
            switch (PackageName) {
                case 'volumepositive':
                  newPackageName = 'volumenegative';
                  SortingType = -1;
                  break;
                case 'volumenegative':
                  newPackageName = 'volumepositive';
                  SortingType = 1;
                  break;
                default:
                  newPackageName = PackageName;
                  break;
            }
            PackageName = newPackageName
        } else if (activeTab === 'TopSentiment') {
            let newPackageName = '';
            switch (PackageName) {
                case 'mentionspositive':
                  newPackageName = 'mentionsnegative';
                  SortingType = -1;
                  break;
                case 'mentionsnegative':
                  newPackageName = 'mentionspositive';
                  SortingType = 1;
                  break;
                case 'mentionschangepositive':
                  newPackageName = 'mentionschangenegative';
                  SortingType = -1;
                  break;
                case 'mentionschangenegative':
                  newPackageName = 'mentionschangepositive';
                  SortingType = 1;
                  break;
                default:
                  newPackageName = PackageName;
                  break;
            }
            PackageName = newPackageName
        }  else if (activeTab === 'TopPrice') {
            let newPackageName = '';
            switch (PackageName) {
                case 'pricechangepositive':
                  newPackageName = 'pricechangenegative';
                  SortingType = -1;
                  break;
                case 'pricechangenegative':
                  newPackageName = 'pricechangepositive';
                  SortingType = 1;
                  break;
                case 'pricepositive':
                  newPackageName = 'pricenegative';
                  SortingType = -1;
                  break;
                case 'pricenegative':
                  newPackageName = 'pricepositive';
                  SortingType = 1;
                  break;
                default:
                  newPackageName = PackageName;
                  break;
            }
            PackageName = newPackageName
        }
    } else {
        let newPackageName = '';
        if (activeTab === 'TopMentions') {
            if (sortedColumn === 'Mentions Score') {
              newPackageName = 'mentions';
            } else if (sortedColumn === 'Mentions Change') {
              newPackageName = 'mentionschange';
            }
        } else if (activeTab === 'TopReach') {
            if (sortedColumn === 'Reach Score') {
              newPackageName = 'exposure';
            } else if (sortedColumn === 'Reach Change') {
              newPackageName = 'exposurechange';
            }
        } else if (activeTab === 'TopSentiment') {
            if (sortedColumn === 'Sentiment') {
                if(SortingType === 1) {
                    newPackageName = 'mentionspositive';
                } else {
                    newPackageName = 'mentionsnegative';
                }
              
            } else if (sortedColumn === 'Sentiment Change') {
                if(SortingType === 1) {
                    newPackageName = 'mentionschangepositive';
                } else {
                    newPackageName = 'mentionschangenegative';
                }
            }
        } else if (activeTab === 'TopPrice') {
            if (sortedColumn === 'Price Change') {
                if(SortingType === 1) {
                    newPackageName = 'pricechangepositive';
                } else {
                    newPackageName = 'pricechangenegative';
                }
            } else if (sortedColumn === 'Price') {
                if(SortingType === 1) {
                    newPackageName = 'pricepositive';
                } else {
                    newPackageName = 'pricenegative';
                }
            }
        }
        PackageName = newPackageName
    }

    
    prefObj.packageName = PackageName;
    prefObj.tmSortedColumn = sortedColumn;
    prefObj.tmSortingType = SortingType;
    return prefObj;
}

export const newTopCoinPreference = () => {
    return Store.getState().trendingMentions.coin_column_preference_template;
}

export const newCoinDetailColumnPreference = (TickerData) => {
    let prefTemplate =  JSON.parse(JSON.stringify(Store.getState().trendingMentions.tmDetailPreferencesTemplate));
    // console.log(prefTemplate, TickerData)
    prefTemplate['SecurityName'] = TickerData.SecurityName
    prefTemplate['SecurityTicker'] = TickerData.SecurityTicker
    prefTemplate['sourceFilter'] = TickerData.SourceFilter && TickerData.SourceFilter !== '' ? TickerData.SourceFilter : ''
    return prefTemplate;
}