import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { ResetTMSort } from '../../actions/trendingmentions.action';
// import * as moment from 'moment';
import { connect } from 'react-redux';
import { APPPATH } from '../../modules/helper.module';
import { isMobile } from 'react-device-detect';

class TMSubtitles extends Component {
    constructor(props) {
        super();
        this.state = {
            activeTab: props.activeTab,
            tmSortedColumn: props.tmSortedColumn,
            tmSortingType: props.tmSortingType,
            subtitle: this.makeSubtitle(props.activeTab, props.tmSortedColumn, props.tmSortingType),
            collapseHover: false
        };
    
       
    }

    componentWillReceiveProps(nextProps, prevState) {
        this.setState({
            ...this.state,
            activeTab: nextProps.activeTab,
            tmSortedColumn: nextProps.tmSortedColumn,
            tmSortingType: nextProps.tmSortingType,
            subtitle: this.makeSubtitle(nextProps.activeTab, nextProps.tmSortedColumn, nextProps.tmSortingType)
        });
    }

    makeSubtitle = (activeTab, tmSortedColumn, tmSortingType) => {
        // const { activeTab, tmSortedColumn, tmSortingType } = this.state;
        let subtitle;
        switch(activeTab) {
            case 'TopSentiment':
                if(tmSortedColumn === 'Sentiment Change') {
                    if(parseInt(tmSortingType) === 1) {
                        subtitle = 'Top By Sentiment Spike';
                    } else if(parseInt(tmSortingType) === -1) {
                        subtitle = 'Top By Sentiment Drop';
                    }
                } else if(tmSortedColumn === 'Sentiment') {
                    if(parseInt(tmSortingType) === 1) {
                        subtitle = 'Top By Positive Sentiment';
                    } else if(parseInt(tmSortingType) === -1) {
                        subtitle = 'Top By Negative Sentiment';
                    }
                }
                break;
            case 'TopMentions':
                if(tmSortedColumn === 'Mentions Change') {
                    if(parseInt(tmSortingType) === 1) {
                        subtitle = 'Top By Mentions Spike';
                    } else if(parseInt(tmSortingType) === -1) {
                        subtitle = 'Top By Mentions Drop';
                    }
                } else if(tmSortedColumn === 'Mentions Score') {
                    if(parseInt(tmSortingType) === 1) {
                        subtitle = 'Top By Most Mentions';
                    } else if(parseInt(tmSortingType) === -1) {
                        subtitle = 'Top By Least Mentions';
                    }
                }
                break;
            case 'TopReach':
                if(tmSortedColumn === 'Reach Score') {
                    if(parseInt(tmSortingType) === 1) {
                        subtitle = 'Top By Most Followers Reach';
                    } else if(parseInt(tmSortingType) === -1) {
                        subtitle = 'Top By Least Followers Reach';
                    }
                } else if(tmSortedColumn === 'Reach Change') {
                    if(parseInt(tmSortingType) === 1) {
                        subtitle = 'Top By Followers Reach Spike';
                    } else if(parseInt(tmSortingType) === -1) {
                        subtitle = 'Top By Followers Reach Drop';
                    }
                }
                break;
            case 'TopPrice':
                if(tmSortedColumn === 'Price') {
                    if(parseInt(tmSortingType) === 1) {
                        subtitle = 'Top By Highest Price';
                    } else if(parseInt(tmSortingType) === -1) {
                        subtitle = 'Top By Lowest Price';
                    }
                } else if(tmSortedColumn === 'Price Change') {
                    if(parseInt(tmSortingType) === 1) {
                        subtitle = 'Top By Price Spike';
                    } else if(parseInt(tmSortingType) === -1) {
                        subtitle = 'Top By Price Drop';
                    }
                }
                break;
            case 'TopVolume':
                if(tmSortedColumn === 'Volume') {
                    if(parseInt(tmSortingType) === 1) {
                        subtitle = 'Top By Highest Trade Volume';
                    } else if(parseInt(tmSortingType) === -1) {
                        subtitle = 'Top By Lowest Trade Volume';
                    }
                } 
                break;
            default:
                subtitle = '';
                break;
        }
        return subtitle;
    }

    
    toggleCollapseHover = () => {
        this.setState({
            ...this.state,
            collapseHover: !this.state.collapseHover
        })
    }

    render() {
        const { subtitle, collapseHover } = this.state;
        let collapseStatus = this.props.maxcoinmenu === '' ? false : true;
        // console.log(this.props.usertype)
        return (
            <Row className={`marg-0  tmTabWrap bg-tmSubtitles pad-tmSubtitles ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                <Col lg={7} sm={7} xs={7} md={7} xl={7} className={`nopad tmSubtitles ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>{subtitle}</Col>
                {
                    (this.props.usertype === 'None' || this.props.usertype === 'WUser' || this.props.usertype === 'WAdmin') && (
                        <Col lg={5} sm={5} xs={5} md={5} xl={5} className={`text-right `} style={{padding: '0px 10px 5px 0px'}}>
                            <span className={`usercoincount pointer ${this.props.Theme === 'light' ? 'lightmode' : ''}`} onClick={this.props.togglemaxCoinState}>
                                <i className={`fa fa-sliders pad2r5px fs14 `}></i>
                                {this.props.CoinMenuText}  
                                <span
                                    onMouseEnter={this.toggleCollapseHover}
                                    onMouseLeave={this.toggleCollapseHover}
                                >
                                    <img
                                        style={{ width: '12px', marginTop: '-5px', marginLeft: '5px' }}
                                        src={`${
                                            collapseHover
                                            ? `${APPPATH.ImageUrl}/storyexpandiconblue.svg`
                                            : `${APPPATH.ImageUrl}/storyexpandicon.svg`
                                        }`}
                                        className={`storyExpandIcon ${!collapseStatus ? '' : 'transform180anticlock'}`}
                                        alt=""
                                    />
                                </span>
                            </span>
                            {this.props.maxcoinmenu}
                        </Col>
                    )
                }
            </Row>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        activeTab: state.trendingMentions.coin_column_preferences[ownProps.cc_index] ? state.trendingMentions.coin_column_preferences[ownProps.cc_index].activeTab : '',
        tmSortedColumn: state.trendingMentions.coin_column_preferences[ownProps.cc_index] ? state.trendingMentions.coin_column_preferences[ownProps.cc_index].tmSortedColumn : '',
        tmSortingType: state.trendingMentions.coin_column_preferences[ownProps.cc_index] ? parseInt(state.trendingMentions.coin_column_preferences[ownProps.cc_index].tmSortingType) : '',
        Theme: state.app_preferences.preferences.user_interface.Theme,
    };
};
  
const mapDispatchToProps = {
    
};
  
export default connect(mapStateToProps, mapDispatchToProps)(TMSubtitles);