import { APICONFIG, ErrorHandleHelper, TrendingMentionsHelper, PreferenceHandler } from '../modules/helper.module';
import * as logactions from '../actions/logAction.action';
import Store from '../store';
import { Userpreferencedatastring } from '../static_data/Autologindata';


export function setusertheme(theme) {
  return (dispatch) => {
    dispatch({
      type: 'SETUSERTHEME',
      payload: theme,
    });
  };
}

export function getdesktopapppreferences() {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/userpreferences/getdesktopappinfo?userName=${APICONFIG.apiLoggedInUser()}`, {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      })
        .then(async (response) => {
          if (response.status === 200 && response.ok) {
            await response.json().then((result) => {
              // console.log(result)
              dispatch({
                type: 'SET_DESKTOPAPP_PREFERENCES',
                payload: result,
              });
            });
          } else {
            await response.json().then((result) => {
              console.log(result);
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in getdesktopapppreferences' + err);
          // logactions.logger({Message: 'error in getdesktopapppreferences'+ err})
          // logactions.logger({Message: ErrorHandleHelper.generateErrorString(err, 'Error in DesktopPreferences')})
          // throw new Error('error in getdesktopapppreferences'+  err);
          // logactions.addLogAction({Message: err})
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Get Desktop App Preferences',
            url: `${
              APICONFIG.apiBaseUrl
            }/api/userpreferences/getdesktopappinfo?userName=${APICONFIG.apiLoggedInUser()}`,
            severity: 'low',
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'DesktopPreferences'),
            data: data,
          });
        });
    } catch (err) {
      console.log('error in getdesktopapppreferences', JSON.stringify(err));
      // logactions.addLogAction({Message: err})
    }
  };
}

export function getuserpreferences() {
  return (dispatch) => {
    try {
      let loginlessuser = Store.getState().settings.userData.loginlessuser
      if(typeof Userpreferencedatastring[loginlessuser] !== 'undefined' && Userpreferencedatastring[loginlessuser].preferenceString) {
        let { 
          UserPreferencesString, 
          CoinColumnPreferencesString,
          CoinDetailColumnPreferencesString,
          columorderString
        } = TrendingMentionsHelper.stripCoinColumnPreferencesFromUserPreferences(Userpreferencedatastring[loginlessuser].preferenceString);
        let ccps = CoinColumnPreferencesString.split('coincolumnpreference1:').filter((itm) => itm);
        if(ccps.length > 1) {
          CoinColumnPreferencesString = `coincolumnpreference1:${ccps[0]}`;
        }
        let columnOrderArray = PreferenceHandler.CreateColumnOrderData(columorderString);
        dispatch({
          type: 'SET_USER_PREFERENCES',
          payload: UserPreferencesString,
          forcetheme: Store.getState().settings.forcetheme
          // noblack: true
        });
        dispatch({
          type: 'SET_COINCOLUMN_PREFERENCES',
          payload: CoinColumnPreferencesString,
          initialpreference: true,
          showtopreach: Store.getState().settings.showtopreach
        })
        dispatch({
          type: 'SET_COINDETAILCOLUMN_PREFERENCES',
          payload: CoinDetailColumnPreferencesString,
          tickerdata: false
        })
        dispatch ({
          type : 'SET_COLUMN_ORDER_LIST',
          payload : columnOrderArray
        })
      } else {
        fetch(`${APICONFIG.apiBaseUrl}/api/userpreferences/getuserpreferences?userName=${APICONFIG.apiLoggedInUser()}`, {
          method: 'GET',
          headers: APICONFIG.apiHeaders(),
        })
        .then(async (response) => {
          if (response.status === 200 && response.ok) {
            await response.json().then((result) => {
              // console.log(result)
              let { 
                UserPreferencesString, 
                CoinColumnPreferencesString,
                CoinDetailColumnPreferencesString,
                columorderString
              } = TrendingMentionsHelper.stripCoinColumnPreferencesFromUserPreferences(result);


              //fix for messed up coincloumnsetting due to search
              let ccps = CoinColumnPreferencesString.split('coincolumnpreference1:').filter((itm) => itm);
              // console.log(ccps)
              if(ccps.length > 1) {
                CoinColumnPreferencesString = `coincolumnpreference1:${ccps[0]}`;
              }
              //fix for messed up coincloumnsetting due to search
              // console.log(UserPreferencesString)
              // console.log(CoinColumnPreferencesString)
              // console.log(CoinDetailColumnPreferencesString)
              // console.log(columorderString)
              // return;
              let columnOrderArray = PreferenceHandler.CreateColumnOrderData(columorderString);
              // console.log(columnOrderArray)
              // console.log(CoinDetailColumnPreferencesString)
              // return;
              dispatch({
                type: 'SET_USER_PREFERENCES',
                payload: UserPreferencesString,
                forcetheme: Store.getState().settings.forcetheme
                // noblack: true
              });
              // if(typeof CoinColumnPreferencesString !== 'undefined' && CoinColumnPreferencesString !== '') {
                // console.log(CoinColumnPreferencesString)
                // return
                // console.log(CoinColumnPreferencesString)
                dispatch({
                  type: 'SET_COINCOLUMN_PREFERENCES',
                  payload: CoinColumnPreferencesString,
                  initialpreference: true,
                  showtopreach: Store.getState().settings.showtopreach
                })
              // }
              dispatch({
                type: 'SET_COINDETAILCOLUMN_PREFERENCES',
                payload: CoinDetailColumnPreferencesString,
                tickerdata: false
              })
              dispatch ({
                type : 'SET_COLUMN_ORDER_LIST',
                payload : columnOrderArray
              })

              // let searchsavedata = Store.getState().search.searchSaveData;
              // console.log('searchsave data');
              // console.log(searchsavedata);
            });
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in getuserpreferences', err);
          // logactions.logger({Message: 'error in getuserpreferences'+ err})
          // throw new Error('error in getuserpreferences' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Get User Preferences',
            url: `${
              APICONFIG.apiBaseUrl
            }/api/userpreferences/getuserpreferences?userName=${APICONFIG.apiLoggedInUser()}`,
            severity: 'high',
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'GetUserPreferences'),
            data: data,
          });
        });
      }
      
    } catch (err) {
      // logactions.addLogAction(err)
    }
  };
}

export function getnotificationpreferences() {
  return (dispatch) => {
    try {
      fetch(
        `${
          APICONFIG.apiBaseUrl
        }/api/userpreferences/getnotificationspreferences?userName=${APICONFIG.apiLoggedInUser()}`,
        {
          method: 'GET',
          headers: APICONFIG.apiHeaders(),
        }
      )
        .then(async (response) => {
          if (response.status === 200 && response.ok) {
            await response.json().then((result) => {
              // console.log(result)
              dispatch({
                type: 'SET_NOTIFICATION_PREFERENCES',
                payload: result,
              });
            });
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in getnotificationpreferences', err);
          // logactions.logger({Message: 'error in getnotificationpreferences'+ err})
          // throw new Error('error in getnotificationpreferences' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Get Notification Preferences',
            url: `${
              APICONFIG.apiBaseUrl
            }/api/userpreferences/getnotificationspreferences?userName=${APICONFIG.apiLoggedInUser()}`,
            severity: 'low',
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'GetNotificationPreferences'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }
  };
}

export function getlocalisationpreferences() {
  return (dispatch) => {
    try {
      fetch(
        `${
          APICONFIG.apiBaseUrl
        }/api/userpreferences/gettimezonepreferencesanddifference?userName=${APICONFIG.apiLoggedInUser()}`,
        {
          method: 'GET',
          headers: APICONFIG.apiHeaders(),
        }
      )
        .then(async (response) => {
          if (response.status === 200 && response.ok) {
            await response.json().then((result) => {
              // console.log(result)
              let TZName = result.split(';')[0];
              let TZOffset = result.split(';')[1];
              let tempTZOffset1 = TZName.substring(TZName.lastIndexOf('(') + 1, TZName.lastIndexOf(')'));
              let tempTZOffset = tempTZOffset1.replace('UTC', '');
              // result = 'India Standard Time';
              // console.log(TZName)
              // console.log(TZOffset)
              dispatch({
                type: 'SET_LOCALISATION_PREFERENCES',
                payload: TZName
                // payload: {
                //   id: TZName !== '' ? TZName : tempTZOffset1,
                //   UTCOffset: tempTZOffset !== '' ? tempTZOffset : TZOffset,
                // },
              });
              dispatch({
                type: 'SET_TZ_OFFSET',
                payload: TZOffset,
              });
            });
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in getlocalisationpreferences', err);
          // logactions.logger({Message: 'error in getlocalisationpreferences'+ err})
          // throw new Error('error in getlocalisationpreferences' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Get Localisation Preferences',
            url: `${
              APICONFIG.apiBaseUrl
            }/api/userpreferences/gettimezonepreferences?userName=${APICONFIG.apiLoggedInUser()}`,
            severity: 'low',
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'GetLocalisationPreferences'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }
  };
}

export function getphonepreferences() {
  return (dispatch) => {
    try {
      fetch(
        `${APICONFIG.apiBaseUrl}/api/userpreferences/getphonenumberpreferences?userName=${APICONFIG.apiLoggedInUser()}`,
        {
          method: 'GET',
          headers: APICONFIG.apiHeaders(),
        }
      )
        .then(async (response) => {
          if (response.status === 200 && response.ok) {
            await response.json().then((result) => {
              // result = '';
              dispatch({
                type: 'SET_PHONE_PREFERENCES',
                payload: result,
              });
            });
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        }) 
        .catch((err) => {
          console.log('error in getphonepreferences', err);
          // logactions.logger({Message: 'error in getphonepreferences'+ err})
          // throw new Error('error in getphonepreferences' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Get Phone Preferences',
            url: `${
              APICONFIG.apiBaseUrl
            }/api/userpreferences/getphonenumberpreferences?userName=${APICONFIG.apiLoggedInUser()}`,
            severity: 'low',
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'GetPhonePreferences'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }
  };
}
export function setUserPreferences_LLU(
  data,
  resetStories = false,
  reSearch = false,
  columnType = false,
  selectedCategory = '',
  columnindex = false,
  tickerdata = false
) {

  return (dispatch) => {
    
    try {
      let { 
        UserPreferencesString, 
        CoinColumnPreferencesString,
        CoinDetailColumnPreferencesString,
        columorderString
      } = TrendingMentionsHelper.stripCoinColumnPreferencesFromUserPreferences(data.UserPreferencesString);
      // console.log(UserPreferencesString, 
      //   CoinColumnPreferencesString,
      //   CoinDetailColumnPreferencesString,
      //   columorderString)
      //   return

        let ccps = CoinColumnPreferencesString.split('coincolumnpreference1:').filter((itm) => itm);
            // console.log(ccps)
        if(ccps.length > 1) {
          CoinColumnPreferencesString = `coincolumnpreference1:${ccps[0]}`;
        }

        let columnOrderArray = PreferenceHandler.CreateColumnOrderData(columorderString);
      dispatch({
        type: 'SET_USER_PREFERENCES',
        payload: UserPreferencesString,
        changeUpdateCount: resetStories,
        selectedCategory,
        noblack: false,
        forcetheme: Store.getState().settings.forcetheme
      });
      dispatch({
        type: 'SET_COINCOLUMN_PREFERENCES',
        payload: CoinColumnPreferencesString,
        initialpreference: false,
        showtopreach: Store.getState().settings.showtopreach
      })
      dispatch({
        type: 'SET_COINDETAILCOLUMN_PREFERENCES',
        payload: CoinDetailColumnPreferencesString,
        tickerdata
      })
      dispatch ({
        type : 'SET_COLUMN_ORDER_LIST',
        payload : columnOrderArray
      })
      if (resetStories) {
        dispatch({
          type: 'RESET_STORIES',
        });
      }
      if (reSearch) {
        if (columnType === 'search') {
          dispatch({
            type: 'RESET_SEARCH_STORIES',
          });
          dispatch({
            type: 'RE_SEARCH',
            payload: true,
          });
        } else if (columnType === 'tmd') {
          dispatch({
            type: 'RESET_TICKER_EVENTS',
          });
          dispatch({
            type: 'RE_SEARCH_TICKER_EVENTS',
            payload: true,
          });
        } else if (columnType === 'tmcd') {
          dispatch({
            type: 'RESET_COINDETAIL_TICKER_EVENTS',
            columnindex
          });
          dispatch({
            type: 'RE_SEARCH_COINDETAIL_TICKER_EVENTS',
            payload: true,
            columnindex
          });
        }
      }
    } catch (err) {
      console.log('error in setUserPreferences', err);
      // logactions.logger({Message: 'error in setUserPreferences'+ err})
      // throw new Error('error in setUserPreferences' + err);
      let data = {
        username: APICONFIG.apiLoggedInUser(),
        action: 'Set User Preferences',
        url: `${APICONFIG.apiBaseUrl}/api/userpreferences/setuserpreferences`,
        severity: 'low',
      };
      dispatch({
        type: 'REQUEST_ERROR',
        payload: ErrorHandleHelper.generateErrorString(err, 'SetUserPreferences'),
        data: data,
      });
    }
  };
}
export function setUserPreferences(
  data,
  resetStories = false,
  reSearch = false,
  columnType = false,
  selectedCategory = '',
  columnindex = false,
  tickerdata = false
) {
  // console.log(data.UserPreferencesString)
  
  // return;
  // console.log(CoinColumnPreferencesString)
  return (dispatch) => {
    // let { 
    //   UserPreferencesString, 
    //   CoinColumnPreferencesString,
    //   CoinDetailColumnPreferencesString,
    //   columorderString
    // } = TrendingMentionsHelper.stripCoinColumnPreferencesFromUserPreferences(data.UserPreferencesString);
    // console.log(columorderString)
    // dispatch ({
    //   type : 'SET_COLUMN_ORDER_LIST',
    //   payload : columorderString
    // })
    // return;
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/userpreferences/setuserpreferences`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: APICONFIG.apiHeaders(),
      })
        .then(async (response) => {
          if (response.status === 200 && response.ok) {
            let { 
              UserPreferencesString, 
              CoinColumnPreferencesString,
              CoinDetailColumnPreferencesString,
              columorderString
            } = TrendingMentionsHelper.stripCoinColumnPreferencesFromUserPreferences(data.UserPreferencesString);
            //fix for messed up coincloumnsetting due to search
            let ccps = CoinColumnPreferencesString.split('coincolumnpreference1:').filter((itm) => itm);
            // console.log(ccps)
            if(ccps.length > 1) {
              CoinColumnPreferencesString = `coincolumnpreference1:${ccps[0]}`;
            }
            //fix for messed up coincloumnsetting due to search
            // console.log(UserPreferencesString)
            // console.log(CoinColumnPreferencesString)
            // console.log(CoinDetailColumnPreferencesString)
            // console.log(columorderString)
            // return;
            let columnOrderArray = PreferenceHandler.CreateColumnOrderData(columorderString);
            // console.log(Store.getState().settings.forcetheme)
            dispatch({
              type: 'SET_USER_PREFERENCES',
              payload: UserPreferencesString,
              changeUpdateCount: resetStories,
              selectedCategory,
              noblack: false,
              forcetheme: Store.getState().settings.forcetheme
            });
            dispatch({
              type: 'SET_COINCOLUMN_PREFERENCES',
              payload: CoinColumnPreferencesString,
              initialpreference: false,
              showtopreach: Store.getState().settings.showtopreach
            })
            dispatch({
              type: 'SET_COINDETAILCOLUMN_PREFERENCES',
              payload: CoinDetailColumnPreferencesString,
              tickerdata
            })
            dispatch ({
              type : 'SET_COLUMN_ORDER_LIST',
              payload : columnOrderArray
            })

            // let searchsavedata = Store.getState().search.searchSaveData;
              // console.log('searchsave data');
              // console.log(searchsavedata);

            if (resetStories) {
              dispatch({
                type: 'RESET_STORIES',
              });
            }
            if (reSearch) {
              if (columnType === 'search') {
                dispatch({
                  type: 'RESET_SEARCH_STORIES',
                });
                dispatch({
                  type: 'RE_SEARCH',
                  payload: true,
                });
              } else if (columnType === 'tmd') {
                dispatch({
                  type: 'RESET_TICKER_EVENTS',
                });
                dispatch({
                  type: 'RE_SEARCH_TICKER_EVENTS',
                  payload: true,
                });
              } else if (columnType === 'tmcd') {
                dispatch({
                  type: 'RESET_COINDETAIL_TICKER_EVENTS',
                  columnindex
                });
                dispatch({
                  type: 'RE_SEARCH_COINDETAIL_TICKER_EVENTS',
                  payload: true,
                  columnindex
                });
              }
            }
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in setUserPreferences', err);
          // logactions.logger({Message: 'error in setUserPreferences'+ err})
          // throw new Error('error in setUserPreferences' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Set User Preferences',
            url: `${APICONFIG.apiBaseUrl}/api/userpreferences/setuserpreferences`,
            severity: 'low',
          };
          // dispatch({
          //   type: 'REQUEST_ERROR',
          //   payload: ErrorHandleHelper.generateErrorString(err, 'SetUserPreferences'),
          //   data: data,
          // });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }
  };
}

export function setLocalisationPreferences(data) {
  // let tempTZOffset1 = data.reducerData.text.substring(
  //   data.reducerData.text.lastIndexOf('(') + 1,
  //   data.reducerData.text.lastIndexOf(')')
  // );
  // let tempTZOffset = tempTZOffset1.replace('UTC', '');
console.log(data)
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/userpreferences/settimezonepreferences`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: APICONFIG.apiHeaders(),
      })
        .then(async (response) => {
          if (response.status === 200 && response.ok) {
            dispatch({
              type: 'SET_LOCALISATION_PREFERENCES',
              payload: data.UserPreferencesString,
              // payload: {
              //   id: data.reducerData.text,
              //   UTCOffset: tempTZOffset,
              // },
            });
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in setLocalisationPreferences', err);
          // logactions.logger({Message: 'error in setLocalisationPreferences'+ err})
          // throw new Error('error in setLocalisationPreferences' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Set localisation Preferences',
            url: `${APICONFIG.apiBaseUrl}/api/userpreferences/settimezonepreferences`,
            severity: 'low',
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'SetLocalisationPreferences'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }
  };
}

export function setPhonePreferences(data) {
  // console.log(data)
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/userpreferences/setphonenumberpreferences`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: APICONFIG.apiHeaders(),
      })
        .then(async (response) => {
          if (response.status === 200 && response.ok) {
            // console.log(result)
            dispatch({
              type: 'SET_PHONE_PREFERENCES',
              payload: data.UserPreferencesString,
            });
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in setPhonePreferences', err);
          // logactions.logger({Message: 'error in setPhonePreferences'+ err})
          // throw new Error('error in setPhonePreferences' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Set Phone Preferences',
            url: `${APICONFIG.apiBaseUrl}/api/userpreferences/setphonenumberpreferences`,
            severity: 'low',
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'SetPhonePreferences'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }
  };
}

export function setNotificationPreferences(data) {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/userpreferences/setnotificationspreferences`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: APICONFIG.apiHeaders(),
      })
        .then(async (response) => {
          if (response.status === 200 && response.ok) {
            dispatch({
              type: 'SET_NOTIFICATION_PREFERENCES',
              payload: data.UserPreferencesString,
            });
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in setNotificationPreferences', err);
          // logactions.logger({Message: 'error in setNotificationPreferences'+ err})
          // throw new Error('error in setNotificationPreferences' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Set Notification Preferences',
            url: `${APICONFIG.apiBaseUrl}/api/userpreferences/setnotificationspreferences`,
            severity: 'low',
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'SetNotificationPreferences'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }
  };
}
