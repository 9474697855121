import { Component } from 'react';
import { connect } from 'react-redux';
import * as searchActionCreators from '../../actions/search.action';
import { EventsUpdateHandler, PreferenceHandler, TrendingMentionsHelper } from '../../modules/helper.module';
import { setUserPreferences, setUserPreferences_LLU } from '../../actions/apppreferences.action';
import { switchcolumnfocus, UpdateInitialColumnLoadCounter } from '../../actions/settings.action';
import * as moment from 'moment';

class SearchSaveContextHandle extends Component {
  constructor(props) {
    super();
    this.state = {
      searchSaveInit: false,
      pinnedSearchSaveInit: false,
      pinnedSavedSearchListKey: "",
      searchSaveFeedReqData: {},
      searchSaveUseCases: {},
      searchSaveData: {},
      searchSavedStoriesIndex: {},
      thisInstance: this,
      utcOffset: 0,
      mouseMoveOverStory: false,
      postponeupdate: props.postponeupdate
    };
    this.savedSearchUpdateHandle = {};
    this.saveSearchUpdateInterval = {};
  }

  componentDidMount() {
    if (this.props.render !== 'iframe') {
      this.loadSavedSearches();
    } else {
      // console.log(this.props.render, this.props.iframeasset, this.props.iframeassetdata, this.props.searchSaveData)
      this.getiframesavedsearch()
    }

  }

  getiframesavedsearch = () => {
    let searchSaveFeedReqData = this.state.searchSaveFeedReqData;
    console.log(this.props.searchSaveData)
    if (Object.keys(this.props.searchSaveData).length) {
      Object.keys(this.props.searchSaveData).map((saveSearchId) => {
        let searchData = JSON.parse(this.state.searchSaveData[saveSearchId].SavedDataDetails);
        // console.log(searchData)
        searchSaveFeedReqData[saveSearchId] = {
          RequestState: true,
          InitialFeedRequest: searchData.startDate,
          LastFeedRequest: searchData.endDate,
          LastFeedRequestEnd: searchData.endDate,
          InitialFeedResponse: searchData.endDate,
          LastFeedResponse: '',
        };
        this.savedSearchUpdateHandle[`${saveSearchId}`] = new EventsUpdateHandler(searchSaveFeedReqData[saveSearchId]);
        this.props.setSaveSearchFeedReqData(searchSaveFeedReqData[saveSearchId], saveSearchId);
        this.props.iframesearchevents(this.props.searchSaveData[saveSearchId], searchData)
      })
    }
    this.setState({
      ...this.state,
      searchSaveFeedReqData: searchSaveFeedReqData,
    });
  }

  loadSavedSearches = () => {
    this.props.loadSavedSearch();
  };

  getSavedSearch = (saveData = false, saveInit = false) => {
    if (saveData) {
      if (saveInit) {
        let { tmDetailPreferences, columnlist, pinnedSavedSearchListKey } = this.props;
        let preferences = JSON.parse(JSON.stringify(this.props.preferences));
        if (preferences.user_interface.HasColumnOrderSettings === false) {
          preferences.user_interface.HasColumnOrderSettings = true;
        }
        let coin_column_preferences = JSON.parse(JSON.stringify(this.props.coin_column_preferences));
        let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))
        // console.log(this.props.columnorderlist)
        let tm_prefs = JSON.parse(JSON.stringify(tmDetailPreferences));
        if (coin_column_preferences.length) {
          coin_column_preferences.forEach((itm, i) => {
            if (itm.expanded === 'true') {
              itm.expanded = false
              let keyindex = columnlist.indexOf(`Trending Mentions__${i}`)
              columnorderlist[keyindex].expanded = false
            }
          })
        }

        if (pinnedSavedSearchListKey !== "") {
          let insertAfterIndex = columnlist.indexOf(pinnedSavedSearchListKey)
          columnorderlist.splice(insertAfterIndex + 1, 0, saveData)
        } else {
          columnorderlist.push(saveData)
        }

        // console.log(this.props.columnorderlist)
        let new_columnFilter = saveData.columnFilter;
        let new_columnFilter_count = 0
        columnorderlist.forEach((column, i) => {
          if (new_columnFilter === 'Search') {
            if (column.columntype === 'SavedSearch') {
              new_columnFilter_count++;
            } else if (column.columntype === 'tmcd' && column.columnFilter === 'Search') {
              new_columnFilter_count++;
            }
            // console.log('for search filter count', new_columnFilter_count)
          } else {
            if (column.columntype === 'SavedSearch' || column.columntype === 'tmcd') {
              if (column.columnFilter === new_columnFilter) {
                new_columnFilter_count++;
              }
            }
          }

        })
        if (new_columnFilter === 'Search') {
          if (new_columnFilter_count > 4) {
            let remove_search_index = false;
            let removingsearchcolumntype = ''
            columnorderlist.forEach((column, rm_indx) => {
              if (column.columntype === 'SavedSearch') {
                if (!remove_search_index) {
                  remove_search_index = rm_indx
                  removingsearchcolumntype = 'SavedSearch'
                }

              } else if (column.columntype === 'tmcd' && column.columnFilter === 'Search') {
                if (!remove_search_index) {
                  remove_search_index = rm_indx
                  removingsearchcolumntype = 'tmcd'
                }
              }
            })

            if (removingsearchcolumntype === 'SavedSearch') {
              let saveid = columnorderlist[remove_search_index].UserSavedDataID
              let deletedData = this.props.searchSaveData[saveid];
              if (typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
                this.props.deleteSavedSearch_LLU(deletedData);
              } else {
                this.props.deleteSavedSearch(deletedData);
              }

              columnorderlist.splice(remove_search_index, 1)
            } else if (removingsearchcolumntype === 'tmcd') {
              let remove_tmpref_index = false
              tm_prefs.forEach((itm, ind) => {
                if (itm.SecurityName === columnorderlist[remove_search_index].SecurityName && itm.SecurityTicker === columnorderlist[remove_search_index].SecurityTicker) {
                  if (remove_tmpref_index === false) {
                    remove_tmpref_index = ind
                  }
                }
              })
              if (remove_tmpref_index !== false) {
                let settingstoremove = tm_prefs[remove_tmpref_index]
                let removelistkey = `Coin_Column_details__${remove_tmpref_index}`
                let orderremoveAtIndex = false//columnlist.indexOf(removelistkey)
                columnorderlist.forEach((column, index) => {
                  if (column.columntype === 'tmcd') {
                    if (column.SecurityName === settingstoremove.SecurityName && column.SecurityTicker === settingstoremove.SecurityTicker && column.columnFilter === new_columnFilter) {
                      if (orderremoveAtIndex === false) {
                        orderremoveAtIndex = index
                      }
                    }
                  }
                })
                let tmp_columnorderlist = JSON.parse(JSON.stringify(columnorderlist))
                tmp_columnorderlist.splice(orderremoveAtIndex, 1)
                columnorderlist = JSON.parse(JSON.stringify(tmp_columnorderlist))

                let tmp_tm_prefs = JSON.parse(JSON.stringify(tm_prefs))
                tm_prefs.splice(remove_tmpref_index, 1)
                tm_prefs = JSON.parse(JSON.stringify(tmp_tm_prefs))
              }
            }

          }
        } else {
          if (new_columnFilter_count > 3) {
            let remove_search_index = false;
            let removingsearchcolumntype = ''
            columnorderlist.forEach((column, rm_indx) => {
              if (column.columntype === 'SavedSearch' && column.columnFilter === new_columnFilter) {
                if (!remove_search_index) {
                  remove_search_index = rm_indx
                  removingsearchcolumntype = 'SavedSearch'
                }

              } else if (column.columntype === 'tmcd' && column.columnFilter === new_columnFilter) {
                if (!remove_search_index) {
                  remove_search_index = rm_indx
                  removingsearchcolumntype = 'tmcd'
                }
              }
            })
            if (removingsearchcolumntype === 'SavedSearch') {
              let saveid = columnorderlist[remove_search_index].UserSavedDataID
              let deletedData = this.props.searchSaveData[saveid];
              if (typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
                this.props.deleteSavedSearch_LLU(deletedData);
              } else {
                this.props.deleteSavedSearch(deletedData);
              }

              columnorderlist.splice(remove_search_index, 1)
            } else if (removingsearchcolumntype === 'tmcd') {
              let remove_tmpref_index = false
              tm_prefs.forEach((itm, ind) => {
                if (itm.SecurityName === columnorderlist[remove_search_index].SecurityName && itm.SecurityTicker === columnorderlist[remove_search_index].SecurityTicker) {
                  if (remove_tmpref_index === false) {
                    remove_tmpref_index = ind
                  }
                }
              })
              if (remove_tmpref_index !== false) {
                let settingstoremove = tm_prefs[remove_tmpref_index]
                let removelistkey = `Coin_Column_details__${remove_tmpref_index}`
                let orderremoveAtIndex = false//columnlist.indexOf(removelistkey)
                columnorderlist.forEach((column, index) => {
                  if (column.columntype === 'tmcd') {
                    if (column.SecurityName === settingstoremove.SecurityName && column.SecurityTicker === settingstoremove.SecurityTicker && column.columnFilter === new_columnFilter) {
                      if (orderremoveAtIndex === false) {
                        orderremoveAtIndex = index
                      }
                    }
                  }
                })
                let tmp_columnorderlist = JSON.parse(JSON.stringify(columnorderlist))
                tmp_columnorderlist.splice(orderremoveAtIndex, 1)
                columnorderlist = JSON.parse(JSON.stringify(tmp_columnorderlist))

                let tmp_tm_prefs = JSON.parse(JSON.stringify(tm_prefs))
                tm_prefs.splice(remove_tmpref_index, 1)
                tm_prefs = JSON.parse(JSON.stringify(tmp_tm_prefs))
              }
            }
          }
        }

        let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
        let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
        let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tm_prefs, 'coindetails');
        let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)
        let newPrefStr = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`
        // console.log(newPrefStr);
        PreferenceStrings.userPrefData.UserPreferencesString = newPrefStr;
        PreferenceStrings.userPrefData.UserName = this.props.userData.username;
        // this.props.CloseCoinDetailsColumn(columnIndex, SecurityName)
        if (typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
          this.props.setUserPreferences_LLU(PreferenceStrings.userPrefData);
        } else {
          this.props.setUserPreferences(PreferenceStrings.userPrefData);
        }

      }

      let searchData = JSON.parse(saveData.SavedDataDetails);
      // console.log(searchData)
      let searchSaveFeedReqData = {};
      searchData.startDate = moment().subtract(7, 'days').utcOffset(0).format('YYYY-MM-DD HH:mm:ss');
      searchData.endDate = this.props.userData.userPackage.toLowerCase() === 'bronze' ? moment().utcOffset(0).subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss') : moment().utcOffset(0).format('YYYY-MM-DD HH:mm:ss');

      searchSaveFeedReqData[saveData.UserSavedDataID] = {
        RequestState: true,
        InitialFeedRequest: searchData.startDate,
        LastFeedRequest: searchData.endDate,
        LastFeedRequestEnd: searchData.endDate,
        InitialFeedResponse: searchData.endDate,
        LastFeedResponse: '',
      };

      let searchText = `${searchData.event},${searchData.secTyp.length ? searchData.secTyp.join(',') + ',' : ''}${searchData.sec.length ? searchData.sec.join(',') + ',' : ''
        }${searchData.src && searchData.src.length ? searchData.src.join(',') + ',' : ''}${searchData.startDate}---${searchData.endDate}`;
      // let searchText = `${searchData.event},${searchData.loc.length ? searchData.loc.join(',')+',' : ''}${searchData.secTyp.length ? searchData.secTyp.join(',')+',' : ''}${searchData.sec.length ? searchData.sec.join(',')+',' : ''}${searchData.mnTyp.length ? searchData.mnTyp.join(';')+',' : ''}${searchData.startDate}---${searchData.endDate}`;
      if (saveInit) {
        this.savedSearchUpdateHandle[`${saveData.UserSavedDataID}`] = new EventsUpdateHandler(
          searchSaveFeedReqData[saveData.UserSavedDataID]
        );
      }
      this.savedSearchUpdateHandle[`${saveData.UserSavedDataID}`].setReqData(
        searchSaveFeedReqData[saveData.UserSavedDataID]
      );
      this.props.setSaveSearchFeedReqData(searchSaveFeedReqData[saveData.UserSavedDataID], saveData.UserSavedDataID);
      if (saveInit) {
        this.props.initSearchSave(false);
        this.props.initPinnedSearchSave(false);
        this.props.resetSearchData();
      }
      this.props.updatepinnedSearchCount()
      this.props.searchEvents(
        false,
        searchData.event.join(';'), //searchData.loc.join(';'),
        searchData.secTyp.join(';'),
        searchData.sec.join(';'),
        //searchData.mnTyp.join(';'),
        searchData.startDate,
        searchData.endDate,
        `${searchData.freeText}`,
        searchData.src && searchData.src.length ? searchData.src.join(',') : '',
        `${searchText},${searchData.freeText}`,
        'SavedSearch',
        saveData.UserSavedDataID,
        searchData.withCurated,
        searchData.withBlack,
        searchData.withWhite,
        searchData.withYellow,
        searchData.withOrange,
        searchData.withRed
      );

      this.setState({
        ...this.state,
        searchSaveFeedReqData: {
          ...this.state.searchSaveFeedReqData,
          [saveData.UserSavedDataID]: searchSaveFeedReqData[saveData.UserSavedDataID],
        },
      });
    }
  };

  getSavedSearches = () => {
    let searchSaveFeedReqData = this.state.searchSaveFeedReqData;
    console.log(this.state.searchSaveData)
    if (Object.keys(this.state.searchSaveData).length) {
      Object.keys(this.state.searchSaveData).map((saveSearchId) => {
        let searchData = JSON.parse(this.state.searchSaveData[saveSearchId].SavedDataDetails);
        searchData.startDate = moment().subtract(7, 'days').utcOffset(0).format('YYYY-MM-DD HH:mm:ss');
        searchData.endDate = this.props.userData.userPackage.toLowerCase() === 'bronze' ? moment().utcOffset(0).subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss') : moment().utcOffset(0).format('YYYY-MM-DD HH:mm:ss');

        searchSaveFeedReqData[saveSearchId] = {
          RequestState: true,
          InitialFeedRequest: searchData.startDate,
          LastFeedRequest: searchData.endDate,
          LastFeedRequestEnd: searchData.endDate,
          InitialFeedResponse: searchData.endDate,
          LastFeedResponse: '',
        };
        // console.log(searchData)
        let searchText = `${searchData.event},${searchData.secTyp.length ? searchData.secTyp.join(',') + ',' : ''}${searchData.sec.length ? searchData.sec.join(',') + ',' : ''
          }${searchData.src && searchData.src.length ? searchData.src.join(',') + ',' : ''}${searchData.startDate}---${searchData.endDate}`;
        // let searchText = `${searchData.event},${searchData.loc.length ? searchData.loc.join(',')+',' : ''}${searchData.secTyp.length ? searchData.secTyp.join(',')+',' : ''}${searchData.sec.length ? searchData.sec.join(',')+',' : ''}${searchData.mnTyp.length ? searchData.mnTyp.join(';')+',' : ''}${searchData.startDate}---${searchData.endDate}`;
        // console.log(saveSearchId)
        this.savedSearchUpdateHandle[`${saveSearchId}`] = new EventsUpdateHandler(searchSaveFeedReqData[saveSearchId]);
        this.props.setSaveSearchFeedReqData(searchSaveFeedReqData[saveSearchId], saveSearchId);
        this.props.searchEvents(
          false,
          Array.isArray(searchData.event) ? searchData.event.join(';') : searchData.event,
          // Array.isArray(searchData.loc) ? searchData.loc.join(';') : searchData.loc,
          searchData.secTyp.join(';'),
          searchData.sec.join(';'),
          // searchData.mnTyp.join(';'),
          searchData.startDate,
          searchData.endDate,
          `${searchData.freeText}`,
          searchData.src && searchData.src.length ? searchData.src.join(',') : '',
          `${searchText},${searchData.freeText}`,
          'SavedSearch',
          saveSearchId,
          searchData.withCurated,
          searchData.withBlack,
          searchData.withWhite,
          searchData.withYellow,
          searchData.withOrange,
          searchData.withRed
        );
        return null;
      });
    }
    this.setState({
      ...this.state,
      searchSaveFeedReqData: searchSaveFeedReqData,
    });
  };

  cleanUpSavedSearches = () => {
    let existingSaveIds = Object.keys(this.savedSearchUpdateHandle).length && Object.keys(this.savedSearchUpdateHandle);
    if (existingSaveIds) {
      // console.log(existingSaveIds, this.state.searchSavedStoriesIndex);
      let searchSaveFeedReqData = this.state.searchSaveFeedReqData;
      existingSaveIds.map((svid) => {
        if (Object.keys(this.state.searchSavedStoriesIndex).indexOf(svid) === -1) {
          // console.log(svid)
          clearTimeout(this.saveSearchUpdateInterval[svid]);
          delete this.saveSearchUpdateInterval[svid];
          delete this.savedSearchUpdateHandle[svid];
          delete searchSaveFeedReqData[svid];
        }
        return null;
      });
      this.setState({
        ...this.state,
        searchSaveFeedReqData: searchSaveFeedReqData,
      });
    }
  };

  captureMouseMoveOverStoryFromHome = (status) => {
    this.setState({ ...this.state, mouseMoveOverStory: status });
  };

  // componentWillReceiveProps(nextProps) {

  // let prevSearchSaveFeedReqData = this.state.searchSaveFeedReqData
  // let prevsearchSaveUseCases = this.state.searchSaveUseCases
  // Object.keys(nextProps.searchSavedStoriesIndex).map(savedid => {
  //     let searchSaveFeedReqData = prevSearchSaveFeedReqData[savedid];
  //     let prevSearchSavedStoriesIndex = this.state.searchSavedStoriesIndex[savedid] ? this.state.searchSavedStoriesIndex[savedid] : [];
  //     let newSearchSavedStoriesIndex = nextProps.searchSavedStoriesIndex[savedid]
  //     let error = nextProps.req_error.rootFeeds
  //     if(this.savedSearchUpdateHandle[savedid]) {
  //         searchSaveFeedReqData = this.savedSearchUpdateHandle[savedid].UpdateReqData(prevSearchSavedStoriesIndex, newSearchSavedStoriesIndex, error);
  //     }
  //     console.log(searchSaveFeedReqData.reqData.LastFeedRequest, searchSaveFeedReqData.useCase)
  //     prevSearchSaveFeedReqData[savedid] = searchSaveFeedReqData.reqData
  //     prevsearchSaveUseCases[savedid] = searchSaveFeedReqData.useCase
  // })
  // this.setState({
  //     ...this.state,
  //     searchSaveInit: nextProps.searchSaveInit,
  //     searchSaveData: nextProps.searchSaveData,
  //     searchSavedStoriesIndex : nextProps.searchSavedStoriesIndex,
  //     searchSaveFeedReqData: prevSearchSaveFeedReqData,
  //     searchSaveUseCases: prevsearchSaveUseCases
  // })

  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.thisInstance.props.postponeupdate !== nextProps.postponeupdate) {
      return { postponeupdate: nextProps.postponeupdate };
    }
    if (prevState.searchSaveInit !== nextProps.searchSaveInit) {
      return { searchSaveInit: nextProps.searchSaveInit };
    }
    if (prevState.pinnedSearchSaveInit !== nextProps.pinnedSearchSaveInit) {
      return { pinnedSearchSaveInit: nextProps.pinnedSearchSaveInit };
    }
    if (prevState.pinnedSavedSearchListKey !== nextProps.pinnedSavedSearchListKey) {
      return { pinnedSavedSearchListKey: nextProps.pinnedSavedSearchListKey };
    }
    if (Object.keys(prevState.searchSaveData).length !== Object.keys(nextProps.searchSaveData).length) {
      return { searchSaveData: nextProps.searchSaveData };
    }
    if (Object.keys(nextProps.searchSavedStoriesIndex).length) {
      let prevSearchSaveFeedReqData = prevState.searchSaveFeedReqData;
      let prevsearchSaveUseCases = prevState.searchSaveUseCases;
      Object.keys(nextProps.searchSavedStoriesIndex).map((savedid) => {
        let searchSaveFeedReqData = prevSearchSaveFeedReqData[savedid];
        let prevSearchSavedStoriesIndex = prevState.searchSavedStoriesIndex[savedid]
          ? prevState.searchSavedStoriesIndex[savedid]
          : [];
        let newSearchSavedStoriesIndex = nextProps.searchSavedStoriesIndex[savedid];
        let error = nextProps.req_error.rootFeeds;
        if (prevState.thisInstance.savedSearchUpdateHandle[savedid]) {
          searchSaveFeedReqData = prevState.thisInstance.savedSearchUpdateHandle[savedid].UpdateReqData(
            prevSearchSavedStoriesIndex,
            newSearchSavedStoriesIndex,
            error
          );
        }
        prevSearchSaveFeedReqData[savedid] = searchSaveFeedReqData.reqData;
        prevsearchSaveUseCases[savedid] = searchSaveFeedReqData.useCase;
        return null;
      });
      return {
        searchSavedStoriesIndex: nextProps.searchSavedStoriesIndex,
        searchSaveFeedReqData: prevSearchSaveFeedReqData,
        searchSaveUseCases: prevsearchSaveUseCases,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (Object.keys(prevState.searchSaveData).length < Object.keys(this.state.searchSaveData).length) {
      // console.log('here1')
      let newSaveId = [];
      Object.keys(this.state.searchSaveData).map((saveid) => {
        if (Object.keys(prevState.searchSaveData).indexOf(saveid) === -1) {
          newSaveId.push(saveid);
        }
        return null;
      });

      if (this.state.searchSaveInit || this.state.pinnedSearchSaveInit) {
        this.getSavedSearch(this.state.searchSaveData[newSaveId[0]], true);
      } else {
        this.getSavedSearches();
      }
    } else if (Object.keys(prevState.searchSaveData).length > Object.keys(this.state.searchSaveData).length) {
      // console.log('here2')
      this.cleanUpSavedSearches();
    }
    if (this.state.searchSavedStoriesIndex !== prevState.searchSavedStoriesIndex && !this.state.postponeupdate) {
      // console.log('here3')
      Object.keys(this.state.searchSavedStoriesIndex).map((id) => {
        if (this.state.searchSavedStoriesIndex[id] !== prevState.searchSavedStoriesIndex[id]) {
          this.updateSearchSaveTimers(id);
        }
        return null;
      });
    } else {
      if (prevState.postponeupdate !== this.state.postponeupdate && !this.state.postponeupdate) {
        Object.keys(this.state.searchSavedStoriesIndex).map((id) => {
          if (this.state.searchSavedStoriesIndex[id] !== prevState.searchSavedStoriesIndex[id]) {
            this.updateSearchSaveTimers(id);
          }
          return null;
        });
      } else if (prevState.postponeupdate !== this.state.postponeupdate && this.state.postponeupdate) {
        Object.keys(this.saveSearchUpdateInterval).map((id) => {
          // if (this.state.searchSavedStoriesIndex[id] !== prevState.searchSavedStoriesIndex[id]) {
          // this.updateSearchSaveTimers(id);
          clearTimeout(this.saveSearchUpdateInterval[id])
          // }
          return null;
        });
      }
    }
  }

  updateSearchSaveTimers = (SEARCHID = false) => {
    if (Object.keys(this.state.searchSavedStoriesIndex).length) {
      if (SEARCHID) {
        let UseCase = this.state.searchSaveUseCases[SEARCHID];
        let searchSaveFeedReqData = this.state.searchSaveFeedReqData[SEARCHID];
        this.props.setSaveSearchFeedReqData(searchSaveFeedReqData, SEARCHID);
        switch (UseCase) {
          case 1:
            // this.props.UpdateInitialColumnLoadCounter()
            this.saveSearchUpdateInterval[SEARCHID] = setTimeout(() => {
              if (typeof this.savedSearchUpdateHandle[SEARCHID] !== 'undefined') {
                this.searchSaveUpdateFeeds(this.savedSearchUpdateHandle[SEARCHID].LastFeedRequest, SEARCHID);
              }
              clearTimeout(this.saveSearchUpdateInterval[SEARCHID]);
            }, 60000);

            break;
          case 4:
            let searchSaveData = this.state.searchSaveData[SEARCHID];
            if (this.saveSearchUpdateInterval[SEARCHID]) {
              clearTimeout(this.saveSearchUpdateInterval[SEARCHID]);
            }
            this.getSavedSearch(searchSaveData);

            break;
          case 3:
            if (this.props.searchSaveDataUpdated[SEARCHID]) {
              let searchSaveData = this.state.searchSaveData[SEARCHID];
              if (this.saveSearchUpdateInterval[SEARCHID]) {
                clearTimeout(this.saveSearchUpdateInterval[SEARCHID]);
              }
              this.getSavedSearch(searchSaveData);
            }
            break;
          default:
            this.saveSearchUpdateInterval[SEARCHID] = setTimeout(() => {
              if (typeof this.savedSearchUpdateHandle[SEARCHID] !== 'undefined') {
                this.searchSaveUpdateFeeds(this.savedSearchUpdateHandle[SEARCHID].LastFeedRequest, SEARCHID);
              }
              clearTimeout(this.saveSearchUpdateInterval[SEARCHID]);
            }, 60000);
            break;
        }
      }
    }
  };

  searchSaveUpdateFeeds = (LastFeedRequest, saveid) => {
    let startDate = moment(LastFeedRequest).utcOffset(this.state.utcOffset).format('MM-DD-YYYY HH:mm:ss');
    let nextStartDate = this.props.userData.userPackage.toLowerCase() === 'bronze' ? moment().utcOffset(this.state.utcOffset).subtract(15, 'minutes').format() : moment().utcOffset(this.state.utcOffset).format();
    let endDate = moment(nextStartDate).utcOffset(this.state.utcOffset).format('MM-DD-YYYY HH:mm:ss');

    let searchData =
      this.state.searchSaveData[saveid] && JSON.parse(this.state.searchSaveData[saveid].SavedDataDetails);
    if (searchData) {
      let searchText = `${searchData.event ? searchData.event : ''},${searchData.loc && searchData.loc.length ? searchData.loc.join(',') + ',' : ''
        }${searchData.secTyp && searchData.secTyp.length ? searchData.secTyp.join(',') + ',' : ''}${searchData.sec && searchData.sec.length ? searchData.sec.join(',') + ',' : ''
        }${searchData.mnTyp && searchData.mnTyp.length ? searchData.mnTyp.join(';') + ',' : ''}${searchData.src && searchData.src.length ? searchData.src.join(',') + ',' : ''}${startDate}---${endDate}`;
      this.setState(
        {
          ...this.state,
          searchSaveFeedReqData: {
            ...this.state.searchSaveFeedReqData,
            [`${saveid}`]: {
              ...this.state.searchSaveFeedReqData[`${saveid}`],
              RequestState: true,
              LastFeedRequestEnd: nextStartDate,
              LastFeedRequest: LastFeedRequest,
            },
          },
        },
        () => {
          this.savedSearchUpdateHandle[saveid].RequestState = true;
          this.savedSearchUpdateHandle[saveid].LastFeedRequest = LastFeedRequest;
          this.savedSearchUpdateHandle[saveid].LastFeedRequestEnd = nextStartDate;
          this.props.setSaveSearchFeedReqData(this.state.searchSaveFeedReqData[saveid], saveid);
          this.props.searchEvents(
            true,
            searchData.event ? searchData.event.join(';') : '', // searchData.loc ? searchData.loc.join(';') : '',
            searchData.secTyp ? searchData.secTyp.join(';') : '',
            searchData.sec ? searchData.sec.join(';') : '',
            // searchData.mnTyp ? searchData.mnTyp.join(';') : '',
            startDate,
            endDate,
            `${searchData.freeText}`,
            searchData.src && searchData.src.length ? searchData.src.join(',') : '',
            `${searchText},${searchData.freeText}`,
            'SavedSearch',
            saveid,
            searchData.withCurated,
            searchData.withBlack,
            searchData.withWhite,
            searchData.withYellow,
            searchData.withOrange,
            searchData.withRed,
            this.savedSearchUpdateHandle[saveid]
          );
        }
      );
    }
  };

  render() {
    return '';
  }
}

const mapStateToComponent = (state) => {
  return {
    postponeupdate: state.settings.postponeupdate,
    searchSaveInit: state.search.searchSaveInit,
    pinnedSearchSaveInit: state.search.pinnedSearchSaveInit,
    pinnedSavedSearchListKey: state.search.pinnedSavedSearchListKey,
    searchSaveData: state.search.searchSaveData,
    searchSaveDataUpdated: state.search.searchSaveDataUpdated,
    searchSavedStoriesIndex: state.search.searchSavedStoriesIndex,
    iframeasset: state.settings.iframeasset,
    render: state.settings.render,
    iframeassetdata: state.settings.iframeassetdata,

    tmDetailPreferences: state.trendingMentions.tmDetailPreferences,
    coin_column_preferences: state.trendingMentions.coin_column_preferences,
    preferences: state.app_preferences.preferences,
    columnorderlist: state.settings.columnorderlist,
    columnlist: state.settings.columnlist,

    req_error: state.error.rootFeeds,
  };
};
const mapDispatchToProps = {
  ...searchActionCreators,
  setUserPreferences,
  setUserPreferences_LLU,
  switchcolumnfocus,
  UpdateInitialColumnLoadCounter
};

export default connect(mapStateToComponent, mapDispatchToProps, null, { forwardRef: true })(SearchSaveContextHandle);
